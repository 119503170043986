import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import TextField from 'material-ui/TextField';
import RenderField from '../../shared/render_field';
import { required } from '../../util/validate';
import styles from '../../styles';

function getNullVal(val) {
    if (val === undefined || val === null) {
        return 0;
    }
    return parseInt(val);
}

const ContractDetailsFormFields = {
    total_weekly_man_hours: 'total_weekly_man_hours',
    contract_details_notes: 'contract_details_notes',
    detail_hours: 'detail_hours',
    mowing_hours: 'mowing_hours',
    irrigation_hours: 'irrigation_hours',
};
const ContractDetailsForm = (props) => {
    const { disabled, detail_hours, mowing_hours, irrigation_hours } = props;
    const totalWeeklyHours =
        getNullVal(detail_hours) + getNullVal(mowing_hours) + getNullVal(irrigation_hours);
    return (
      <div>
        <div style={{ ...styles.row }}>
          <TextField
            value={totalWeeklyHours}
            id={ContractDetailsFormFields.total_weekly_man_hours}
            floatingLabelText="Total Weekly Man Hours"
            style={{ ...styles.row.field }}
          />
          <Field
            name={ContractDetailsFormFields.contract_details_notes}
            type="text"
            component={RenderField}
            style={{ ...styles.rowItem }}
            disabled={disabled}
            floatingLabelText="Note"
          />
        </div>
        <div style={{ ...styles.row }}>
          <Field
            name={ContractDetailsFormFields.detail_hours}
            type="number"
            component={RenderField}
            disabled={disabled}
            validate={[required]}
            floatingLabelText="Detail Hours"
          />
        </div>
        <div style={{ ...styles.row }}>
          <Field
            name={ContractDetailsFormFields.mowing_hours}
            type="number"
            component={RenderField}
            validate={[required]}
            disabled={disabled}
            floatingLabelText="Mowing Hours"
          />
        </div>
        <div style={{ ...styles.row }}>
          <Field
            name={ContractDetailsFormFields.irrigation_hours}
            type="number"
            component={RenderField}
            validate={[required]}
            disabled={disabled}
            floatingLabelText="Irrigation Hours"
          />
        </div>
      </div>
    );
};
ContractDetailsForm.defaultProps = {
    disabled: false,
};
ContractDetailsForm.propTypes = {
    disabled: PropTypes.bool,
};
export default ContractDetailsForm;
export { ContractDetailsFormFields };
