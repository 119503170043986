import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Show, ListButton, EditButton, DeleteButton, RefreshButton } from 'admin-on-rest';
import { Toolbar } from 'material-ui/Toolbar';
import { required, number } from '../util/validate';
import { CardActions } from 'material-ui/Card';
import FlatButton from 'material-ui/FlatButton';
import RenderField, { RenderCheckbox } from '../shared/render_field';
import LinkToRelatedManagers from './linkToRelatedManagers';
import checkAccess from '../util/acl';
import styles from '../styles';
import './management_info.css';

const formName = 'record-form';
class Contact extends Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this);
    }
    onSave(formData) {
        this.props.save(formData, 'list');
    }
    render() {
        const { save, loading, handleSubmit } = this.props;
        return (
          <form className="simple-form" onSubmit={handleSubmit(this.onSave)}>
            <div className="field">
              <div>
                <div style={{ ...styles.row }}>
                  <Field
                    name="company_name"
                    disabled
                    type="text"
                    component={RenderField}
                    validate={[required]}
                    floatingLabelText="Company name"
                    style={{ ...styles.row.field }}
                  />
                </div>
                <div style={{ ...styles.row }}>
                  <Field
                    name="street_address"
                    disabled
                    type="text"
                    component={RenderField}
                    validate={[required]}
                    floatingLabelText="Street address"
                    style={{ ...styles.row.field }}
                  />
                </div>
                <div style={{ ...styles.row }}>
                  <Field
                    name="city"
                    disabled
                    type="text"
                    component={RenderField}
                    validate={[required]}
                    floatingLabelText="City"
                    style={{ ...styles.row.field }}
                  />
                </div>
                <div style={{ ...styles.row }}>
                  <Field
                    name="zip"
                    disabled
                    type="text"
                    component={RenderField}
                    validate={[required]}
                    floatingLabelText="Zip"
                    style={{ ...styles.row.field }}
                  />
                </div>
                <div style={{ ...styles.row }}>
                  <Field
                    name="is_third_party"
                    disabled
                    label="Is third party?"
                    component={RenderCheckbox}
                    validate={[required, number]}
                    style={{ ...styles.row.field }}
                  />
                </div>
              </div>
            </div>
            <Toolbar />
          </form>
        );
    }
}

let CreateReduxForm = reduxForm({
    form: formName, // <------ same form name
    destroyOnUnmount: true, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(Contact);

function mapStateToProps(state, thisprops) {
    return {
        save: thisprops.save,
        loading: state.admin.loading,
        initialValues: {
            duration_in_days: 365,
        },
    };
}

function mapDispatchToProps(dispatch) {
    return {};
}

CreateReduxForm = connect(mapStateToProps, mapDispatchToProps)(CreateReduxForm);

class EnhancedContainer extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { save } = this.props;
        return (
          <div>
            <CreateReduxForm save={save} />
          </div>
        );
    }
}

const ManagementInfoActions = ({ basePath, data }) => (
  <CardActions className="card-actions">
    <EditButton basePath={basePath} record={data} />
    <ListButton basePath={basePath} />
    <DeleteButton basePath={basePath} record={data} />
    <RefreshButton />
    <LinkToRelatedManagers record={data || {}} text={'View Managers'} />
  </CardActions>
);

class ShowComponent extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
          <Show title="Management company" {...this.props} actions={<ManagementInfoActions />}>
            <EnhancedContainer />
          </Show>
        );
    }
}
export default ShowComponent;
