import React from 'react';
import { Create } from 'admin-on-rest';

import UserContainer from './shared/form';

const UserCreate = props => (
  <Create {...props}>
    <UserContainer />
  </Create>
);

export { UserCreate };
