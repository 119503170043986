import React, { Component } from 'react';
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn,
} from 'material-ui/Table';
import moment from 'moment';
import FlatButton from 'material-ui/FlatButton';
import ShowIcon from 'material-ui/svg-icons/image/remove-red-eye';
import { Link } from 'react-router-dom';

export const JobFeedTypes = {
    JobCreated: 1,
    JobUpdated: 2,
    ContractCreated: 3,
    ContractSent: 4,
    RaiseRequestCreated: 5,
    RaiseRequestSent: 6,
    ContractAccepted: 7,
    ContractRejected: 8,
    RaiseRequestAccepted: 9,
    RaiseRequestRejected: 10,
};
function GetLink(jobSetupFeedTypeId, foreignTableID) {
    switch (jobSetupFeedTypeId) {
    case JobFeedTypes.ContractCreated:
    case JobFeedTypes.ContractSent:
    case JobFeedTypes.ContractAccepted:
    case JobFeedTypes.ContractRejected:
        return (
          <div>
            <FlatButton
              icon={<ShowIcon />}
              label="View"
              primary
              containerElement={
                <Link to={{ pathname: `/contract/${foreignTableID}/show` }} />
                        }
            />
          </div>
        );
    case JobFeedTypes.RaiseRequestCreated:
    case JobFeedTypes.RaiseRequestSent:
    case JobFeedTypes.RaiseRequestAccepted:
    case JobFeedTypes.RaiseRequestRejected:
        return (
          <div>
            <FlatButton
              icon={<ShowIcon />}
              label="View"
              primary
              containerElement={
                <Link to={{ pathname: `/raiserequest/${foreignTableID}/show` }} />
                        }
            />
          </div>
        );
    default:
        return <div />;
    }
}
function GenerateRows(items) {
    const rows = items.map(item => (
      <TableRow key={item.id}>
        <TableRowColumn>{moment(item.created_at).format('M/D/YYYY')}</TableRowColumn>
        <TableRowColumn>{item.message}</TableRowColumn>

        <TableRowColumn>
          {GetLink(item.job_setup_feed_type_id, item.foreign_table_id)}
        </TableRowColumn>
      </TableRow>
    ));
    return rows;
}
function JobFeed(props) {
    let items = [];
    if (props && props.data) {
        const keys = Object.keys(props.data);
        items = keys.map(key => props.data[key]);
    }
    return (
      <div>
        <Table>
          <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
            <TableRow>
              <TableHeaderColumn>Date</TableHeaderColumn>
              <TableHeaderColumn>Activity</TableHeaderColumn>
              <TableHeaderColumn>View</TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false}>{GenerateRows(items)}</TableBody>
        </Table>
      </div>
    );
}
export default JobFeed;
