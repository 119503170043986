import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardText, CardTitle } from 'material-ui';
import { CardExpandable } from 'material-ui/Card/index';

export class Boxed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
        };
        this.handleExpandClick = this.handleExpandClick.bind(this);
    }

    componentWillMount() {
        this.setState({
            expanded: this.props.initiallyExpanded === true,
        });
    }

    handleExpandClick() {
        this.setState({ expanded: !this.state.expanded });
    }

    render() {
        const {
            children,
            title,
            containerStyle,
            titleStyle,
            expandable,
            initiallyExpanded,
            showExpandableButton,
            ...props
        } = this.props;
        let content;
        if (Array.isArray(children)) {
            content = React.Children.map(children, child => React.cloneElement(child, props));
        } else {
            content = React.cloneElement(children, props);
        }

        let expander;
        let contentStyle = {};
        if (expandable && !this.state.expanded) {
            contentStyle = { height: 0, overflow: 'hidden', padding: 0, margin: 0 };
        }
        if (title && expandable && showExpandableButton) {
            expander = (
              <CardTitle
                titleStyle={titleStyle}
                onClick={this.handleExpandClick}
                style={{ cursor: 'pointer', ...titleStyle }}
              >
                {title}
                <CardExpandable
                  expanded={this.state.expanded}
                  onExpanding={this.handleExpandClick}
                />
              </CardTitle>
            );
        } else if (title) {
            expander = <CardTitle titleStyle={titleStyle}>{title}</CardTitle>;
        } else {
            expander = '';
        }

        return (
          <Card containerStyle={containerStyle}>
            {expander}
            <CardText style={contentStyle}>{content}</CardText>
          </Card>
        );
    }
}
Boxed.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.node.isRequired,
    titleStyle: PropTypes.object,
    containerStyle: PropTypes.object,
    expandable: PropTypes.bool,
    initiallyExpanded: PropTypes.bool,
    showExpandableButton: PropTypes.bool,
};
Boxed.defaultProps = {
    containerStyle: {},
    titleStyle: {},
    expandable: false,
    initiallyExpanded: false,
    showExpandableButton: true,
};
export default Boxed;
