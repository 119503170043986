import {
    CONTRACT_DOWNLOAD_STARTED,
    CONTRACT_DOWNLOAD_FINISHED,
    CONTRACT_EMAIL_STARTED,
    CONTRACT_EMAIL_FINISHED,
} from '../actions/';

const initialState = {
    downloading: false,
    sendingEmail: false,
};

export const contract = (previousState = initialState, { type, payload }) => {
    if (type === CONTRACT_DOWNLOAD_STARTED) {
        return Object.assign({}, previousState, { downloading: true });
    } else if (type === CONTRACT_DOWNLOAD_FINISHED) {
        return Object.assign({}, previousState, { downloading: false });
    } else if (type === CONTRACT_EMAIL_STARTED) {
        return Object.assign({}, previousState, { sendingEmail: true });
    } else if (type === CONTRACT_EMAIL_FINISHED) {
        return Object.assign({}, previousState, { sendingEmail: false });
    }
    return previousState;
};
