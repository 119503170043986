function compareString(a, b) {
    const stringA = a.text.toUpperCase();
    const stringB = b.text.toUpperCase();
    if (stringA < stringB) {
        return -1;
    } else if (stringA > stringB) {
        return 1;
    }
    return 0;
}
function compareNumber(a, b) {
    const numberA = parseInt(a.text);
    const numberB = parseInt(b.text);
    if (numberA < numberB) {
        return -1;
    } else if (numberA > numberB) {
        return 1;
    }
    return 0;
}

function getTextForSelectBox(textField, record) {
    if (Array.isArray(textField)) {
        const text = textField.map(field => record[field]);
        return text.join(' ');
    }
    return record[textField];
}
export function GetListForSelectWithIdsAndData({
    data,
    label,
    idField,
    textField,
    sort = false,
    sortAsNumber = false,
    filterResolver,
}) {
    const items = [];
    for (let i = 0; i < data.length; i++) {
        const dataToCheck = data[i];
        const id = dataToCheck[idField];
        if (dataToCheck) {
            if (filterResolver === null || filterResolver === undefined) {
                const text = getTextForSelectBox(textField, dataToCheck);
                items.push({
                    id,
                    text,
                });
            } else if (filterResolver(dataToCheck)) {
                const text = getTextForSelectBox(textField, dataToCheck);
                items.push({
                    id,
                    text,
                });
            }
        }
    }
    let functionToSort = compareString;
    if (sortAsNumber) {
        functionToSort = compareNumber;
    }

    return {
        floatingLabelText: label,
        items: sort ? items.sort((a, b) => functionToSort(a, b)) : items,
    };
}

export function GetListForSelect(
    data,
    label,
    idField,
    textField,
    sort = false,
    sortAsNumber = false,
    displayFormatter,
) {
    const listData = Array.isArray(data) ? data : [];
    const items = listData.map((item) => {
        if (typeof displayFormatter === 'function') {
            return {
                id: item[idField],
                text: displayFormatter(item),
            };
        }
        return {
            id: item[idField],
            text: item[textField],
        };
    });
    let functionToSort = compareString;
    if (sortAsNumber) {
        functionToSort = compareNumber;
    }

    return {
        floatingLabelText: label,
        items: sort ? items.sort((a, b) => functionToSort(a, b)) : items,
    };
}
