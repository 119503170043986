import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { RenderCheckbox } from '../../shared/render_field';
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn,
} from 'material-ui/Table';
import roleAccess, { checkAccess } from '../roleaccess';

const AccessControlList = (props) => {
    const { formValues, disabled } = props;
    const userReadDisabled = !checkAccess(formValues, roleAccess, 'user.read');
    const userWriteDisabled = !checkAccess(formValues, roleAccess, 'user.write');
    const pricingGroupReadDisabled = !checkAccess(formValues, roleAccess, 'pricing_group.read');
    const pricingGroupWriteDisabled = !checkAccess(formValues, roleAccess, 'pricing_group.write');
    const laborRateReadDisabled = !checkAccess(formValues, roleAccess, 'labor_rate.read');
    const laborRateWriteDisabled = !checkAccess(formValues, roleAccess, 'labor_rate.write');
    const managementReadDisabled = !checkAccess(formValues, roleAccess, 'management.read');
    const managementWriteDisabled = !checkAccess(formValues, roleAccess, 'management.write');
    const managerReadDisabled = !checkAccess(formValues, roleAccess, 'manager.read');
    const managerWriteDisabled = !checkAccess(formValues, roleAccess, 'manager.write');
    const jobReadDisabled = !checkAccess(formValues, roleAccess, 'job.read');
    const jobWriteDisabled = !checkAccess(formValues, roleAccess, 'job.write');
    const quoteReadDisabled = !checkAccess(formValues, roleAccess, 'quote.read');
    const quoteWriteDisabled = !checkAccess(formValues, roleAccess, 'quote.write');
    const contractTemplateReadDisabled = !checkAccess(
        formValues,
        roleAccess,
        'contract_template.read',
    );
    const contractTemplateWriteDisabled = !checkAccess(
        formValues,
        roleAccess,
        'contract_template.write',
    );
    const raiseRequestRemplateReadDisabled = !checkAccess(
        formValues,
        roleAccess,
        'raise_request_template.read',
    );
    const raiseRequestTemplateWriteDisabled = !checkAccess(
        formValues,
        roleAccess,
        'raise_request_template.write',
    );

    const contractReadDisabled = !checkAccess(formValues, roleAccess, 'contract.read');
    const contractWriteDisabled = !checkAccess(formValues, roleAccess, 'contract.write');
    const raiseRequestReadDisabled = !checkAccess(formValues, roleAccess, 'raise_request.read');
    const raiseRequestWriteDisabled = !checkAccess(formValues, roleAccess, 'raise_request.write');
    return (
      <div>
        <Field
          name="roleAdministrator"
          component={RenderCheckbox}
          disabled={disabled}
          onChange={(event, newValue) => {
              props.updateField(newValue, [
                  'user_read',
                  'pricing_group_read',
                  'labor_rate_read',
                  'management_read',
                  'manager_read',
                  'raise_request_read',
                  'job_read',
                  'contract_read',
                  'contract_template_read',
                  'quote_read',
                  'raise_request_template_read',
                  'user_write',
                  'pricing_group_write',
                  'labor_rate_write',
                  'management_write',
                  'manager_write',
                  'raise_request_write',
                  'job_write',
                  'contract_write',
                  'contract_template_write',
                  'quote_write',
                  'raise_request_template_write',
              ]);
          }}
          label="Is Admin?"
        />

        <Table>
          <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
            <TableRow>
              <TableHeaderColumn>Resource</TableHeaderColumn>
              <TableHeaderColumn>Read</TableHeaderColumn>
              <TableHeaderColumn>Write</TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody displayRowCheckbox={false}>
            <TableRow>
              <TableRowColumn>User</TableRowColumn>
              <TableRowColumn>
                <Field
                  name="user_read"
                  component={RenderCheckbox}
                  disabled={userReadDisabled || disabled}
                />
              </TableRowColumn>
              <TableRowColumn>
                <Field
                  name="user_write"
                  component={RenderCheckbox}
                  disabled={userWriteDisabled || disabled}
                  onChange={(event, newValue) => {
                      props.updateField(newValue, ['user_read']);
                  }}
                />
              </TableRowColumn>
            </TableRow>
            <TableRow>
              <TableRowColumn>Pricing Group</TableRowColumn>
              <TableRowColumn>
                <Field
                  name="pricing_group_read"
                  disabled={pricingGroupReadDisabled || disabled}
                  component={RenderCheckbox}
                />
              </TableRowColumn>
              <TableRowColumn>
                <Field
                  name="pricing_group_write"
                  component={RenderCheckbox}
                  onChange={(event, newValue) =>
                                    props.updateField(newValue, ['pricing_group_read'])}
                  disabled={pricingGroupWriteDisabled || disabled}
                />
              </TableRowColumn>
            </TableRow>
            <TableRow>
              <TableRowColumn>Labor Rate</TableRowColumn>
              <TableRowColumn>
                <Field
                  name="labor_rate_read"
                  disabled={laborRateReadDisabled || disabled}
                  component={RenderCheckbox}
                />
              </TableRowColumn>
              <TableRowColumn>
                <Field
                  name="labor_rate_write"
                  component={RenderCheckbox}
                  onChange={(event, newValue) =>
                                    props.updateField(newValue, ['labor_rate_read'])}
                  disabled={laborRateWriteDisabled || disabled}
                />
              </TableRowColumn>
            </TableRow>
            <TableRow>
              <TableRowColumn>Management</TableRowColumn>
              <TableRowColumn>
                <Field
                  name="management_read"
                  disabled={managementReadDisabled || disabled}
                  component={RenderCheckbox}
                />
              </TableRowColumn>
              <TableRowColumn>
                <Field
                  name="management_write"
                  component={RenderCheckbox}
                  disabled={managementWriteDisabled || disabled}
                  onChange={(event, newValue) =>
                                    props.updateField(newValue, ['management_read'])}
                />
              </TableRowColumn>
            </TableRow>
            <TableRow>
              <TableRowColumn>Manager</TableRowColumn>
              <TableRowColumn>
                <Field
                  name="manager_read"
                  disabled={managerReadDisabled || disabled}
                  component={RenderCheckbox}
                />
              </TableRowColumn>
              <TableRowColumn>
                <Field
                  name="manager_write"
                  component={RenderCheckbox}
                  onChange={(event, newValue) =>
                                    props.updateField(newValue, ['manager_read'])}
                  disabled={managerWriteDisabled || disabled}
                />
              </TableRowColumn>
            </TableRow>
            <TableRow>
              <TableRowColumn>Job</TableRowColumn>
              <TableRowColumn>
                <Field
                  name="job_read"
                  disabled={jobReadDisabled || disabled}
                  component={RenderCheckbox}
                  onChange={(event, newValue) =>
                                    props.updateField(newValue, [
                                        'pricing_group_read',
                                        'labor_rate_read',
                                        'management_read',
                                        'manager_read',
                                    ])}
                />
              </TableRowColumn>
              <TableRowColumn>
                <Field
                  name="job_write"
                  component={RenderCheckbox}
                  disabled={jobWriteDisabled || disabled}
                  onChange={(event, newValue) =>
                                    props.updateField(newValue, [
                                        'user_read',
                                        'pricing_group_read',
                                        'labor_rate_read',
                                        'management_read',
                                        'manager_read',
                                        'job_read',
                                        'user_write',
                                        'pricing_group_write',
                                        'labor_rate_write',
                                        'management_write',
                                        'manager_write',
                                    ])}
                />
              </TableRowColumn>
            </TableRow>
            <TableRow>
              <TableRowColumn>Quote</TableRowColumn>
              <TableRowColumn>
                <Field
                  name="quote_read"
                  disabled={quoteReadDisabled || disabled}
                  component={RenderCheckbox}
                />
              </TableRowColumn>
              <TableRowColumn>
                <Field
                  name="quote_write"
                  component={RenderCheckbox}
                  disabled={quoteWriteDisabled || disabled}
                  onChange={(event, newValue) =>
                                    props.updateField(newValue, ['quote_read'])}
                />
              </TableRowColumn>
            </TableRow>
            <TableRow>
              <TableRowColumn>Contract Template</TableRowColumn>
              <TableRowColumn>
                <Field
                  name="contract_template_read"
                  component={RenderCheckbox}
                  disabled={contractTemplateReadDisabled || disabled}
                />
              </TableRowColumn>
              <TableRowColumn>
                <Field
                  name="contract_template_write"
                  component={RenderCheckbox}
                  disabled={contractTemplateWriteDisabled || disabled}
                  onChange={(event, newValue) =>
                                    props.updateField(newValue, ['contract_template_read'])}
                />
              </TableRowColumn>
            </TableRow>
            <TableRow>
              <TableRowColumn>Contract</TableRowColumn>
              <TableRowColumn>
                <Field
                  name="contract_read"
                  component={RenderCheckbox}
                  disabled={contractReadDisabled || disabled}
                  onChange={(event, newValue) =>
                                    props.updateField(newValue, [
                                        'pricing_group_read',
                                        'labor_rate_read',
                                        'management_read',
                                        'manager_read',
                                        'job_read',
                                        'contract_template_read',
                                    ])}
                />
              </TableRowColumn>
              <TableRowColumn>
                <Field
                  name="contract_write"
                  component={RenderCheckbox}
                  disabled={contractWriteDisabled || disabled}
                  onChange={(event, newValue) =>
                                    props.updateField(newValue, [
                                        'pricing_group_read',
                                        'labor_rate_read',
                                        'management_read',
                                        'manager_read',
                                        'contract_template_read',
                                        'contract_read',
                                        'job_read',
                                        'pricing_group_write',
                                        'labor_rate_write',
                                        'management_write',
                                        'manager_write',
                                        'job_write',
                                        'contract_template_write',
                                    ])}
                />
              </TableRowColumn>
            </TableRow>
            <TableRow>
              <TableRowColumn>Raise request template</TableRowColumn>
              <TableRowColumn>
                <Field
                  name="raise_request_template_read"
                  component={RenderCheckbox}
                  disabled={raiseRequestRemplateReadDisabled || disabled}
                />
              </TableRowColumn>
              <TableRowColumn>
                <Field
                  name="raise_request_template_write"
                  disabled={raiseRequestTemplateWriteDisabled || disabled}
                  component={RenderCheckbox}
                  onChange={(event, newValue) =>
                                    props.updateField(newValue, ['raise_request_template_read'])}
                />
              </TableRowColumn>
            </TableRow>
            <TableRow>
              <TableRowColumn>Raise request</TableRowColumn>
              <TableRowColumn>
                <Field
                  name="raise_request_read"
                  disabled={raiseRequestReadDisabled || disabled}
                  component={RenderCheckbox}
                  onChange={(event, newValue) =>
                                    props.updateField(newValue, [
                                        'pricing_group_read',
                                        'labor_rate_read',
                                        'management_read',
                                        'manager_read',
                                        'job_read',
                                        'raise_request_template_read',
                                    ])}
                />
              </TableRowColumn>
              <TableRowColumn>
                <Field
                  name="raise_request_write"
                  component={RenderCheckbox}
                  disabled={raiseRequestWriteDisabled || disabled}
                  onChange={(event, newValue) =>
                                    props.updateField(newValue, [
                                        'pricing_group_read',
                                        'labor_rate_read',
                                        'management_read',
                                        'manager_read',
                                        'raise_request_read',
                                        'job_read',
                                        'raise_request_template_read',
                                        'pricing_group_write',
                                        'labor_rate_write',
                                        'management_write',
                                        'manager_write',
                                        'job_write',
                                        'raise_request_template_write',
                                    ])}
                />
              </TableRowColumn>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
};
AccessControlList.defaultProps = {
    disabled: false,
};
AccessControlList.propTypes = {
    disabled: PropTypes.bool,
};
export default AccessControlList;
