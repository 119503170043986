import React from 'react';
import {
    List,
    ShowButton,
    ReferenceInput,
    SelectInput,
    Filter,
    ChipField,
    Datagrid,
    TextField,
    FunctionField,
    SimpleList,
    Responsive,
} from 'admin-on-rest';
import GetAcceptedRejected from '../components/accept_reject';

const PostFilter = props => (
  <Filter {...props}>
    <ReferenceInput label="Job" source="job_setup_id" reference="jobsetup">
      <SelectInput optionText="project_name" optionValue="id" />
    </ReferenceInput>
  </Filter>
);
const ListComponent = props => (
  <List title="Contracts" {...props} perPage={10} filters={<PostFilter />}>
    <Responsive
      small={
        <SimpleList
          primaryText={record => record.name}
          secondaryText={record => {
            if (record.accept_reject == null || record.accept_reject === undefined) {
              if (record.emailSent > 0) {
                return 'Awaiting Approval';
              }
              return 'In Progress';
            }
            if (record.accept_reject) {
              return 'Accepted';
            }
            return 'Rejected';
            // console.log('record\n', record);
            // return GetAcceptedRejected(record.accept_reject, record.emailSent)
          }}
          // tertiaryText={record => record.phone}
        />
      }
      medium={
        <Datagrid>
          <TextField source="id" />
          <TextField source="name" />
          <TextField label="Contract Template" sortable={false} source="contract_template.name" />
          <ChipField label="Job" source="job.project_name" sortable={false} />
          <FunctionField
            label="Accepted/Rejected"
            source="accept_reject"
            render={record => GetAcceptedRejected(record.accept_reject, record.emailSent)}
          />
          <ShowButton />
        </Datagrid>
      }
    />
        
  </List>
);
export default ListComponent;
