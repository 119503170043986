import React from 'react';
import moment from 'moment';
import {
    List,
    DateField,
    ShowButton,
    EditButton,
    Datagrid,
    TextField,
    SimpleList,
    Responsive,
} from 'admin-on-rest';
import checkAccess from '../util/acl';
import EnableLaborRateCheckbox from './enable_labor_rate';

const ListComponent = props => (
  <List title="Labor rates" {...props} perPage={10}>
    {permissions => (
      <Responsive
        small={
          <SimpleList
            primaryText={record => record.name}
            secondaryText={record => moment(record.valid_start_date).format('M/D/YYYY')}
          />
                }
        medium={
          <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <DateField source="valid_start_date" />
            <TextField source="duration_in_days" />
            {checkAccess('labor_rate_write', permissions) &&
                            false && <EnableLaborRateCheckbox source="enabled" sortable={false} />}

            <ShowButton />
            {checkAccess('labor_rate_write', permissions) && <EditButton />}
          </Datagrid>
                }
      />
        )}
  </List>
);
export default ListComponent;
