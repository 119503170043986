import {
  cyan500,
  cyan700,
  pinkA200,
  grey100,
  grey300,
  grey400,
  grey500,
  white,
  darkBlack,
  fullBlack,
} from 'material-ui/styles/colors';
import { fade } from 'material-ui/utils/colorManipulator';
import spacing from 'material-ui/styles/spacing';

const myTheme = {
  fontFamily: 'Nunito, Lato, sans-serif',

  // drawer: {
  //     width: '260px',
  // },

  sidebarOpen: {},

  // palette: {
  //     background: {
  //         paper: 'white',
  //     },
  //     paper: {
  //         background: 'white',
  //     },
  // },
  // paper: {
  //     backgroundColor: 'white',
  //     colorDefault: {
  //         backgroundColor: 'white',
  //     },
  //     colorAccent: {
  //         backgroundColor: 'white',
  //     },
  //     colorPrimary: {
  //         backgroundColor: 'white',
  //     },
  // },
  appBar: {
    color: '#ca0c22',
    // color: 'orange',
    textColor: 'black',
    // height: '100px',
    // titleFontWeight: typography.fontWeightNormal,
    // padding: 0,
  },
  paper: {
    color: 'black',
    backgroundColor: 'white',
    zDepthShadows: 'none',
  },

  palette: {
    primary1Color: '#DB2828',
  },
};

export default myTheme;
