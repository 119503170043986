import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Edit } from 'admin-on-rest';
import { connect } from 'react-redux';
import SaveButton from '../components/savebutton';
import { reduxForm, Field } from 'redux-form';
import { Toolbar, ToolbarGroup } from 'material-ui/Toolbar';
import RenderField, { RenderEditor } from '../shared/render_field';
import { required } from '../util/validate';
import TokensList from './tokens_supported';
import '../styles/form.css';

const formName = 'record-form';

class RaiseRequestTemplateForm extends Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this);
    }
    onSave(formData) {
        const { name, content } = formData;
        this.props.save({
            name,
            content,
        });
    }

    render() {
        const { loading, save, handleSubmit, submitting } = this.props;
        return (
          <form className="simple-form" onSubmit={handleSubmit(this.onSave)}>
            <div className="field">
              <Field
                name="name"
                type="text"
                component={RenderField}
                validate={[required]}
                floatingLabelText="Name"
              />
              <div>
                <h4>Tokens Supported:</h4>
                <div>
                  <TokensList />
                </div>
              </div>
              <div>
                <div>
                  <Field name="content" component={RenderEditor} />
                </div>
              </div>
            </div>
            <Toolbar>
              <ToolbarGroup lastChild>
                <SaveButton label="Save" loading={loading} />
              </ToolbarGroup>
            </Toolbar>
          </form>
        );
    }
}

function mapStateToProps(state, thisprops) {
    const { name, content } = thisprops.record;
    return {
        loading: state.admin.loading,
        save: thisprops.save,
        initialValues: {
            name,
            content,
        },
    };
}

let RaiseRequestTemplateReduxForm = reduxForm({
    form: formName, // <------ same form name
    destroyOnUnmount: true, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(RaiseRequestTemplateForm);

RaiseRequestTemplateReduxForm = connect(mapStateToProps)(RaiseRequestTemplateReduxForm);

class EnhancedContainer extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { record, save } = this.props;
        return (
          <div>
            <RaiseRequestTemplateReduxForm record={record} save={save} />
          </div>
        );
    }
}

class EditForm extends Component {
    render() {
        return (
          <Edit title="Edit raise request template" {...this.props}>
            <EnhancedContainer />
          </Edit>
        );
    }
}
export default EditForm;
