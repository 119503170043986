import { call, put, takeEvery, all } from 'redux-saga/effects';
import {
    PASSWORD_RESET,
    PASSWORD_FORGOT,
    passwordForgotStarted,
    passwordForgotFinished,
    passwordResetStarted,
    passwordResetFinished,
 } from '../actions/';
import { push } from 'react-router-redux';
import { showNotification, userLogout } from 'admin-on-rest';
import { resetPassword, forgotPassword } from '../api';
import { sleep } from '../util/sleep';


function* passwordReset({ password, logoutAfterReset }) {
    try {
        yield put(passwordResetStarted());
        yield call(resetPassword, password);
        yield put(passwordResetFinished());
        yield put(showNotification('Password updated!'));
        yield sleep(2000);
        if (logoutAfterReset) {
            yield put(userLogout());
            yield put(showNotification('Please login with updated password'));
        }
    } catch (err) {
        yield put(passwordResetFinished());
        yield put(showNotification(err.message));
    }
}

function* passwordForgot({ email }) {
    try {
        yield put(passwordForgotStarted());
        yield call(forgotPassword, email);
        yield put(showNotification('Reset password email sent!'));
        yield sleep(1500);
        yield put(passwordForgotFinished());
        yield put(userLogout());
    } catch (err) {
        yield put(passwordForgotFinished());
        yield put(showNotification(err.message));
    }
}

export function* passwordSaga() {
    yield all([
        takeEvery(PASSWORD_RESET, passwordReset),
        takeEvery(PASSWORD_FORGOT, passwordForgot),
    ]);
}
