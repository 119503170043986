const initialValues = {
    legal_name: 'johnson',
    detail_hours: '100',
    mowing_hours: '10',
    irrigation_hours: '10',
    total_price: '10000',
    project_name: 'Bodhi Leaf',
    job_project_type_id: 1,
    street_address: '428 W Katella Ave',
    city: 'Anaheim',
    state: 'Ca',
    zip: '92806',
    fiscal_year: 1,
    start_date: new Date(),
    yard_id: 1,
    original_price: 500,
    price_note: 'test',
};
export default initialValues;
