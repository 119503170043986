import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { push as pushAction } from 'react-router-redux';
import autoprefixer from 'material-ui/utils/autoprefixer';
import withWidth from 'material-ui/utils/withWidth';
import compose from 'recompose/compose';
import { AppBarMobile } from 'admin-on-rest';
import MyTheme from './mytheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { Card, CardTitle, CardText } from 'material-ui/Card';
import { cyan500, pinkA200 } from 'material-ui/styles/colors';
import defaultTheme from 'admin-on-rest/lib/mui/defaultTheme';

const styles = {
    wrapper: {
        // Avoid IE bug with Flexbox, see #467
        display: 'flex',
        flexDirection: 'column',
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    body: {
        backgroundColor: 'white',
        display: 'flex',
        flex: 1,
        overflowY: 'hidden',
        overflowX: 'scroll',
    },
    bodySmall: {
        backgroundColor: '#fff',
        padding: 0,
    },
    content: {
        flex: 1,
        padding: '2em',
    },
    contentSmall: {
        flex: 1,
        padding: '0',
    },
    loader: {
        position: 'absolute',
        top: 0,
        right: 0,
        margin: 16,
        zIndex: 1200,
    },
};
const prefixedStyles = {};

function getColorsFromTheme(theme) {
    if (!theme) return { primary1Color: cyan500, accent1Color: pinkA200 };
    const { palette: { primary1Color, accent1Color } } = theme;
    return { primary1Color, accent1Color };
}

const translate = function (key) {
    return key;
};

class BtcLoginPage extends Component {
    render() {
        const { theme, width } = this.props;
        const muiTheme = getMuiTheme(MyTheme);
        const { primary1Color, accent1Color } = getColorsFromTheme(muiTheme);

        if (!prefixedStyles.main) {
            // do this once because user agent never changes
            const prefix = autoprefixer(muiTheme);
            prefixedStyles.wrapper = prefix(styles.wrapper);
            prefixedStyles.main = prefix(styles.main);
            prefixedStyles.body = prefix(styles.body);
            prefixedStyles.bodySmall = prefix(styles.bodySmall);
            prefixedStyles.content = prefix(styles.content);
            prefixedStyles.contentSmall = prefix(styles.contentSmall);
        }

        return (
          <MuiThemeProvider muiTheme={muiTheme}>
            <div style={prefixedStyles.wrapper}>
              <div style={prefixedStyles.main}>
                {width === 1 && <AppBarMobile title="Dashboard" />}
                <div
                  className="body"
                  style={width === 1 ? prefixedStyles.bodySmall : prefixedStyles.body}
                >
                  <div
                    style={
                                    width === 1
                                        ? prefixedStyles.contentSmall
                                        : prefixedStyles.content
                                }
                  >
                    <Card style={{ margin: '2em' }}>
                      <CardTitle title="Welcome to the O’Connell Landscape Maintenance Hub! " />
                      <CardText>
                                        This is the new internal hub for all things OCLM. Here you
                                        can manage the entire quote process - from creation to
                                        approval.
                                        <br />
                        <br />
                                        Please be patient as this is just the first version. New
                                        updates will continue to be added weekly. We will be
                                        continuously adding new features and will be looking for
                                        feedback from the team to let us know how we can improve it.
                                    </CardText>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </MuiThemeProvider>
        );
    }
}

BtcLoginPage.defaultProps = {
    theme: defaultTheme,
};

function mapStateToProps(state) {
    return {
        loginLoading: state.login.loginLoading,
        signInError: false,
    };
}
const enhance = compose(translate, connect(mapStateToProps, { push: pushAction }), withWidth());

export default enhance(BtcLoginPage);
