/**
 * Convert a `File` object returned by the upload input into
 * a base 64 string. That's easier to use on FakeRest, used on
 * the ng-admin example. But that's probably not the most optimized
 * way to do in a production database.
 */
const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const addUploadCapabilities = requestHandler => (type, resource, params) => {
    if (type === 'CREATE' && resource === 'contract') {
        let exhibitAToBase64 = Promise.resolve();
        let exhibitCToBase64 = Promise.resolve();
        if (params.data.exhibita && params.data.exhibita.length > 0) {
            const exhibitAFile = params.data.exhibita[0];
            if (exhibitAFile.rawFile instanceof File) {
                exhibitAToBase64 = convertFileToBase64(exhibitAFile.rawFile);
            }
        }
        if (params.data.exhibitc && params.data.exhibitc.length > 0) {
            const exhibitCFile = params.data.exhibitc[0];

            if (exhibitCFile.rawFile instanceof File) {
                exhibitCToBase64 = convertFileToBase64(exhibitCFile.rawFile);
            }
        }
        const promises = [exhibitAToBase64, exhibitCToBase64];
        return Promise.all(promises).then((result) => {
            const exhibitAResult = result[0];
            const exhibitCResult = result[1];
            return requestHandler(type, resource, {
                ...params,
                data: {
                    ...params.data,
                    exhibit_a: exhibitAResult,
                    exhibit_c: exhibitCResult,
                },
            });
        });
    }

    return requestHandler(type, resource, params);
};

export default addUploadCapabilities;
