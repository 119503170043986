import { SEARCH_FINISHED, SEARCH_INPUT_CHANGED, SEARCH_CLEAR_RESULTS } from '../actions/';

const initialState = {
    inputValue: '',
    results: [],
};

export const search = (previousState = initialState, { type, payload }) => {
    switch (type) {
    case SEARCH_INPUT_CHANGED:
        return {
            ...previousState,
            inputValue: payload,
        };
    case SEARCH_CLEAR_RESULTS: {
        return {
            ...previousState,
            results: [],
        };
    }
    case SEARCH_FINISHED:
        return {
            ...previousState,
            results: payload,
        };
    default:
        return previousState;
    }
};
