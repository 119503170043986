import React, { Component } from 'react';
import { ViewTitle } from 'admin-on-rest';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { bindActionCreators } from 'redux';
import { passwordReset, loginManually } from '../actions';
import { Card, RaisedButton, CircularProgress, CardActions, TextField } from 'material-ui';
import compose from 'recompose/compose';
import { GetQueryParams } from '../util/url';

const renderInput = ({ meta: { touched, error } = {}, input: { ...inputProps }, ...props }) => (
  <TextField errorText={touched && error} {...inputProps} {...props} />
);

const translate = function (key) {
    return key;
};

const formStyle = { padding: '0 1em 1em 1em' };
class Activate extends Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this);
    }
    componentWillMount() {
        const queryParams = GetQueryParams(this.props.location.search);
        const { token, logoff } = queryParams;
        this.logoutAfterReset = false;
        if (token) {
            const roles = [];
            this.logoutAfterReset = true;
            this.props.actions.loginManually(token, roles);
        }
        if (logoff) {
            this.logoutAfterReset = true;
        }
    }

    onSave(val) {
        if (val.password) {
            this.props.actions.passwordReset(val.password, this.logoutAfterReset);
        }
    }
    render() {
        const { handleSubmit, loading } = this.props;
        return (
          <Card>
            <ViewTitle title="Update password" />
            <form onSubmit={handleSubmit(this.onSave)}>
              <div style={formStyle}>
                <div>
                  <Field
                    name="password"
                    component={renderInput}
                    floatingLabelText={'Password'}
                    type="password"
                    disabled={this.props.loading}
                  />
                </div>
                <div>
                  <Field
                    name="confirmpassword"
                    component={renderInput}
                    floatingLabelText={'Confirm Password'}
                    type="password"
                    disabled={this.props.loading}
                  />
                </div>
              </div>
              <CardActions>
                <RaisedButton
                  type="submit"
                  primary
                  disabled={loading}
                  icon={loading && <CircularProgress size={25} thickness={2} />}
                  label={'Save'}
                />
              </CardActions>
            </form>
          </Card>
        );
    }
}
function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators({ passwordReset, loginManually }, dispatch) };
}
function mapStateToProps(state) {
    return {
        loading: state.login.passwordResetLoading,
    };
}

const ActivateWithRouter = withRouter(Activate);

const enhance = compose(
    translate,
    reduxForm({
        form: 'passwordReset',
        validate: (values) => {
            const errors = {};
            if (!values.password) errors.password = 'Required';
            if (!values.confirmpassword) errors.confirmpassword = 'Required';
            if (values.password !== values.confirmpassword) { errors.confirmpassword = 'Passwords do not match'; }
            return errors;
        },
    }),
    connect(mapStateToProps, mapDispatchToProps),
);
export default enhance(ActivateWithRouter);
