import { UPDATE, GET_LIST, CRUD_GET_LIST } from 'admin-on-rest';

export const ENABLE_USER = 'ENABLE_USER';
export const enableUser = (id, data, basePath) => ({
    type: ENABLE_USER,
    payload: { id, data: { ...data, enabled: !data.enabled } },
    meta: { resource: 'user', fetch: UPDATE, cancelPrevious: false },
});

export const FETCH_USERS = 'FETCH_USERS';
export const fetchUsers = data => ({
    type: CRUD_GET_LIST,
    payload: { ...data },
    meta: { resource: 'user', fetch: GET_LIST, cancelPrevious: false },
});
