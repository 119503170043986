import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import RenderField, { RenderCheckbox, RenderCheckboxList } from '../../shared/render_field';
import { required } from '../../util/validate';
import { GetListForSelect } from '../../util/parse';
import styles from '../../styles';

const PrimaryInfoFormFields = {
    title: 'title',
    firstName: 'firstName',
    lastName: 'lastName',
    suffix: 'suffix',
    email: 'email',
    phone: 'phone',
    cell: 'cell',
    officeIds: 'officeIds',
};
const PrimaryInfo = (props) => {
    const { disabled, offices } = props;
    const propsForOfficeSelect = GetListForSelect(offices, 'Office', 'id', 'name', true, false);
    propsForOfficeSelect.options = [...propsForOfficeSelect.items];
    return (
      <div>
        <div style={{ ...styles.row }} className="text-row">
          <Field
            name="title"
            type="text"
            disabled={disabled}
            component={RenderField}
            floatingLabelText="Title"
            style={{ ...styles.row.field }}
          />
        </div>
        <div style={{ ...styles.row }} className="text-row">
          <Field
            disabled={disabled}
            name="firstName"
            type="text"
            component={RenderField}
            validate={[required]}
            floatingLabelText="First Name"
            style={{ ...styles.row.field }}
          />
          <Field
            disabled={disabled}
            name="lastName"
            type="text"
            component={RenderField}
            validate={[required]}
            floatingLabelText="Last Name"
            style={{ ...styles.row.field }}
          />
        </div>
        <div style={{ ...styles.row }} className="text-row">
          <Field
            disabled={disabled}
            name="suffix"
            type="text"
            component={RenderField}
            floatingLabelText="Suffix"
            style={{ ...styles.row.field }}
          />
        </div>
        <div style={{ ...styles.row }} className="text-row">
          <Field
            disabled={disabled || props.isEditing}
            name="email"
            type="text"
            validate={[required]}
            component={RenderField}
            floatingLabelText="Email"
            style={{ ...styles.row.field }}
          />
        </div>
        <div style={{ ...styles.row }} className="text-row">
          <Field
            disabled={disabled}
            name="phone"
            type="text"
            component={RenderField}
            floatingLabelText="Phone"
            style={{ ...styles.row.field }}
          />
          <Field
            disabled={disabled}
            name="cell"
            type="text"
            component={RenderField}
            floatingLabelText="Cell"
            style={{ ...styles.row.field }}
          />
        </div>
        <div style={{ ...styles.row }} className="text-row office-checkboxes">
          <Field
            disabled={disabled}
            name="officeIds"
            props={propsForOfficeSelect}
            label="Office"
            component={RenderCheckboxList}
            validate={[]}
            style={{ ...styles.row.field }}
          />
        </div>
        <div style={{ ...styles.row }} className="text-row">
          <Field
            name="is_supervisor"
            component={RenderCheckbox}
            disabled={disabled}
            label="Is Supervisor?"
            style={{ ...styles.row.field }}
          />
        </div>

        <div style={{ ...styles.row }} className="text-row">
          <Field
            name="is_regional_manager"
            component={RenderCheckbox}
            disabled={disabled}
            label="Is Regional Manager?"
            style={{ ...styles.row.field }}
          />
        </div>
      </div>
    );
};

PrimaryInfo.defaultProps = {
    disabled: false,
    isEditing: false,
};
PrimaryInfo.propTypes = {
    disabled: PropTypes.bool,
    isEditing: PropTypes.bool,
};

export default PrimaryInfo;
export { PrimaryInfoFormFields };
