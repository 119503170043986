import { call, put, takeEvery, all } from 'redux-saga/effects';
import { showNotification } from 'admin-on-rest';
import { ENABLE_USER } from '../actions/';
import { enableUser } from '../api';

function* enableUserHandler(data) {
    try {
        yield call(enableUser, data);
    } catch (err) {
        yield put(showNotification(err.message));
    }
}

export function* userSaga() {
    yield all([
        // takeEvery(ENABLE_USER, enableUserHandler),
    ]);
}
