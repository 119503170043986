import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Edit } from 'admin-on-rest';
import { fetchManagement } from '../actions';
import SaveButton from '../components/savebutton';
import { Toolbar, ToolbarGroup } from 'material-ui/Toolbar';
import { required } from '../util/validate';
import RenderField, { RenderSelect } from '../shared/render_field';
import { GetListForSelect } from '../util/parse';
import { Field, reduxForm } from 'redux-form';
import styles from '../styles';
import Divider from 'material-ui/Divider';
import Subheader from 'material-ui/Subheader';
import { companyNameFormatter } from './utils';

const formName = 'record-form';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this);
    }
    componentDidMount() {
        this.props.actions.fetchManagement({
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'company_name', order: 'ASC' },
            filter: {},
        });
    }
    onSave(formData) {
        this.props.save(formData, 'list');
    }
    render() {
        const { save, loading, handleSubmit, management } = this.props;

        const propsForMangementSelect = GetListForSelect(
            Object.values(management),
            'Management',
            'id',
            'company_name',
            null,
            null,
            companyNameFormatter,
        );

        console.log('propsForMangementSelect', propsForMangementSelect);
        return (
          <form className="simple-form" onSubmit={handleSubmit(this.onSave)}>
            <div className="field">
              <div>
                <Subheader style={{ ...styles.subheader }}>Manager Info</Subheader>
                <Divider />

                <div style={{ ...styles.row }}>
                  <Field
                    name="name"
                    type="text"
                    component={RenderField}
                    validate={[required]}
                    floatingLabelText="Name"
                    style={{ ...styles.row.field }}
                  />
                </div>
                <div style={{ ...styles.row }}>
                  <Field
                    name="email"
                    type="text"
                    component={RenderField}
                    validate={[required]}
                    floatingLabelText="Email"
                    style={{ ...styles.row.field }}
                  />
                </div>
                <div style={{ ...styles.row }}>
                  <Field
                    name="phone"
                    type="text"
                    component={RenderField}
                    validate={[required]}
                    floatingLabelText="Phone"
                    style={{ ...styles.row.field }}
                  />
                </div>
                {propsForMangementSelect.items.length > 0 && (
                <div style={{ ...styles.row }}>
                  <Field
                    name="management_company_id"
                    props={propsForMangementSelect}
                    component={RenderSelect}
                    style={{ ...styles.row.field }}
                  />
                </div>
                        )}
              </div>
            </div>
            <Toolbar>
              <ToolbarGroup lastChild>
                <SaveButton label="Save" loading={loading} />
              </ToolbarGroup>
            </Toolbar>
          </form>
        );
    }
}

let CreateReduxForm = reduxForm({
    form: formName, // <------ same form name
    destroyOnUnmount: true, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(Contact);

function mapStateToProps(state, thisprops) {
    return {
        save: thisprops.save,
        loading: state.admin.loading,
        management: state.admin.resources.management.data,
        initialValues: thisprops.record,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                fetchManagement,
            },
            dispatch,
        ),
    };
}

CreateReduxForm = connect(mapStateToProps, mapDispatchToProps)(CreateReduxForm);

class EnhancedContainer extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { save } = this.props;
        return (
          <div>
            <CreateReduxForm save={save} />
          </div>
        );
    }
}

class EditComponent extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
          <Edit title="Edit manager" {...this.props}>
            <EnhancedContainer />
          </Edit>
        );
    }
}
export default EditComponent;
