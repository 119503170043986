import { simpleRestClient, fetchUtils } from 'admin-on-rest';
// import axios from 'axios';

export const baseUrl = window.API_URL;
export const loginAPIUrl = window.LOGIN_URL;

// axios.defaults.baseURL = baseUrl;
// axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

// export default simpleRestClient(baseUrl);

const httpClient = (url, options = {}) => {
  const token = localStorage.getItem('token');
  if (!options.headers) {
    options.headers = new Headers({
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    });
  }
  options.credentials = 'same-origin';
  return fetchUtils.fetchJson(url, options);
};
const restClient = simpleRestClient(baseUrl, httpClient);
export default restClient;
