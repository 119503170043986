import React from 'react';
import { Admin, Resource, Delete } from 'admin-on-rest';
import UserIcon from 'material-ui/svg-icons/social/group';
import { UserCreate, UserList, UserShow, UserEdit } from './users';
import {
  PricingGroupCreate,
  PricingGroupList,
  PricingGroupShow,
  PricingGroupEdit,
} from './pricing_groups';
import {
  LaborRateShow,
  LaborRateList,
  LaborRateEdit,
  LaborRateCreate,
} from './labor_rate';
import {
  ManagementInfoList,
  ManagementInfoEdit,
  ManagementInfoCreate,
  ManagementInfoShow,
} from './management_info';
import {
  ManagerList,
  ManagerEdit,
  ManagerCreate,
  ManagerShow,
} from './manager';
import {
  JobSetupCreate,
  JobSetupEdit,
  JobSetupList,
  JobSetupShow,
} from './job_setup';
import {
  ContractTemplateCreate,
  ContractTemplateList,
  ContractTemplateShow,
  ContractTemplateEdit,
} from './contracttemplate';

import { ContractCreate, ContractList, ContractShow } from './contract';

import {
  RaiseRequestTemplateCreate,
  RaiseRequestTemplateEdit,
  RaiseRequestTemplateList,
  RaiseRequestTemplateShow,
} from './raise_request_template';
import {
  RaiseRequestCreate,
  RaiseRequestList,
  RaiseRequestShow,
} from './raise_request';
import Dashboard from './dashboard';
import authClient from './authClient';
import customRoutes from './routes';
import Menu from './menu';
import MyLayout from './mylayout';
import MyTheme from './mytheme';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import {
  loginSaga,
  activateSaga,
  userSaga,
  passwordSaga,
  contractDownloadSaga,
  raiseRequestDownloadSaga,
  errorSagas,
  contractEmailSaga,
  raiseRequestEmailSaga,
  searchSaga,
} from './sagas';
import { login, contract, search } from './reducers';
import client from './client';
import addUploadCapabilities from './enhanced_rest_client';
import loginPage from './login';
import logoPng from './logo-2023.png';
import checkAccess from './util/acl';

const restClient = addUploadCapabilities(client);

const Header = () => (
  <a href="/" title="Back to Home">
    <img src={logoPng} className="main-logo" alt="OCLM Logo" width="200" />
  </a>
);
const App = () => (
  <Admin
    loginPage={loginPage}
    authClient={authClient}
    customReducers={{ login, contract, search }}
    customRoutes={customRoutes}
    customSagas={[
      loginSaga,
      activateSaga,
      userSaga,
      passwordSaga,
      contractDownloadSaga,
      raiseRequestDownloadSaga,
      errorSagas,
      contractEmailSaga,
      raiseRequestEmailSaga,
      searchSaga,
    ]}
    appLayout={MyLayout}
    theme={getMuiTheme(MyTheme)}
    menu={Menu}
    dashboard={Dashboard}
    title={<Header />}
    restClient={restClient}
  >
    {permissions => [
      <Resource
        name="user"
        icon={UserIcon}
        edit={checkAccess('user_write', permissions) ? UserEdit : null}
        create={checkAccess('user_write', permissions) ? UserCreate : null}
        show={checkAccess('user_read', permissions) ? UserShow : null}
        list={checkAccess('user_read', permissions) ? UserList : null}
        remove={checkAccess('user_delete', permissions) ? Delete : null}
      />,
      <Resource
        name="pricinggroup"
        icon={UserIcon}
        create={
          checkAccess('pricing_group_write', permissions)
            ? PricingGroupCreate
            : null
        }
        edit={
          checkAccess('pricing_group_write', permissions)
            ? PricingGroupEdit
            : null
        }
        show={
          checkAccess('pricing_group_read', permissions)
            ? PricingGroupShow
            : null
        }
        list={
          checkAccess('pricing_group_read', permissions)
            ? PricingGroupList
            : null
        }
        remove={checkAccess('pricing_group_write', permissions) ? Delete : null}
      />,
      <Resource
        name="laborrate"
        icon={UserIcon}
        create={
          checkAccess('labor_rate_write', permissions) ? LaborRateCreate : null
        }
        edit={
          checkAccess('labor_rate_write', permissions) ? LaborRateEdit : null
        }
        show={
          checkAccess('labor_rate_read', permissions) ? LaborRateShow : null
        }
        list={
          checkAccess('labor_rate_read', permissions) ? LaborRateList : null
        }
        remove={checkAccess('labor_rate_write', permissions) ? Delete : null}
      />,
      <Resource
        name="management"
        icon={UserIcon}
        create={
          checkAccess('management_write', permissions)
            ? ManagementInfoCreate
            : null
        }
        edit={
          checkAccess('management_write', permissions)
            ? ManagementInfoEdit
            : null
        }
        show={
          checkAccess('management_read', permissions)
            ? ManagementInfoShow
            : null
        }
        list={
          checkAccess('management_read', permissions)
            ? ManagementInfoList
            : null
        }
        remove={checkAccess('management_write', permissions) ? Delete : null}
      />,
      <Resource
        name="manager"
        icon={UserIcon}
        create={
          checkAccess('manager_write', permissions) ? ManagerCreate : null
        }
        edit={checkAccess('manager_write', permissions) ? ManagerEdit : null}
        show={checkAccess('manager_read', permissions) ? ManagerShow : null}
        list={checkAccess('manager_read', permissions) ? ManagerList : null}
        remove={checkAccess('manager_write', permissions) ? Delete : null}
      />,
      <Resource
        name="jobsetup"
        icon={UserIcon}
        create={checkAccess('job_write', permissions) ? JobSetupCreate : null}
        edit={checkAccess('job_write', permissions) ? JobSetupEdit : null}
        show={checkAccess('job_read', permissions) ? JobSetupShow : null}
        list={checkAccess('job_read', permissions) ? JobSetupList : null}
        remove={checkAccess('job_write', permissions) ? Delete : null}
      />,
      <Resource
        name="contracttemplate"
        icon={UserIcon}
        create={
          checkAccess('contract_template_write', permissions)
            ? ContractTemplateCreate
            : null
        }
        edit={
          checkAccess('contract_template_write', permissions)
            ? ContractTemplateEdit
            : null
        }
        show={
          checkAccess('contract_template_read', permissions)
            ? ContractTemplateShow
            : null
        }
        list={
          checkAccess('contract_template_read', permissions)
            ? ContractTemplateList
            : null
        }
        remove={
          checkAccess('contract_template_write', permissions) ? Delete : null
        }
      />,
      <Resource
        name="contract"
        icon={UserIcon}
        create={
          checkAccess('contract_write', permissions) ? ContractCreate : null
        }
        edit={checkAccess('contract_read', permissions) ? ContractShow : null}
        show={checkAccess('contract_read', permissions) ? ContractShow : null}
        list={checkAccess('contract_read', permissions) ? ContractList : null}
        remove={checkAccess('contract_write', permissions) ? Delete : null}
      />,
      <Resource
        name="raiserequesttemplate"
        icon={UserIcon}
        create={
          checkAccess('raise_request_template_write', permissions)
            ? RaiseRequestTemplateCreate
            : null
        }
        edit={
          checkAccess('raise_request_template_write', permissions)
            ? RaiseRequestTemplateEdit
            : null
        }
        show={
          checkAccess('raise_request_template_read', permissions)
            ? RaiseRequestTemplateShow
            : null
        }
        list={
          checkAccess('raise_request_template_read', permissions)
            ? RaiseRequestTemplateList
            : null
        }
      />,
      <Resource
        name="raiserequest"
        icon={UserIcon}
        create={
          checkAccess('raise_request_write', permissions)
            ? RaiseRequestCreate
            : null
        }
        edit={
          checkAccess('raise_request_read', permissions)
            ? RaiseRequestShow
            : null
        }
        show={
          checkAccess('raise_request_read', permissions)
            ? RaiseRequestShow
            : null
        }
        list={
          checkAccess('raise_request_read', permissions)
            ? RaiseRequestList
            : null
        }
        remove={checkAccess('raise_request_write', permissions) ? Delete : null}
      />,
      <Resource name="projecttype" />,
      <Resource name="yard" />,
      <Resource name="deductiontype" />,
      <Resource name="specificationtype" />,
      <Resource name="contractdownload" />,
      <Resource name="contractemail" />,
      <Resource name="raiserequestdownload" />,
      <Resource name="emailtransaction" />,
      <Resource name="jobsetupfeed" />,
      <Resource name="office" />,
    ]}
  </Admin>
);
export default App;
