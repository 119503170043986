import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import DatePicker from 'material-ui/DatePicker';
import Checkbox from 'material-ui/Checkbox';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { Editor } from 'react-draft-wysiwyg';
import { ImageField, NumberInput, LongTextInput } from 'admin-on-rest';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import ImageInput from '../components/ImageInput';
import FileInput from '../components/ImageInput';
import { bytesToSize } from '../util/format';
import styles from './style.css';
import '../styles/form.css';
import '../styles/react-draft-wysiwyg.css';
import '../styles/draft.css';

const cssStyles = {
    errorLabel: {
        bottom: '15px',
        fontSize: '12px',
        lineHeight: '12px',
        color: 'rgb(244, 67, 54)',
        transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    },
};
const renderField = ({ meta: { touched, error } = {}, input: { ...inputProps }, ...props }) => (
  <div>
    <TextField errorText={touched && error} {...inputProps} {...props} />
  </div>
);

function parsePercentage(val) {
    return val.replace(/[%]/g, '');
}
function parseCurrency(val) {
    return val.replace(/[$,]/g, '');
}
export const RenderPercentage = props => (
  <RenderMaskedInput
    mask={createNumberMask({ allowDecimal: false, prefix: '', suffix: '%' })}
    parseFunction={parsePercentage}
    {...props}
  />
);

export const RenderCurrency = props => (
  <RenderMaskedInput
    mask={createNumberMask({ allowDecimal: true, prefix: '$' })}
    parseFunction={parseCurrency}
    {...props}
  />
);
class RenderMaskedInput extends Component {
    constructor(props) {
        super(props);
        const { meta, input } = props;
        const { onChange, value } = input;
        this.state = {
            value: props.input.value,
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        const { readOnly, parseFunction } = this.props;
        if (readOnly) {
          return;
        }
        this.setState({
            value: e.target.value,
        });
        
      this.props.input.onChange(parseFunction(e.target.value));
    }
    componentWillReceiveProps(nextProps) {
        // this is a hack around populating the value from props
        // we want to drive the input mask from state but
        // when we change the value from outside, we would like
        // to update the state from that value, which is passed
        // in as props

        if (nextProps.input.value !== this.props.input.value){
          this.setState({
            value: nextProps.input.value
          });
        }
        // if (nextProps.meta.initial !== this.props.meta.initial) {
        //     this.setState({
        //         value: nextProps.meta.initial,
        //     });
        // }
    }
    render() {

        const { props } = this;
        const { meta, mask } = props;
        const { floatingLabelText, disabled } = props;
        const { value } = this.state;
        return (
          <div>
            <TextField
              id={props.source}
              errorText={meta.touched && meta.error}
              onChange={this.handleChange}
              floatingLabelText={floatingLabelText}
              disabled={disabled}
              style={{ ...props.style }}
            >
              <MaskedInput value={value} mask={mask} />
            </TextField>
          </div>
        );
    }
}
RenderMaskedInput.defaultProps = {
  readOnly: false
}


export const RenderNumberInput = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
}) => (
  <div>
    <NumberInput
      label={props.floatingLabelText}
      errorText={touched && error}
      meta={{ touched, error }}
      input={{ ...inputProps }}
      disabled={props.disabled}
      {...props}
    />
  </div>
);
function openFile(e, imageSrc) {
    e.preventDefault();
    const image = new Image();
    image.src = imageSrc;
    const w = window.open('');
    w.document.write(image.outerHTML);
}

export const RenderContent = (props) => {
    const { content } = props.record;
    const html = draftToHtml(content);
    const markup = { __html: html };

    return (
      <div style={{ width: '100%', paddingTop: '32px' }}>
        <label style={{ display: 'inline-block', marginBottom: '10px' }}>{props.label}</label>
        <div dangerouslySetInnerHTML={markup} />
      </div>
    );
};

export const RenderExhibitImage = (props) => {
    const { image_type, pic } = props.record;
    if (!image_type) {
        return <div />;
    }
    const imageSrc = `data:image/${image_type};base64,${pic}`;
    return (
      <div style={{ width: '100%', paddingTop: '32px' }}>
        <label style={{ display: 'inline-block', marginBottom: '10px' }}>{props.label}</label>
        <div className="exhibitImageContainer">
          <a href="#" onClick={e => openFile(e, imageSrc)} target="_blank">
            <img
              src={imageSrc}
              style={{ maxWidth: '100%', height: '150px' }}
              alt="exhibit"
            />
          </a>
        </div>
      </div>
    );
};
export const RenderLongTextInput = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
}) => (
  <div style={{ width: '100%' }}>
    <LongTextInput
      errorText={touched && error}
      meta={{ touched, error }}
      input={{ ...inputProps }}
      {...props}
    />
  </div>
);
export class RenderImageInput extends Component {
    constructor(props) {
        super(props);
        this.state = { accepted: [], rejected: [] };
    }
    render() {
        const { meta: { touched, error } = {}, input: { ...inputProps }, maxBytes } = this.props;
        return (
          <div style={{ width: '100%', paddingTop: '32px' }}>
            <label style={{ display: 'inline-block', marginBottom: '10px' }}>
              {this.props.label}
            </label>
            <ImageInput
              input={{ ...inputProps }}
              maxSize={maxBytes}
              accept="image/png, image/jpeg"
              label="Exhibit C"
              onDropAfter={(accepted, rejected) => {
                  this.setState({ rejected });
              }}
            >
              <ImageField source="src" title="title" />
            </ImageInput>
            {this.state.rejected.length > 0 && (
            <div
              style={{
                  background: 'red',
                  border: 'dashed 1px',
                  padding: '10px',
                  color: 'white',
                  marginTop: '10px',
              }}
            >
              <h2>Rejected files</h2>
              <ul>
                {this.state.rejected.map(f => (
                  <li key={f.name}>{`${f.name} - ${bytesToSize(
                                    f.size,
                                )} is over ${bytesToSize(
                                    maxBytes,
                                )}. Choose a smaller image size or use an external tool to resize`}</li>
                            ))}
              </ul>
            </div>
                )}
          </div>
        );
    }
}
RenderImageInput.defaultProps = {
    maxBytes: 1024 * 1024 * 2,
};
export const RenderImageInput1 = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
}) => (
  <div style={{ width: '100%', paddingTop: '32px' }}>
    <label style={{ display: 'inline-block', marginBottom: '10px' }}>{props.label}</label>
    <ImageInput
      input={{ ...inputProps }}
      maxSize={1000000}
      accept="image/png, image/jpeg"
      label="Exhibit C"
    >
      <ImageField source="src" title="title" />
    </ImageInput>
  </div>
);

export const RenderCheckboxList = ({
    label,
    required,
    name,
    options,
    input,
    meta: { touched, error, warning },
}) => (
  <div style={{ width: '100%', paddingTop: '32px', marginBottom: '32px' }}>
    <label>{label}</label>
    {touched && error && <div style={{ ...cssStyles.errorLabel }}>{error}</div>}
    <div className="checkbox-list-container">
      {options.map((option, index) => (
        <div className="checkbox checkbox-list-item" key={index}>
          <Checkbox
            style={{ cursor: 'pointer', overflow: 'visible', width: 'auto' }}
            labelPosition="right"
            label={options.text}
            checked={input.value.indexOf(option.id) !== -1}
            onCheck={(event) => {
                const newValue = [...input.value];
                if (event.target.checked) {
                    newValue.push(option.id);
                } else {
                    newValue.splice(newValue.indexOf(option.id), 1);
                }
                return input.onChange(newValue);
            }}
          />
          {option.text}
        </div>
            ))}
    </div>
  </div>
);
export const RenderCheckbox = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
}) => {
    const value = inputProps.value || false;
    const { onChange } = inputProps;
    return (
      <div>
        <Checkbox
          {...inputProps}
          checked={value}
          onCheck={(event, isInputChecked) => {
              onChange(isInputChecked);
          }}
          onBlur={e => inputProps.onBlur(undefined)}
          {...props}
        />
        {touched && error && <label>{error}</label>}
      </div>
    );
};

export const RenderSelect = ({
    meta: { touched, error } = {},
    input: { value, onChange },
    ...props
}) => {
    const { disabled, allowEmpty = false } = props;

    const items = allowEmpty ? [{ id: null, value: '' }, ...props.items] : [...props.items];
    const MenuItems = items.map(item => (
      <MenuItem key={item.id} value={item.id} primaryText={item.text} />
    ));

    return (
      <div style={{width:  "100%" }}>
        <SelectField
          floatingLabelText={props.floatingLabelText}
          value={value}
          onChange={(event, index, val) => onChange(val)}
          disabled={disabled}
          errorText={touched && error}
          style={{ ...props.style }}
        >
          {MenuItems}
        </SelectField>
      </div>
    );
};

export const RenderDatePicker = ({
    meta: { touched, error } = {},
    input: { value, onChange },
    ...props
}) => (
  <div>
    <DatePicker
      value={value ? new Date(value) : null}
      {...props}
      autoOk
      errorText={touched && error}
      onChange={(event, date) => onChange(date)}
    />
  </div>
);

const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
    });

class EditorWrapper extends Component {
    constructor(props) {
        super(props);
        this.handleEditorStateChange = this.handleEditorStateChange.bind(this);
        const { value } = props;
        const editorState = value
            ? EditorState.createWithContent(convertFromRaw(value))
            : EditorState.createEmpty();

        this._uploadImageCallBack = this._uploadImageCallBack.bind(this);
        this.state = {
            editorState,
            uploadedImages: [],
        };
    }
    convertToString(editorState) {
        return convertToRaw(editorState.getCurrentContent());
        // return JSON.stringify(convertToRaw(editorState.getCurrentContent()));
    }
    handleEditorStateChange(editorState) {
        const { onChange } = this.props;
        this.setState({
            editorState,
        });
        const stringContent = this.convertToString(editorState);
        onChange(stringContent);
    }
    _uploadImageCallBack(file) {
        // // long story short, every time we upload an image, we
        // // need to save it to the state so we can get it's data
        // // later when we decide what to do with it.

        // // Make sure you have a uploadImages: [] as your default state
        // const uploadedImages = this.state.uploadedImages;

        // const imageObject = {
        //     file,
        //     localSrc: URL.createObjectURL(file),
        // };

        // uploadedImages.push(imageObject);

        // this.setState({ uploadedImages });

        // // We need to return a promise with the image src
        // // the img src we will use here will be what's needed
        // // to preview it in the browser. This will be different than what
        // // we will see in the index.md file we generate.
        // return new Promise((resolve, reject) => {
        //     resolve({ data: { link: imageObject.localSrc } });
        // });

        return convertFileToBase64(file).then(url => ({ data: { link: url } }));
    }
    render() {
        const { touched, errorText } = this.props;
        const { editorState } = this.state;
        const toolbar = {
            options: ['image'],
            image: {
                urlEnabled: false,
                uploadEnabled: true,
            },
        };
        return (
          <div>
            <Editor
              editorState={editorState}
              wrapperClassName="demo-wrapper"
              editorClassName="demo-editor"
              onEditorStateChange={this.handleEditorStateChange}
              toolbar={{
                  options: [
                      'inline',
                      'blockType',
                      'list',
                      'textAlign',
                      'colorPicker',
                      'link',
                      'embedded',
                      'image',
                      'remove',
                      'history',
                  ],
                  image: {
                      urlEnabled: true,
                      uploadEnabled: true,
                      uploadCallback: this._uploadImageCallBack,
                  },
              }}
            />
            {touched && errorText && <div className="errorLabel">{errorText}</div>}
          </div>
        );
    }
}
EditorWrapper.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export const RenderEditor = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
}) => (
  <div style={{ width: '100%', paddingTop: '32px' }}>
    <label>{props.label}</label>
    <EditorWrapper
      touched={touched}
      errorText={error}
      value={inputProps.value}
      onChange={inputProps.onChange}
    />
  </div>
);

export default renderField;
