import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import _ from 'lodash';
import { formatDate } from '../util/format';
import getAcceptRejectText from '../util/accept_reject';
import { search, searchInputChanged, searchClearResults } from '../actions';
import searchCss from '../searchbar.css';

function getHighlighted(field, query) {
    field = _.upperFirst(field);
    const matches = AutosuggestHighlightMatch(field, query);
    const parts = AutosuggestHighlightParse(field, matches);
    const highlighted = parts.map((part, index) => {
        const className = part.highlight ? 'highlight' : null;

        return (
          <span className={className} key={index}>
            {part.text}
          </span>
        );
    });
    return highlighted;
}
function renderQuote(record, query) {
    const nameHighLighted = getHighlighted(record.name, query);
    const descriptionHighLighted = getHighlighted(record.description, query);
    const notesHighLighted = getHighlighted(record.notes, query);
    return (
      <div className={'suggestion-content'}>
        <div className="suggestion-content-header">{nameHighLighted}</div>
        <div className="suggestion-1-col">{descriptionHighLighted}</div>
        <div className="suggestion-1-col">{notesHighLighted}</div>
      </div>
    );
}
function renderUser(user, query) {
    const firstNameHighLighted = getHighlighted(user.first_name, query);
    const lastNameHighLighted = getHighlighted(user.last_name, query);
    const emailHighLighted = getHighlighted(user.email, query);
    const titleHighLighted = getHighlighted(user.title, query);
    const cellHighLighted = getHighlighted(user.cell, query);

    return (
      <div className={'suggestion-content'}>
        <div className="suggestion-content-header">
          {firstNameHighLighted} {lastNameHighLighted}
        </div>
        <div className="suggestion-1-col">{titleHighLighted}</div>
        <div className="suggestion-1-col">
          {emailHighLighted} {cellHighLighted}
        </div>
      </div>
    );
}
function renderJob(job, query) {
    const jobNameHighLighted = getHighlighted(job.project_name, query);
    const legalNameHighLighted = getHighlighted(job.legal_name, query);
    const streetAddressHighLighted = getHighlighted(job.street_address, query);
    const cityHighLighted = getHighlighted(job.city, query);
    const zipHighLighted = getHighlighted(job.zip, query);

    return (
      <div className={'suggestion-content'}>
        <div className="suggestion-content-header">{jobNameHighLighted}</div>
        <div className="suggestion-1-col">{legalNameHighLighted}</div>
        <div className="suggestion-1-col">
          {streetAddressHighLighted}
          {job.street_address && job.city ? ',' : ''} {cityHighLighted} {zipHighLighted}
        </div>
      </div>
    );
}
function renderManagement(management, query) {
    const companyNameHighLighted = getHighlighted(management.company_name, query);
    const streetAddressHighLighted = getHighlighted(management.street_address, query);
    const cityHighLighted = getHighlighted(management.city, query);
    const zipHighLighted = getHighlighted(management.zip, query);

    return (
      <div className={'suggestion-content'}>
        <div className="suggestion-content-header">{companyNameHighLighted}</div>
        <div className="suggestion-1-col">
          {streetAddressHighLighted}
          {management.street_address && management.city ? ',' : ''} {cityHighLighted}{' '}
          {zipHighLighted}
        </div>
      </div>
    );
}
function renderManager(user, query) {
    const nameHighLighted = getHighlighted(user.name, query);
    const emailHighLighted = getHighlighted(user.email, query);
    const phoneHighLighted = getHighlighted(user.phone, query);
    return (
      <div className={'suggestion-content'}>
        <div className="suggestion-content-header">{nameHighLighted}</div>
        <div className="suggestion-1-col">{emailHighLighted}</div>
        {user.phone && (
        <div className="suggestion-1-col">
          {'Phone: '} {phoneHighLighted}
        </div>
            )}
      </div>
    );
}
function renderLaborRate(laborRate, query) {
    const nameHighLighted = getHighlighted(laborRate.name, query);
    return (
      <div className={'suggestion-content'}>
        <div className="suggestion-content-header">{nameHighLighted}</div>
      </div>
    );
}
function renderPricingGroup(pricingGroup, query) {
    const nameHighLighted = getHighlighted(pricingGroup.name, query);
    return (
      <div className={'suggestion-content'}>
        <div className="suggestion-content-header">{nameHighLighted}</div>
      </div>
    );
}

function renderContract(contract, query) {
    const nameHighLighted = getHighlighted(contract.name, query);
    const emailHighLighted = getHighlighted(contract.email, query);
    const companyNameHighLighted = getHighlighted(contract.company_name, query);
    const effectiveDate = contract.effective_date;
    return (
      <div className={'suggestion-content'}>
        <div className="suggestion-content-header">{nameHighLighted}</div>
        <div className="suggestion-1-col">{emailHighLighted}</div>
        {effectiveDate && (
        <div className="suggestion-1-col">
          {'Effective: '} {formatDate(effectiveDate)}
        </div>
            )}
        {contract.company_name && (
        <div className="suggestion-1-col">{companyNameHighLighted}</div>
            )}
      </div>
    );
}
function renderRaiseRequest(raiseRequest, query) {
    const nameHighLighted = getHighlighted(raiseRequest.name, query);
    const templateNameHighLighted = getHighlighted(raiseRequest.template_name, query);
    const acceptRejectText = getAcceptRejectText(raiseRequest.accept_rejected);

    return (
      <div className={'suggestion-content'}>
        <div className="suggestion-content-header">{nameHighLighted}</div>
        {raiseRequest.template_name && (
        <div className="suggestion-1-col">{templateNameHighLighted}</div>
            )}
        <div className="suggestion-1-col">{acceptRejectText}</div>
      </div>
    );
}

function renderSuggestion(suggestion, { query }) {
    const { resourcetype } = suggestion;

    if (resourcetype == 'user') {
        return renderUser(suggestion, query);
    } else if (resourcetype == 'manager') {
        return renderManager(suggestion, query);
    } else if (resourcetype == 'job') {
        return renderJob(suggestion, query);
    } else if (resourcetype == 'management') {
        return renderManagement(suggestion, query);
    } else if (resourcetype == 'labor_rate') {
        return renderLaborRate(suggestion, query);
    } else if (resourcetype == 'pricing_group') {
        return renderPricingGroup(suggestion, query);
    } else if (resourcetype == 'contract') {
        return renderContract(suggestion, query);
    } else if (resourcetype == 'raise_request') {
        return renderRaiseRequest(suggestion, query);
    } else if (resourcetype == 'quote') {
        return renderQuote(suggestion, query);
    }

    const suggestionText = `${suggestion.firstName}`;
    const matches = AutosuggestHighlightMatch(suggestionText, query);
    const parts = AutosuggestHighlightParse(suggestionText, matches);

    return (
      <span className={`suggestion-content ${suggestion.twitter}`}>
        <span className="name">
          {parts.map((part, index) => {
              const className = part.highlight ? 'highlight' : null;

              return (
                <span className={className} key={index}>
                  {part.text}
                </span>
              );
          })}
        </span>
      </span>
    );
}

function renderSectionTitle(section) {
    return <strong>{_.startCase(section.title)}</strong>;
}

function getSectionSuggestions(section) {
    return section.suggestions;
}

class SearchBar extends React.Component {
    constructor() {
        super();

        this.state = {
            value: '',
            suggestions: [],
        };
        this.onChange = this.onChange.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    }

    onChange(event, { newValue, method }) {
        this.props.actions.searchInputChanged(newValue);
    }
    onSuggestionSelected(event, { suggestion }) {
        const { resourcetype, id } = suggestion;
        let route = '';
        switch (resourcetype) {
        case 'user':
            route = 'user';
            break;
        case 'job':
            route = 'jobsetup';
            break;
        case 'management':
            route = 'management';
            break;
        case 'manager':
            route = 'manager';
            break;
        case 'labor_rate':
            route = 'laborrate';
            break;
        case 'pricing_group':
            route = 'pricinggroup';
            break;
        case 'contract':
            route = 'contract';
            break;
        case 'raise_request':
            route = 'raiserequest';
            break;
        default:
            route = '';
        }

        if (resourcetype === 'quote') {
            window.location.href = `https://quotes.oclm.com/dashboard/quotes/view/${id}`;
        } else {
            this.props.actions.push(`/${route}/${id}/show`);
        }
    }
    onSuggestionsFetchRequested({ value }) {
        this.props.actions.search(value);
    }

    onSuggestionsClearRequested() {
        this.props.actions.searchClearResults();
    }
    onShouldRenderSuggestions(value) {
        return value.length > 2;
    }
    render() {
        const { suggestions, inputValue } = this.props;
        const inputProps = {
            placeholder: 'Search',
            value: inputValue,
            onChange: this.onChange,
        };

        return (
          <Autosuggest
            multiSection
            alwaysRenderSuggestions={false}
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            shouldRenderSuggestions={this.onShouldRenderSuggestions}
            getSuggestionValue={() => inputValue}
            renderSuggestion={renderSuggestion}
            renderSectionTitle={renderSectionTitle}
            onSuggestionSelected={this.onSuggestionSelected}
            getSectionSuggestions={getSectionSuggestions}
            inputProps={inputProps}
          />
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            { search, push, searchInputChanged, searchClearResults },
            dispatch,
        ),
    };
}
function mapStateToProps(state) {
    const { search } = state;
    return {
        inputValue: search.inputValue,
        suggestions: search.results,
    };
}

const ConnnectedComponent = connect(mapStateToProps, mapDispatchToProps)(SearchBar);
export default ConnnectedComponent;
