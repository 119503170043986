import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Show } from 'admin-on-rest';
import { Toolbar, ToolbarGroup } from 'material-ui/Toolbar';
import RaisedButton from 'material-ui/RaisedButton';
import { required, number } from '../util/validate';
import RenderField, {
    RenderNumberInput,
    RenderDatePicker,
    RenderCurrency,
} from '../shared/render_field';
import { Field, reduxForm } from 'redux-form';
import Divider from 'material-ui/Divider';
import Subheader from 'material-ui/Subheader';
import styles from '../styles';

const formName = 'record-form';
class Contact extends Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this);
    }
    onSave(formData) {
        this.props.save(formData, 'list');
    }
    render() {
        const { save, loading, handleSubmit } = this.props;
        return (
          <form className="simple-form" onSubmit={handleSubmit(this.onSave)}>
            <div className="field">
              <div>
                <Subheader style={{ ...styles.subheader }}>Basic Info</Subheader>
                <Divider />
                <div style={{ ...styles.row }}>
                  <Field
                    name="name"
                    type="text"
                    disabled
                    component={RenderField}
                    validate={[required]}
                    floatingLabelText="Name"
                    style={{ ...styles.row.field }}
                  />
                </div>
                <div style={{ ...styles.row }}>
                  <Field
                    name="valid_start_date"
                    component={RenderDatePicker}
                    disabled
                    props={{ container: 'inline' }}
                    validate={[required]}
                    locale="en-US"
                    floatingLabelText="Valid start date"
                    style={{ ...styles.row.field }}
                  />
                  <Field
                    name="duration_in_days"
                    component={RenderNumberInput}
                    disabled
                    validate={[required, number]}
                    floatingLabelText="Duration in days"
                    style={{ ...styles.row.field }}
                  />
                </div>
                <Subheader style={{ ...styles.subheader }}>Rates</Subheader>
                <Divider />
                <div style={{ ...styles.row }}>
                  <Field
                    name="landscape_maint_extra_labor_rate"
                    floatingLabelText="Landscape maint extra labor rate"
                    disabled
                    component={RenderCurrency}
                    validate={[required, number]}
                    style={{ ...styles.row.field }}
                  />
                  <Field
                    name="landscape_maint_foreman_rate"
                    floatingLabelText="Landscape maint foreman rate"
                    disabled
                    component={RenderCurrency}
                    validate={[required, number]}
                    style={{ ...styles.row.field }}
                  />
                </div>
                <div style={{ ...styles.row }}>
                  <Field
                    name="landscape_maint_manager_rate"
                    floatingLabelText="Landscape maint manager rate"
                    disabled
                    component={RenderCurrency}
                    validate={[required, number]}
                    style={{ ...styles.row.field }}
                  />
                  <Field
                    name="irrigation_technician_rate"
                    floatingLabelText="Irrigation technician rate"
                    disabled
                    component={RenderCurrency}
                    validate={[required, number]}
                    style={{ ...styles.row.field }}
                  />
                </div>
              </div>
            </div>
            <Toolbar />
          </form>
        );
    }
}

let CreateReduxForm = reduxForm({
    form: formName, // <------ same form name
    destroyOnUnmount: true, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(Contact);

function mapStateToProps(state, thisprops) {
    return {
        save: thisprops.save,
        loading: state.admin.loading,
        initialValues: thisprops.record,
    };
}

function mapDispatchToProps(dispatch) {
    return {};
}

CreateReduxForm = connect(mapStateToProps, mapDispatchToProps)(CreateReduxForm);

class EnhancedContainer extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { save } = this.props;
        return (
          <div>
            <CreateReduxForm save={save} />
          </div>
        );
    }
}

class ShowComponent extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
          <Show title="Labor rate" {...this.props}>
            <EnhancedContainer />
          </Show>
        );
    }
}
export default ShowComponent;
