import { GET_ONE, UPDATE } from 'admin-on-rest';
import client, { baseUrl } from '../client';
import axios from 'axios';

export const enableUser = function ({ id, enabled }) {
    return client(UPDATE, 'user', { id, data: { enabled } });
};
export const fetchToken = function (token) {
    return client(GET_ONE, 'activate', { id: token });
};

export const resetPassword = function (password) {
    const token = localStorage.getItem('token');
    return fetch(`${baseUrl}/account/passwordreset`, {
        method: 'post',
        headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ password }),
    }).then((response) => {
        if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
        }
        return response.json();
    });
};

export const forgotPassword = function (email) {
    return fetch(`${baseUrl}/password/forgot`, {
        method: 'post',
        headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
    }).then((response) => {
        if (response.status < 200 || response.status >= 300) {
            return response.json().then((data) => {
                throw new Error(data.message);
            });
        }
        return response.json();
    });
};
