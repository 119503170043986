const styles = {
    columnDivide: {
        borderRight: 'solid 1px lightgrey',
        marginRight: '25px',
    },
    cardAction: {
        zIndex: 2,
        display: 'inline-block',
        float: 'right',
    },
    cardActionItem: {
        marginRight: '10px',
    },
    rowContainer: {
        marginTop: '20px',
    },
    exhibitImageContainer: {
        padding: '10px',
    },
    exhibitImage: {
        width: '100px',
        height: '100px',
    },
    subheader: {
        marginTop: '20px',
        fontWeight: 'bold',
    },
    row: {
        display: 'flex',
        field: {
            marginRight: '32px',
        },
    },
    tab: {
        button: {
            backgroundColor: 'white',
            color: '#757575',
        },
        selectedButton: {
            backgroundColor: 'white',
            color: '#c82222',
        },
        buttonWithErrors: {
            backgroundColor: 'white',
            color: '#757575',
        },
        buttonWithErrorsInnerDiv: {
            height: '48px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        inkBarStyle: {
            backgroundColor: '#c82222',
        },
    },
};
export default styles;
