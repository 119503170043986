import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Field,
    reduxForm,
    hasSubmitFailed,
    getFormSyncErrors,
    getFormValues,
    change,
    initialize,
} from 'redux-form';
import { bindActionCreators } from 'redux';
import { Toolbar, ToolbarGroup } from 'material-ui/Toolbar';
import RenderField, {
    RenderSelect,
    RenderImageInput,
    RenderDatePicker,
    RenderEditor,
    RenderCurrency,
} from '../shared/render_field';
import { required } from '../util/validate';
import { GetListForSelect } from '../util/parse';
import { fetchContractTemplates, fetchJobSetups, fetchManagement, fetchManager } from '../actions';
import { Create } from 'admin-on-rest';
import SaveButton from '../components/savebutton';
import RaisedButton from 'material-ui/RaisedButton';
import moment from 'moment';
import { denormalize } from '../util/normalizr';
import styles from '../styles';
import '../styles/form.css';

const formName = 'record-form';
class Contact extends Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this);
        this.onJobChange = this.onJobChange.bind(this);
    }
    componentDidMount() {
        this.props.actions.fetchContractTemplates({
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'name', order: 'ASC' },
            filter: {},
        });
        this.props.actions.fetchJobSetups({
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'project_name', order: 'ASC' },
            filter: {},
        });
        this.props.actions.fetchManagement({
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'company_name', order: 'ASC' },
            filter: {},
        });
        this.props.actions.fetchManager({
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'name', order: 'ASC' },
            filter: {},
        });
    }
    onSave(formData) {
        this.props.save(formData, 'show');
    }
    onJobChange(event, newValue) {
        const job_setup_id = newValue;
        const { change, initialize } = this.props.actions;
        const { jobSetups } = this.props;
        const jobSetupKeys = Object.keys(jobSetups);
        const jobSetupFiltered = jobSetupKeys.filter(key => key == job_setup_id);

        let termEndDate = moment();
        let termStartDate = moment();
        if (jobSetupFiltered && jobSetupFiltered.length > 0) {
            const jobSetupKey = jobSetupFiltered[0];
            const jobSetup = jobSetups[jobSetupKey];
            if (jobSetup.management_id) {
                change(formName, 'management_id', jobSetup.management_id);
            } else {
                change(formName, 'management_id', null);
            }
            if (jobSetup.manager_id) {
                change(formName, 'manager_id', jobSetup.manager_id);
            } else {
                change(formName, 'manager_id', null);
            }
            initialize(formName, { monthly_price: jobSetup.total_price }, true);
            termStartDate = moment(jobSetup.start_date);
            termEndDate = termStartDate
                .clone()
                .add(1, 'y')
                .subtract(1, 'd');
        }

        change(formName, 'term_start_date', termStartDate.format('M/D/YYYY'));
        change(formName, 'term_end_date', termEndDate.toDate());
    }
    render() {
        const {
            save,
            handleSubmit,
            submitFailed,
            errors,
            submitting,
            jobSetups,
            management,
            manager,
            contractTemplates,
            formValues,
            loading,
        } = this.props;
        const propsForContractTemplateSelect = GetListForSelect(
            contractTemplates,
            'Contract Template',
            'id',
            'name',
        );
        const propsForJobSetupSelect = GetListForSelect(jobSetups, 'Job', 'id', 'project_name');
        const propsForMangementSelect = GetListForSelect(
            management,
            'Management',
            'id',
            'company_name',
        );
        let managers = [];
        let managementSelectDisabled = false;
        let managerSelectDisabled = false;
        if (formValues && formValues.management_id) {
            managers = manager.filter(x => x.management_company_id == formValues.management_id);
        }
        const propsForManagersSelect = GetListForSelect(managers, 'Manager', 'id', 'name');

        if (formValues && formValues.job_setup_id) {
            const { job_setup_id } = formValues;
            const jobSetupKeys = Object.keys(jobSetups);
            const jobSetupFiltered = jobSetupKeys.filter(key => key == job_setup_id);

            if (jobSetupFiltered && jobSetupFiltered.length > 0) {
                const jobSetupKey = jobSetupFiltered[0];
                const jobSetup = jobSetups[jobSetupKey];
                if (jobSetup.management_id) {
                    managementSelectDisabled = true;
                }

                if (jobSetup.manager_id) {
                    managerSelectDisabled = true;
                }
            }
        }

        return (
          <form className="simple-form" onSubmit={handleSubmit(this.onSave)}>
            <div className="field">
              <div style={{ ...styles.row }}>
                <Field
                  name="name"
                  type="text"
                  component={RenderField}
                  validate={[required]}
                  floatingLabelText="Name"
                  style={{ ...styles.row.field }}
                />
              </div>
              <div style={{ ...styles.row }}>
                <Field
                  name="contract_template_id"
                  props={propsForContractTemplateSelect}
                  component={RenderSelect}
                  validate={[required]}
                  style={{ ...styles.row.field }}
                />
              </div>
              <div style={{ ...styles.row }}>
                <Field
                  name="job_setup_id"
                  props={propsForJobSetupSelect}
                  onChange={this.onJobChange}
                  component={RenderSelect}
                  validate={[required]}
                  style={{ ...styles.row.field }}
                />
                {propsForMangementSelect.items.length > 0 && (
                <Field
                  name="management_id"
                  props={propsForMangementSelect}
                  component={RenderSelect}
                  disabled={managementSelectDisabled}
                  style={{ ...styles.row.field }}
                />
                        )}
                {propsForMangementSelect.items.length === 0 && (
                <div>
                  <RaisedButton
                    label="Create Management company"
                    disableTouchRipple
                    disableFocusRipple
                    primary
                    target="_blank"
                    href="#/management/create"
                  />
                </div>
                        )}
                {propsForManagersSelect.items.length > 0 && (
                <Field
                  name="manager_id"
                  props={propsForManagersSelect}
                  component={RenderSelect}
                  disabled={managerSelectDisabled}
                  style={{ ...styles.row.field }}
                />
                        )}
                {manager.length === 0 &&
                            propsForManagersSelect.items.length === 0 && (
                            <div>
                              <RaisedButton
                                label="Create Manager"
                                disableTouchRipple
                                disableFocusRipple
                                primary
                                target="_blank"
                                href="#/manager/create"
                              />
                            </div>
                            )}
              </div>
              <div style={{ ...styles.row }}>
                <Field
                  name="term_start_date"
                  type="text"
                  component={RenderDatePicker}
                  floatingLabelText="Term Start"
                  style={{ ...styles.row.field }}
                />
                <Field
                  name="term_end_date"
                  type="text"
                  component={RenderDatePicker}
                  validate={[required]}
                  locale="en-US"
                  floatingLabelText="Term End"
                  style={{ ...styles.row.field }}
                />
              </div>
              <div style={{ ...styles.row }}>
                <Field
                  name="monthly_price"
                  label="Monthly Price"
                  floatingLabelText="Monthly Price"
                  component={RenderCurrency}
                  validate={[required]}
                  style={{ ...styles.row.field }}
                />
              </div>
              <div style={{ ...styles.row }}>
                <Field
                  name="exhibita"
                  label="Exhibit A"
                  floatingLabelText="Exhibit A"
                  component={RenderImageInput}
                  style={{ width: '100%' }}
                />
              </div>
              <div style={{ ...styles.row }}>
                <Field
                  name="exhibit_b"
                  label="Exhibit B"
                  component={RenderEditor}
                  style={{ width: '100%' }}
                />
              </div>
              <div style={{ ...styles.row }}>
                <Field
                  name="exhibitc"
                  label="Exhibit C"
                  floatingLabelText="Exhibit C"
                  component={RenderImageInput}
                />
              </div>
            </div>
            <Toolbar>
              <ToolbarGroup lastChild>
                <SaveButton label="Save" loading={loading} />
              </ToolbarGroup>
            </Toolbar>
          </form>
        );
    }
}
let ContactReduxForm = reduxForm({
    form: formName, // <------ same form name
    destroyOnUnmount: true, // <------ preserve form data
    forceUnregisterOnUnmount: false, // <------ unregister fields on unmount,
})(Contact);

function mapStateToProps(state, thisprops) {
    const syncErrors = getFormSyncErrors(formName)(state);
    const submitFailed = hasSubmitFailed(formName)(state);
    const formValues = getFormValues(formName)(state);

    return {
        errors: syncErrors,
        submitFailed,
        formValues,
        save: thisprops.save,
        jobSetups: denormalize(
            state.admin.resources.jobsetup.data,
            state.admin.resources.jobsetup.list.ids,
        ),
        contractTemplates: denormalize(
            state.admin.resources.contracttemplate.data,
            state.admin.resources.contracttemplate.list.ids,
        ),
        management: denormalize(
            state.admin.resources.management.data,
            state.admin.resources.management.list.ids,
        ),
        manager: denormalize(state.admin.resources.manager.data, state.admin.resources.manager.list.ids),
        loading: state.admin.loading,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                fetchContractTemplates,
                fetchJobSetups,
                change,
                initialize,
                fetchManagement,
                fetchManager,
            },
            dispatch,
        ),
    };
}

ContactReduxForm = connect(mapStateToProps, mapDispatchToProps)(ContactReduxForm);

class EnhancedContainer extends Component {
    render() {
        const { save } = this.props;
        return (
          <div>
            <ContactReduxForm save={save} />
          </div>
        );
    }
}

class CreateForm extends Component {

    render() {
        return (
          <Create title="Create contract" {...this.props}>
            <EnhancedContainer />
          </Create>
        );
    }
}
export default CreateForm;
