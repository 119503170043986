import React from 'react';

const TokensList = () => (
  <ul>
    <li>{'{{date}}'}</li>
    <li>{'{{increase}}'}</li>
    <li>{'{{increase_percent}}'}</li>
    <li>{'{{manager}}'}</li>
    <li>{'{{management_company}}'}</li>
    <li>{'{{management_company_address}}'}</li>
    <li>{'{{property_name}}'}</li>
    <li>{'{{current_monthly_contract_amount}}'}</li>
    <li>{'{{new_monthly_contract_amount}}'}</li>
    <li>{'{{pricing_group}}'}</li>
    <li>{'{{signature}}'}</li>
    <li>{'{{project_manager_name}}'}</li>
    <li>{'{{title}}'}</li>
  </ul>
);

export default TokensList;
