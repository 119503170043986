import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { RenderSelect } from '../../shared/render_field';
import moment from 'moment';

const LaborRateFormFields = {
    labor_rate_id: 'labor_rate_id',
};
const LaborRateForm = (props) => {
    const { disabled, laborRates } = props;

    const keys = Object.keys(laborRates);
    const laborrateItems = keys.map((key) => {
        const item = laborRates[key];
        const validStartDate = moment(item.valid_start_date).format('M/D/YYYY');
        return {
            id: item.id,
            text: `${item.name} (Valid as of ${validStartDate})`,
        };
    });
    const propsForlaborrateSelect = {
        floatingLabelText: 'Labor Rate',
        items: laborrateItems,
    };
    return (
      <Field
        name={LaborRateFormFields.labor_rate_id}
        props={propsForlaborrateSelect}
        disabled={disabled}
        component={RenderSelect}
      />
    );
};
LaborRateForm.defaultProps = {
    disabled: false,
};
LaborRateForm.propTypes = {
    disabled: PropTypes.bool,
    laborRates: PropTypes.array.isRequired,
};
export default LaborRateForm;
export { LaborRateFormFields };
