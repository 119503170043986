import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Create } from 'admin-on-rest';
import { connect } from 'react-redux';
import { reduxForm, hasSubmitFailed, getFormSyncErrors, change, getFormValues } from 'redux-form';
import { Toolbar, ToolbarGroup } from 'material-ui/Toolbar';
import { bindActionCreators } from 'redux';
import SaveButton from '../../components/savebutton';
import RenderCard from '../shared/Card';
import { denormalize } from '../../util/normalizr';
import initialValues from './testData';

import {
    createJobSetup,
    fetchProjectTypes,
    fetchYards,
    fetchPricingGroups,
    fetchLaborRate,
    fetchUsers,
    fetchDeductionType,
    fetchSpecificationType,
    fetchManagement,
    fetchManager,
} from '../../actions';

import ProjectInfoForm, { ProjectInfoFormFields } from '../shared/project_info';
import ContractSummary, { ContractSummaryFormFields } from '../shared/contract_summary';
import AdditionalNotesForm, { AdditionalNotesFormFields } from '../shared/additional_notes';
import ContractDetailsForm, { ContractDetailsFormFields } from '../shared/contract_details';
import IrrigationForm, { IrrigationFormFields } from '../shared/irrigation';
import LaborRateForm, { LaborRateFormFields } from '../shared/labor_rate';

import ManagementInfoForm, { ManagementInfoFormFields } from '../shared/management_info';

import ItemsToBeCompletedForm, {
    ItemsToBeCompletedFormFields,
} from '../shared/items_to_be_completed';

import PricingGroupForm, { PricingGroupFormFields } from '../shared/pricing_group';
import SpecificationDetailsForm, {
    SpecificationDetailsFormFields,
} from '../shared/specification_details';

class JobSetupCreateForm extends Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this);
        this.getManagers = this.getManagers.bind(this);
    }
    componentDidMount() {
        this.props.actions.fetchProjectTypes({
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'project_type', order: 'ASC' },
            filter: {},
        });
        this.props.actions.fetchYards({
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'name', order: 'ASC' },
            filter: {},
        });
        this.props.actions.fetchPricingGroups({
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'name', order: 'ASC' },
            filter: {},
        });
        this.props.actions.fetchLaborRate({
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'name', order: 'ASC' },
            filter: {},
        });
        this.props.actions.fetchUsers({
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'firstName', order: 'ASC' },
            filter: {},
        });
        this.props.actions.fetchDeductionType({
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'name', order: 'ASC' },
            filter: {},
        });
        this.props.actions.fetchSpecificationType({
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'name', order: 'ASC' },
            filter: {},
        });
        this.props.actions.fetchManagement({
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'company_name', order: 'ASC' },
            filter: {},
        });
    }
    onSave(formData) {
        this.props.save(formData, 'list');
    }
    getManagers(event, newValue, oldValue) {
        let managerId = newValue;
        const { change } = this.props.actions;
        if (!newValue) {
            managerId = -1;
        }
        change(formName, 'manager_id', null);
        this.props.actions.fetchManager({
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'name', order: 'ASC' },
            filter: { management_company_id: managerId },
        });
    }

    render() {
        const {
            loading,
            save,
            handleSubmit,
            submitFailed,
            errors,
            submitting,
            laborRates,
            projectTypes,
            pricingGroups,
            users,
            specificationTypes,
            yard,
            deductionTypes,
            management,
            manager,
            formValues = {},
        } = this.props;
        const {
            total_price = 0,
            original_price = 0,
            deduction_1 = 0,
            deduction_2 = 0,
            deduction_3 = 0,
            deduction_4 = 0,
            deduction_5 = 0,
            detail_hours = 0,
            mowing_hours = 0,
            irrigation_hours = 0,
        } = formValues;
        return (
          <form className="simple-form" onSubmit={handleSubmit(this.onSave)}>
            <RenderCard
              title="Project Info"
              submitFailed={submitFailed}
              fields={ProjectInfoFormFields}
              initiallyExpanded
              errors={errors}
            >
              <ProjectInfoForm projectTypes={projectTypes} users={users} yard={yard} />
            </RenderCard>
            <RenderCard
              title="Contract Summary"
              submitFailed={submitFailed}
              fields={ContractSummaryFormFields}
              errors={errors}
            >
              <ContractSummary
                deductionTypes={deductionTypes}
                original_price={original_price}
                deduction_1={deduction_1}
                deduction_2={deduction_2}
                deduction_3={deduction_3}
                deduction_4={deduction_4}
                deduction_5={deduction_5}
              />
            </RenderCard>
            <RenderCard
              title="Additional Notes"
              submitFailed={submitFailed}
              fields={AdditionalNotesFormFields}
              errors={errors}
            >
              <AdditionalNotesForm />
            </RenderCard>
            <RenderCard
              title="Management"
              submitFailed={submitFailed}
              fields={ManagementInfoFormFields}
              errors={errors}
            >
              <ManagementInfoForm
                management={management}
                managers={manager}
                onChangeManagement={this.getManagers}
              />
            </RenderCard>

            <RenderCard
              title="Irrigation"
              submitFailed={submitFailed}
              fields={IrrigationFormFields}
              errors={errors}
            >
              <IrrigationForm />
            </RenderCard>
            <RenderCard
              title="Items to be completed"
              submitFailed={submitFailed}
              fields={ItemsToBeCompletedFormFields}
              errors={errors}
            >
              <ItemsToBeCompletedForm users={users} />
            </RenderCard>
            <RenderCard
              title="Contract Details"
              submitFailed={submitFailed}
              fields={ContractDetailsFormFields}
              errors={errors}
            >
              <ContractDetailsForm
                detail_hours={detail_hours}
                mowing_hours={mowing_hours}
                irrigation_hours={irrigation_hours}
              />
            </RenderCard>
            <RenderCard
              title="Specification Details"
              submitFailed={submitFailed}
              fields={SpecificationDetailsFormFields}
              errors={errors}
            >
              <SpecificationDetailsForm specificationTypes={specificationTypes} />
            </RenderCard>
            <RenderCard
              title="Pricing Group"
              submitFailed={submitFailed}
              fields={PricingGroupFormFields}
              errors={errors}
            >
              <PricingGroupForm pricingGroups={pricingGroups} />
            </RenderCard>
            <RenderCard
              title="Labor Rate"
              submitFailed={submitFailed}
              fields={LaborRateFormFields}
              errors={errors}
            >
              <LaborRateForm laborRates={laborRates} />
            </RenderCard>
            <Toolbar>
              <ToolbarGroup lastChild>
                <SaveButton label="Save" loading={loading} />
              </ToolbarGroup>
            </Toolbar>
          </form>
        );
    }
}
const formName = 'record-form';

function mapStateToProps(state, thisprops) {
    const syncErrors = getFormSyncErrors(formName)(state);
    const submitFailed = hasSubmitFailed(formName)(state);
    const formValues = getFormValues(formName)(state);
    return {
        errors: syncErrors,
        submitFailed,
        formValues,
        loading: state.admin.loading,
        save: thisprops.save,
        projectTypes: denormalize(
            state.admin.resources.projecttype.data,
            state.admin.resources.projecttype.list.ids,
        ),
        users: denormalize(state.admin.resources.user.data, state.admin.resources.user.list.ids),
        yard: denormalize(state.admin.resources.yard.data, state.admin.resources.yard.list.ids),
        laborRates: denormalize(
            state.admin.resources.laborrate.data,
            state.admin.resources.laborrate.list.ids,
        ),
        pricingGroups: denormalize(
            state.admin.resources.pricinggroup.data,
            state.admin.resources.pricinggroup.list.ids,
        ),
        deductionTypes: denormalize(
            state.admin.resources.deductiontype.data,
            state.admin.resources.deductiontype.list.ids,
        ),
        specificationTypes: denormalize(
            state.admin.resources.specificationtype.data,
            state.admin.resources.specificationtype.list.ids,
        ),
        management: denormalize(
            state.admin.resources.management.data,
            state.admin.resources.management.list.ids,
        ),
        manager: denormalize(
            state.admin.resources.manager.data,
            state.admin.resources.manager.list.ids,
        ),
        // initialValues,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                createJobSetup,
                fetchProjectTypes,
                fetchYards,
                fetchPricingGroups,
                fetchLaborRate,
                fetchUsers,
                fetchDeductionType,
                fetchSpecificationType,
                fetchManagement,
                fetchManager,
                change,
            },
            dispatch,
        ),
    };
}

let JobReduxForm = reduxForm({
    form: formName, // <------ same form name
    destroyOnUnmount: true, // <------ preserve form data
    forceUnregisterOnUnmount: false, // <------ unregister fields on unmount
})(JobSetupCreateForm);

JobReduxForm = connect(mapStateToProps, mapDispatchToProps)(JobReduxForm);

class EnhancedContainer extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { save } = this.props;
        return (
          <div>
            <JobReduxForm save={save} />
          </div>
        );
    }
}

class CreateForm extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
          <Create title="Create job" {...this.props}>
            <EnhancedContainer />
          </Create>
        );
    }
}
export default CreateForm;
