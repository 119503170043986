import {
    LOGGED_IN,
    LOGGED_OUT,
    PASSWORD_FORGOT_STARTED,
    PASSWORD_FORGOT_FINISHED,
    PASSWORD_RESET_STARTED,
    PASSWORD_RESET_FINISHED
} from '../actions/';
import { AuthCheck } from '../auth';

const initialState = {
    loggedIn: AuthCheck(),
    loginLoading: false,
    passwordResetLoading: false,
    passwordForgotLoading: false,
};

export const login = (previousState = initialState, { type, payload }) => {
    if (type === LOGGED_IN) {
        return Object.assign({}, previousState, { loggedIn: true });
        // return {loggedIn: true};
    } else if (type === LOGGED_OUT) {
        return Object.assign({}, previousState, { loggedIn: false });
    } else if (type === 'AOR/USER_LOGIN_LOADING') {
        return Object.assign({}, previousState, { loginLoading: true });
    } else if (type === 'AOR/USER_LOGIN_SUCCESS') {
        return Object.assign({}, previousState, { loginLoading: false });
    } else if (type === 'AOR/USER_LOGIN_FAILURE') {
        return Object.assign({}, previousState, { loginLoading: false });
    } else if (type === PASSWORD_FORGOT_STARTED) {
        return Object.assign({}, previousState, { passwordForgotLoading: true });
    } else if (type === PASSWORD_FORGOT_FINISHED) {
        return Object.assign({}, previousState, { passwordForgotLoading: false });
    } else if (type === PASSWORD_RESET_STARTED) {
        return Object.assign({}, previousState, { passwordResetLoading: true });
    } else if (type === PASSWORD_RESET_FINISHED) {
        return Object.assign({}, previousState, { passwordResetLoading: false });
    }
    return previousState;
};

