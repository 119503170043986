import React, { Component, PropTypes } from 'react';
import { propTypes, reduxForm, Field, startSubmit } from 'redux-form';
import { connect } from 'react-redux';
import { push as pushAction } from 'react-router-redux';
import compose from 'recompose/compose';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { Card, CardActions } from 'material-ui/Card';
import Avatar from 'material-ui/Avatar';
import RaisedButton from 'material-ui/RaisedButton';
import Snackbar from 'material-ui/Snackbar';
import TextField from 'material-ui/TextField';

import { FlatButton } from 'material-ui';
import CircularProgress from 'material-ui/CircularProgress';
import LockIcon from 'material-ui/svg-icons/action/lock-outline';
import RegisterIcon from 'material-ui/svg-icons/social/people';

import { cyan500, pinkA200 } from 'material-ui/styles/colors';

import defaultTheme from 'admin-on-rest/lib/mui/defaultTheme';
import { userLogin, Notification } from 'admin-on-rest';

const styles = {
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f8fafb',
    },
    card: {
        minWidth: 300,
        width: '300px',
        padding: '25px',
    },
    avatar: {
        margin: '1em',
        textAlign: 'center ',
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        display: 'flex',
    },
};

function getColorsFromTheme(theme) {
    if (!theme) return { primary1Color: cyan500, accent1Color: pinkA200 };
    const { palette: { primary1Color, accent1Color } } = theme;
    return { primary1Color, accent1Color };
}

const renderInput = ({ meta: { touched, error } = {}, input: { ...inputProps }, ...props }) => (
  <TextField errorText={touched && error} {...inputProps} {...props} fullWidth />
);

const translate = function (key) {
    return key;
};

class BtcLoginPage extends Component {
    constructor(props) {
        super(props);
        this.handlePasswordForgotLink = this.handlePasswordForgotLink.bind(this);
        this.login = this.login.bind(this);
    }

    login({ username, password }) {
        const { userLogin } = this.props;
        userLogin({ username, password });
        // return false;
    }

    handlePasswordForgotLink() {
        this.props.push('/passwordforgot');
    }
    render() {
        const { handleSubmit, submitting, theme, loginLoading, signInError } = this.props;
        const muiTheme = getMuiTheme(theme);
        const { primary1Color, accent1Color } = getColorsFromTheme(muiTheme);

        return (
          <MuiThemeProvider muiTheme={muiTheme}>
            <div style={{ ...styles.main }}>
              <Card style={styles.card}>
                <div style={styles.avatar}>
                  <h2>Portal Login</h2>
                </div>
                {signInError && (
                <Snackbar
                  open
                  autoHideDuration={4000}
                  message={signInError.message || signInError}
                />
                        )}

                <form onSubmit={handleSubmit(this.login)}>
                  <div>
                    <div>
                      <Field
                        name="username"
                        component={renderInput}
                        floatingLabelText={'Email'}
                        disabled={loginLoading}
                      />
                    </div>
                    <div>
                      <Field
                        name="password"
                        component={renderInput}
                        floatingLabelText={'Password'}
                        type="password"
                        disabled={loginLoading}
                      />
                    </div>
                  </div>
                  <CardActions>
                    <RaisedButton
                      type="submit"
                      primary
                      disabled={loginLoading}
                      icon={
                                        loginLoading && <CircularProgress size={25} thickness={2} />
                                    }
                      label={'Login'}
                      fullWidth
                    />
                    <FlatButton
                      label="Forgot password?"
                      hoverColor="transparent"
                      onClick={this.handlePasswordForgotLink}
                      secondary
                    />
                  </CardActions>
                </form>
              </Card>
              <Notification />
            </div>
          </MuiThemeProvider>
        );
    }
}

BtcLoginPage.defaultProps = {
    theme: defaultTheme,
};

const enhance = compose(
    translate,
    reduxForm({
        form: 'signIn',
        validate: (values, props) => {
            const errors = {};

            if (!values.username) errors.username = 'Email Required';
            if (!values.password) errors.password = 'Password Required';

            return errors;
        },
    }),
    connect(mapStateToProps, { push: pushAction, userLogin, startSubmit }),
);

function mapStateToProps(state) {
    return {
        loginLoading: state.login.loginLoading,
        signInError: false,
    };
}
// loginLoading
export default enhance(BtcLoginPage);
