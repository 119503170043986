import { GET_LIST, CRUD_GET_LIST } from 'admin-on-rest';

export const FETCH_MANAGER_SUCCESSFUL = 'FETCH_MANAGER_SUCCESSFUL';
export const fetchManagerSuccessful = data => ({
    type: FETCH_MANAGER_SUCCESSFUL,
    payload: { ...data },
});
export const fetchManager = data => ({
    type: CRUD_GET_LIST,
    payload: { ...data },
    meta: { resource: 'manager', fetch: GET_LIST, cancelPrevious: false },
});
