import React, { useState, useEffect } from 'react';
import fetch from 'cross-fetch';
import { baseUrl } from '../../client';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import useDebounce from './useDebounce';

export default function SearchUsers({ onChange }) {
  const [textValue, setTextValue] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;

  function handleChange(e) {
    setTextValue(e.target.value);
  }

  const debouncedSearchTerm = useDebounce(textValue, 500);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsSearching(true);

      const token = localStorage.getItem('token');
      const filterData = JSON.stringify({
        firstName: textValue,
      });
      const url = `${baseUrl}/user?filter=${encodeURIComponent(filterData)}`;

      async function fetchData() {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });
        const users = await response.json();
        console.log('users', users);
        setIsSearching(false);
        setOptions(users);
      }

      fetchData();
    } else {
      setOptions([]);
    }
  }, [debouncedSearchTerm]);

  return (
    <Autocomplete
      id="search-users"
      style={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      // getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={option => option.fullName}
      options={options}
      loading={loading}
      inputValue={textValue}
      onChange={onChange}
      renderInput={params => (
        <TextField
          {...params}
          label="OCLM User Name"
          variant="outlined"
          onChange={handleChange}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
