import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import RenderField, { RenderSelect, RenderCheckbox } from '../../shared/render_field';
import styles from '../../styles';

const IrrigationFormFields = {
    controller_type: 'controller_type',
    number_of_stations: 'number_of_stations',
    number_of_controllers: 'number_of_controllers',
    water_district_name: 'water_district_name',
    copies_of_water_bills: 'copies_of_water_bills',
};

const IrrigationForm = (props) => {
    const { disabled } = props;
    const propfsForControllerType = {
        floatingLabelText: 'Type of Controller',
        items: [
            {
                id: 1,
                text: 'Gardener',
            },
            {
                id: 2,
                text: 'Manager',
            },
        ],
    };
    return (
      <div>
        <div style={{ ...styles.row }} className="text-row">
          <Field
            name={IrrigationFormFields.controller_type}
            type="text"
            component={RenderField}
            style={{ ...styles.rowItem }}
            disabled={disabled}
            floatingLabelText="Controller Type"
          />
        </div>
        <div style={{ ...styles.row }} className="text-row">
          <Field
            name={IrrigationFormFields.number_of_stations}
            type="number"
            component={RenderField}
            disabled={disabled}
            floatingLabelText="Number of stations"
            style={{ ...styles.row.field }}
          />

          <Field
            name={IrrigationFormFields.number_of_controllers}
            type="number"
            component={RenderField}
            disabled={disabled}
            floatingLabelText="Number of controllers"
            style={{ ...styles.row.field }}
          />
        </div>
        <div style={{ ...styles.row }} className="text-row">
          <Field
            name={IrrigationFormFields.water_district_name}
            type="text"
            component={RenderField}
            disabled={disabled}
            floatingLabelText="Water district name"
            style={{ ...styles.row.field }}
          />
        </div>
        <div style={{ ...styles.row }} className="text-row">
          <Field
            name={IrrigationFormFields.copies_of_water_bills}
            component={RenderCheckbox}
            disabled={disabled}
            label="Copies of water bills?"
            style={{ ...styles.row.field }}
          />
        </div>
      </div>
    );
};

IrrigationForm.defaultProps = {
    disabled: false,
};

IrrigationForm.propTypes = {
    disabled: PropTypes.bool,
};

export default IrrigationForm;
export { IrrigationFormFields };
