import React from 'react';
import { List, DateField, ShowButton, EditButton, Datagrid, TextField, SimpleList, Responsive } from 'admin-on-rest';
import EnablePricingGroupCheckbox from './enable_pricing_group';
import checkAccess from '../util/acl';

export const PricingGroupList = props => (
  <List title="Pricing groups" {...props} perPage={10}>
    {permissions => (
      <Responsive
        small={
          <SimpleList
            primaryText={record => record.name}
            // secondaryText={record => record.valid_start_date}
            // tertiaryText={record => record.duration_in_days}
          />
        }
        medium={
          <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <DateField source="valid_start_date" />
            <TextField source="duration_in_days" />
            {checkAccess('user_write', permissions && false) && (
              <EnablePricingGroupCheckbox source="enabled" sortable={false} />
            )}

            <ShowButton />
            {checkAccess('user_write', permissions) && <EditButton />}
          </Datagrid>
        }
      />
      
    )}
  </List>
);
