import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import RenderField, {
    RenderSelect,
    RenderDatePicker,
    RenderLongTextInput,
} from '../../shared/render_field';
import { required, positiveNumber } from '../../util/validate';
import states from '../../util/states';
import months from '../../util/months';
import { GetListForSelect, GetListForSelectWithIdsAndData } from '../../util/parse';
import styles from '../../styles';

const ProjectInfoFormFields = {
    project_name: 'project_name',
    legal_name: 'legal_name',
    job_project_type_id: 'job_project_type_id',
    number_of_units: 'number_of_units',
    gate_code_to_community: 'gate_code_to_community',
    street_address: 'street_address',
    city: 'city',
    state: 'state',
    zip: 'zip',
    fiscal_year: 'fiscal_year',
    start_date: 'start_date',
    supervisor_user_id: 'supervisor_user_id',
    yard_id: 'yard_id',
    jobs_to_run_with: 'jobs_to_run_with',
};
const ProjectInfoForm = (props) => {
    const { projectTypes, users, yard, disabled } = props;
    const propsForSuperviserUserSelect = GetListForSelectWithIdsAndData({
        data: users,
        label: 'Supervisor',
        idField: 'id',
        textField: ['firstName', 'lastName'],
        sort: true,
        sortAsNumber: false,
        filterResolver: item => item.is_supervisor === true,
    });
    const propsForYardSelect = GetListForSelect(yard, 'Yard #', 'id', 'name', true, true);
    const propsForProjectInfoSelect = GetListForSelect(
        projectTypes,
        'Project Type',
        'id',
        'project_type',
        true,
    );
    const propsForStatesSelect = {
        floatingLabelText: 'State',
        items: states,
    };

    const propsForFiscalYearSelect = {
        floatingLabelText: 'Fiscal year',
        items: months,
    };
    return (
      <div>
        <div style={{ ...styles.row }} className="text-row long-row">
          <div className="text-row">
            <Field
              name={ProjectInfoFormFields.project_name}
              type="text"
              component={RenderField}
              validate={[required]}
              disabled={disabled}
              floatingLabelText="Job/Project Name"
              style={{ ...styles.row.field }}
            />
            <Field
              name={ProjectInfoFormFields.legal_name}
              type="text"
              component={RenderField}
              validate={[required]}
              disabled={disabled}
              floatingLabelText="Legal Name (LLC)"
              style={{ ...styles.row.field }}
            />
          </div>
          <div className="text-row">
            <Field
              name={ProjectInfoFormFields.job_project_type_id}
              props={propsForProjectInfoSelect}
              validate={[required]}
              disabled={disabled}
              component={RenderSelect}
              style={{ ...styles.row.field }}
            />
          </div>
        </div>
        <div style={{ ...styles.row }} className="text-row">
          <Field
            name={ProjectInfoFormFields.number_of_units}
            type="number"
            component={RenderField}
            validate={[positiveNumber]}
            disabled={disabled}
            floatingLabelText="Number of Units/Homes"
            style={{ ...styles.row.field }}
          />
          <Field
            name={ProjectInfoFormFields.gate_code_to_community}
            type="text"
            component={RenderField}
            disabled={disabled}
            floatingLabelText="Gate code to community"
            style={{ ...styles.row.field }}
          />
        </div>
        <div style={{ ...styles.row }} className="text-row long-row">
          <div className="text-row">
            <Field
              name={ProjectInfoFormFields.street_address}
              type="text"
              component={RenderField}
              validate={[required]}
              disabled={disabled}
              floatingLabelText="Street address"
              style={{ ...styles.row.field }}
            />
            <Field
              name={ProjectInfoFormFields.city}
              type="text"
              component={RenderField}
              validate={[required]}
              disabled={disabled}
              floatingLabelText="City"
              style={{ ...styles.row.field }}
            />
          </div>
          <div className="text-row">
            <Field
              name={ProjectInfoFormFields.state}
              props={propsForStatesSelect}
              validate={[required]}
              disabled={disabled}
              component={RenderSelect}
              style={{ ...styles.row.field }}
            />
            <Field
              name={ProjectInfoFormFields.zip}
              type="number"
              component={RenderField}
              validate={[required]}
              disabled={disabled}
              floatingLabelText="Zip"
              style={{ ...styles.row.field }}
            />
          </div>
        </div>
        <div style={{ ...styles.row }} className="text-row">
          <Field
            name={ProjectInfoFormFields.fiscal_year}
            props={propsForFiscalYearSelect}
            validate={[required, positiveNumber]}
            disabled={disabled}
            component={RenderSelect}
            style={{ ...styles.row.field }}
          />
          <Field
            name={ProjectInfoFormFields.start_date}
            props={{ hintText: 'Start Date', container: 'inline' }}
            disabled={disabled}
            validate={[required]}
            component={RenderDatePicker}
            floatingLabelText="Start date"
            style={{ ...styles.row.field }}
          />
        </div>
        <div style={{ ...styles.row }} className="text-row">
          <Field
            name={ProjectInfoFormFields.supervisor_user_id}
            props={propsForSuperviserUserSelect}
            validate={[required]}
            disabled={disabled}
            component={RenderSelect}
            style={{ ...styles.row.field }}
          />
          <Field
            name={ProjectInfoFormFields.yard_id}
            props={propsForYardSelect}
            disabled={disabled}
            validate={[required]}
            component={RenderSelect}
            style={{ ...styles.row.field }}
          />
        </div>
        <div style={{ ...styles.row }} className="text-row">
          <Field
            name={ProjectInfoFormFields.jobs_to_run_with}
            type="text"
            component={RenderLongTextInput}
            disabled={disabled}
            label="Job(s) to run with"
            style={{ ...styles.row.field }}
          />
        </div>
      </div>
    );
};
ProjectInfoForm.defaultProps = {
    disabled: false,
};
ProjectInfoForm.propTypes = {
    projectTypes: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    yard: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
};

export default ProjectInfoForm;
export { ProjectInfoFormFields };
