import { call, put, takeEvery, all } from 'redux-saga/effects';
import { CONTRACT_DOWNLOAD, contractDownloadStarted, contractDownloadFinished } from '../actions';
import { showNotification } from 'admin-on-rest';
import { baseUrl } from '../client';

const contractDownloadAPI = function (id) {
    const token = localStorage.getItem('token');
    return fetch(`${baseUrl}/contractdownload/${id}`, {
        method: 'get',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return response.blob();
        })
        .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'contract.pdf';
            a.click();
        });
};

function* contractDownload({ id }) {
    try {
        yield put(contractDownloadStarted());
        yield call(contractDownloadAPI, id);
    } catch (err) {
        yield put(showNotification(err.message));
    }
    yield put(contractDownloadFinished());
}

export function* contractDownloadSaga() {
    yield all([takeEvery(CONTRACT_DOWNLOAD, contractDownload)]);
}
