export const GetQueryParams = function (searchStr) {
    const queryParams = {};
    searchStr.replace('?','')
        .split('&')
        .map((el) => {
            const keyVal = el.split('=');
            if (keyVal) {
                const key = keyVal[0];
                const val = keyVal[1];
                queryParams[key] = val;
            }
        });
    return queryParams;
};
