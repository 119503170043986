export const RAISE_REQUEST_DOWNLOAD = 'RAISE_REQUEST_DOWNLOAD';
export const raiseRequestDownload = ({ id }) => ({
    type: RAISE_REQUEST_DOWNLOAD,
    id,
});

export const RAISE_REQUEST_EMAIL = 'RAISE_REQUEST_EMAIL';
export const raiseRequestEmail = ({ id }) => ({
    type: RAISE_REQUEST_EMAIL,
    id,
});

export const RAISE_REQUEST_EMAIL_STARTED = 'RAISE_REQUEST_EMAIL_STARTED';
export const raiseRequestEmailStarted = () => ({
    type: RAISE_REQUEST_EMAIL_STARTED,
});

export const RAISE_REQUEST_EMAIL_FINISHED = 'RAISE_REQUEST_EMAIL_FINISHED';
export const raiseRequestEmailFinished = () => ({
    type: RAISE_REQUEST_EMAIL_FINISHED,
});
