// errorSagas.js, to be imported as a custom saga
import {
    CRUD_UPDATE_SUCCESS,
    CRUD_CREATE_SUCCESS,
    CRUD_CREATE_FAILURE,
    CRUD_DELETE_FAILURE,
    CRUD_DELETE_SUCCESS,
    CRUD_UPDATE_FAILURE,
    CRUD_GET_ONE_SUCCESS,
    FETCH_ERROR,
    showNotification,
} from 'admin-on-rest';
import { stopSubmit, initialize, destroy } from 'redux-form';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';

function* crudCreateSuccess(action) {
    switch (action.meta.resource) {
    case 'user':
        yield put(showNotification('User created', 'warning'));
        break;
    case 'pricinggroup':
        yield put(showNotification('Pricing group created', 'warning'));
        break;
    case 'laborrate':
        yield put(showNotification('Labor rate created', 'warning'));
        break;
    case 'management':
        yield put(showNotification('Management created', 'warning'));
        break;
    case 'manager':
        yield put(showNotification('Manager created', 'warning'));
        break;
    case 'jobsetup':
        yield put(showNotification('Job created', 'warning'));
        break;
    case 'contracttemplate':
        yield put(showNotification('Contract template created', 'warning'));
        break;
    case 'contract':
        yield put(showNotification('Contract created', 'warning'));
        break;
    case 'raiserequesttemplate':
        yield put(showNotification('Raise request template created', 'warning'));
        break;
    case 'raiserequest':
        yield put(showNotification('Raise request created', 'warning'));
        break;
    default:
        yield put(showNotification(`${action.meta.resource} created`, 'warning'));
    }
}
function* crudUpdateSuccess(action) {
    switch (action.meta.resource) {
    case 'user':
        yield put(showNotification('User updated', 'warning'));
        break;
    case 'pricinggroup':
        yield put(showNotification('Pricing group updated', 'warning'));
        break;
    case 'laborrate':
        yield put(showNotification('Labor rate updated', 'warning'));
        break;
    case 'management':
        yield put(showNotification('Management updated', 'warning'));
        break;
    case 'manager':
        yield put(showNotification('Manager updated', 'warning'));
        break;
    case 'jobsetup':
        yield put(showNotification('Job updated', 'warning'));
        break;
    case 'contracttemplate':
        yield put(showNotification('Contract template updated', 'warning'));
        break;
    case 'contract':
        yield put(showNotification('Contract updated', 'warning'));
        break;
    case 'raiserequesttemplate':
        yield put(showNotification('Raise request template updated', 'warning'));
        break;
    case 'raiserequest':
        yield put(showNotification('Raise request updated', 'warning'));
        break;
    default:
        yield put(showNotification(`${action.meta.resource} updated`, 'warning'));
    }
}
function* crudDeleteSuccess(action) {
    switch (action.meta.resource) {
    case 'user':
        yield put(showNotification('User deleted', 'warning'));
        break;
    case 'pricinggroup':
        yield put(showNotification('Pricing group deleted', 'warning'));
        break;
    case 'laborrate':
        yield put(showNotification('Labor rate deleted', 'warning'));
        break;
    case 'management':
        yield put(showNotification('Management deleted', 'warning'));
        break;
    case 'manager':
        yield put(showNotification('Manager deleted', 'warning'));
        break;
    case 'jobsetup':
        yield put(showNotification('Job deleted', 'warning'));
        break;
    case 'contracttemplate':
        yield put(showNotification('Contract template deleted', 'warning'));
        break;
    case 'contract':
        yield put(showNotification('Contract deleted', 'warning'));
        break;
    case 'raiserequesttemplate':
        yield put(showNotification('Raise request template deleted', 'warning'));
        break;
    case 'raiserequest':
        yield put(showNotification('Raise request deleted', 'warning'));
        break;
    default:
        yield put(showNotification(`${action.meta.resource} deleted`, 'warning'));
    }
}
function* crudCreateFailure(action) {
    const json = action.payload;
    yield put(showNotification('There are some errors on the page', 'warning'));
    yield put(stopSubmit('record-form', json));
}
function* crudDeleteFailure(action) {
    const { error } = action.payload;
    yield put(showNotification(error, 'warning'));
}
function* crudGetOneSuccess(action) {
    yield put(initialize('record-form', action.payload.data, false));
}

function* fetchError(data) {
    if (data.error.status == 401) {
        yield put(showNotification("You're not logged", 'warning'));
    } else if (data.error.status == 403) {
        yield put(showNotification("You don't have access to this", 'warning'));
    } else if (data.error.status == 500) {
        yield put(showNotification('There was en error on the server', 'warning'));
    }
}

function* locationChange(data) {
    yield put(destroy('record-form'));
}
export function* errorSagas() {
    yield takeEvery(CRUD_CREATE_FAILURE, crudCreateFailure);
    yield takeEvery(CRUD_UPDATE_FAILURE, crudCreateFailure);
    yield takeLatest(FETCH_ERROR, fetchError);
    yield takeEvery(CRUD_CREATE_SUCCESS, crudCreateSuccess);
    yield takeEvery(CRUD_UPDATE_SUCCESS, crudUpdateSuccess);
    yield takeLatest(CRUD_GET_ONE_SUCCESS, crudGetOneSuccess);
    yield takeLatest(CRUD_DELETE_FAILURE, crudDeleteFailure);
    yield takeLatest(CRUD_DELETE_SUCCESS, crudDeleteSuccess);
    yield takeEvery('@@router/LOCATION_CHANGE', locationChange);
}
