import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { RenderSelect, RenderCheckbox } from '../../shared/render_field';
import { GetListForSelectWithIdsAndData } from '../../util/parse';
import styles from '../../styles';

const ItemsToBeCompletedFormFields = {
    job_setup_pictures: 'job_setup_pictures',
    job_setup_pictures_assigned_to: 'job_setup_pictures_assigned_to',
    site_plan: 'site_plan',
    site_plan_assigned_to: 'site_plan_assigned_to',
    irrigation_map: 'irrigation_map',
    irrigation_map_assigned_to: 'irrigation_map_assigned_to',
    old_rotation_map: 'old_rotation_map',
    old_rotation_map_assigned_to: 'old_rotation_map_assigned_to',
    current_tree_count: 'current_tree_count',
    current_tree_count_assigned_to: 'current_tree_count_assigned_to',
};

const fixWidth = {
    // width: '256px',
};
const ItemsToBeCompletedForm = (props) => {
    const { users, disabled } = props;
    const usersList = GetListForSelectWithIdsAndData({
        data: users,
        label: 'Assigned To',
        idField: 'id',
        textField: ['firstName', 'lastName'],
        sort: true,
        sortAsNumber: false,
    });
    return (
      <div>
        <div style={{ ...styles.row, alignItems: 'center' }} className="text-row">
          <Field
            name={ItemsToBeCompletedFormFields.job_setup_pictures}
            label="Job set-up pictures"
            disabled={disabled}
            component={RenderCheckbox}
            style={{ ...fixWidth }}
          />
          <Field
            name={ItemsToBeCompletedFormFields.job_setup_pictures_assigned_to}
            props={usersList}
            disabled={disabled}
            component={RenderSelect}
            style={{ ...styles.row.field }}
          />
        </div>

        <div style={{ ...styles.row, alignItems: 'center' }} className="text-row">
          <Field
            name={ItemsToBeCompletedFormFields.site_plan}
            label="Site Plan"
            disabled={disabled}
            component={RenderCheckbox}
            style={{ ...fixWidth }}
          />
          <Field
            name={ItemsToBeCompletedFormFields.site_plan_assigned_to}
            props={usersList}
            disabled={disabled}
            component={RenderSelect}
            style={{ ...styles.row.field }}
          />
        </div>
        <div style={{ ...styles.row, alignItems: 'center' }} className="text-row">
          <Field
            name={ItemsToBeCompletedFormFields.irrigation_map}
            component={RenderCheckbox}
            disabled={disabled}
            label="Irrigation Map"
            style={{ ...fixWidth }}
          />
          <Field
            name={ItemsToBeCompletedFormFields.irrigation_map_assigned_to}
            props={usersList}
            disabled={disabled}
            component={RenderSelect}
            style={{ ...styles.row.field }}
          />
        </div>
        <div style={{ ...styles.row, alignItems: 'center' }} className="text-row">
          <Field
            name={ItemsToBeCompletedFormFields.old_rotation_map}
            component={RenderCheckbox}
            disabled={disabled}
            label="Old Rotation Map"
            style={{ ...fixWidth }}
          />
          <Field
            name={ItemsToBeCompletedFormFields.old_rotation_map_assigned_to}
            props={usersList}
            disabled={disabled}
            component={RenderSelect}
            style={{ ...styles.row.field }}
          />
        </div>
        <div style={{ ...styles.row, alignItems: 'center' }} className="text-row">
          <Field
            name={ItemsToBeCompletedFormFields.current_tree_count}
            component={RenderCheckbox}
            disabled={disabled}
            label="Current Tree Count"
            style={{ ...fixWidth }}
          />
          <Field
            name={ItemsToBeCompletedFormFields.current_tree_count_assigned_to}
            props={usersList}
            disabled={disabled}
            component={RenderSelect}
            style={{ ...styles.row.field }}
          />
        </div>
      </div>
    );
};

ItemsToBeCompletedForm.defaultProps = {
    disabled: false,
};

ItemsToBeCompletedForm.propTypes = {
    disabled: PropTypes.bool,
};

export default ItemsToBeCompletedForm;
export { ItemsToBeCompletedFormFields };
