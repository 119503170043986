import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ViewTitle } from 'admin-on-rest';
import { Card } from 'material-ui/Card';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { checkActivateToken } from '../actions';
import { GetQueryParams } from '../util/url';

class Activate extends Component {
    constructor(props) {
        super(props);
        // const queryParams = GetQueryParams(props.location.search)
        // const { token } = queryParams;

        // if (token) {
        //     props.actions.checkActivateToken(token);
        // }
    }

    componentWillMount() {
        const queryParams = GetQueryParams(this.props.location.search);
        const { token } = queryParams;

        if (token) {
            this.props.actions.checkActivateToken(token);
        }
    }
    render() {
        return (
          <Card>
            <ViewTitle title="Activate Your Account" />
          </Card>
        );
    }
}

const ActivateWithRouter = withRouter(Activate);

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators({ checkActivateToken }, dispatch) };
}
function mapStateToProps(state) {
    return {};
}

const ConnnectedComponent = connect(mapStateToProps, mapDispatchToProps)(ActivateWithRouter);
export default ConnnectedComponent;
