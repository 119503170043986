import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  reduxForm,
  getFormValues,
  change,
  submit,
  getFormSyncErrors,
  getFormAsyncErrors,
  hasSubmitFailed
} from "redux-form";
import { bindActionCreators } from "redux";
import RaisedButton from "material-ui/RaisedButton";
import { Toolbar, ToolbarGroup } from "material-ui/Toolbar";
import { Tabs, Tab } from "material-ui/Tabs";
import ErrorIcon from "material-ui/svg-icons/alert/error";
import _ from "lodash";
import { fetchOffices } from "../../actions";
import PrimaryInfo, { PrimaryInfoFormFields } from "./primaryInfoTab";
import AccessControlList from "./accessControlListTab";
import { checkIfEmailExists } from "../../util/validate";
import { denormalize } from "../../util/normalizr";

import "../../styles/form.css";
import styles from "../../styles";

const formName = "record-form";

function getErrors(errors, submitFailed, fields) {
  let errorsOccured = [];
  if (errors && submitFailed) {
    const errorKeys = Object.keys(errors);
    const fieldKeys = Object.keys(fields);
    errorsOccured = _.intersection(errorKeys, fieldKeys);
  }
  return errorsOccured;
}
class User extends Component {
  constructor(props) {
    super(props);
    this.onSave = this.onSave.bind(this);
    this.updateField = this.updateField.bind(this);
    this.state = { selectedTab: 0 };
  }
  onSave(formData) {
    this.props.save(formData, "list");
  }
  componentDidMount() {
    this.props.actions.fetchOffices({
      pagination: { page: 1, perPage: 1000 },
      sort: { field: "name", order: "ASC" },
      filter: {}
    });
  }
  render() {
    const {
      save,
      handleSubmit,
      formValues,
      submitFailed,
      errors,
      asyncErrors,
      disabled,
      office
    } = this.props;
    const { submit } = this.props.actions;
    const { selectedTab } = this.state;
    const primaryInfoErrors = getErrors(
      errors,
      submitFailed,
      PrimaryInfoFormFields
    );
    let primaryInfoTabStyle = styles.tab.button;
    let primaryInfoLabel = "Primary Info";
    if (selectedTab === 0) {
      primaryInfoTabStyle = styles.tab.selectedButton;
    } else if (primaryInfoErrors.length > 0) {
      primaryInfoLabel = (
        <div style={{ ...styles.tab.buttonWithErrorsInnerDiv }}>
          Primary Info
          <ErrorIcon color="red" />
        </div>
      );
      primaryInfoTabStyle = styles.tab.buttonWithErrors;
    }
    return (
      <form className="simple-form" onSubmit={handleSubmit(this.onSave)}>
        <div className="field">
          <Tabs
            inkBarStyle={{ ...styles.tab.inkBarStyle }}
            value={this.state.selectedTab}
            onChange={value => this.setState({ selectedTab: value })}
          >
            <Tab
              label={primaryInfoLabel}
              value={0}
              buttonStyle={{ ...primaryInfoTabStyle }}
            >
              <PrimaryInfo
                disabled={disabled}
                offices={office}
                isEditing={this.props.isEditing}
              />
            </Tab>
            <Tab
              label="Permissions"
              value={1}
              buttonStyle={
                selectedTab == 1
                  ? { ...styles.tab.selectedButton }
                  : { ...styles.tab.button }
              }
            >
              <AccessControlList
                formValues={formValues}
                updateField={this.updateField}
                disabled={disabled}
              />
            </Tab>
          </Tabs>
        </div>
        <Toolbar>
          <ToolbarGroup firstChild>
            {this.state.selectedTab === 0 && (
              <RaisedButton
                label="Next"
                primary
                onClick={() => this.setState({ selectedTab: 1 })}
              />
            )}
            {this.state.selectedTab === 1 && (
              <RaisedButton
                label="Back"
                primary
                onClick={() => this.setState({ selectedTab: 0 })}
              />
            )}
          </ToolbarGroup>
          <ToolbarGroup lastChild>
            {this.state.selectedTab === 1 && !disabled && (
              <RaisedButton label="Save" type="Submit" primary />
            )}
          </ToolbarGroup>
        </Toolbar>
      </form>
    );
  }
  updateField(newValue, fieldsToChange) {
    const { change } = this.props.actions;
    for (let i = 0; i < fieldsToChange.length; i++) {
      const fieldToChange = fieldsToChange[i];
      change(formName, fieldToChange, newValue);
    }
  }
}

let UserReduxForm = reduxForm({
  form: formName, // <------ same form name
  destroyOnUnmount: true, // <------ preserve form data
  forceUnregisterOnUnmount: false, // <------ unregister fields on unmount
  asyncValidate: checkIfEmailExists,
  asyncBlurFields: ["email"]
})(User);

function mapStateToProps(state, thisprops) {
  const formValues = getFormValues(formName)(state);
  const syncErrors = getFormSyncErrors(formName)(state);
  const asyncErrors = getFormAsyncErrors(formName)(state);
  const submitFailed = hasSubmitFailed(formName)(state);
  return {
    formValues,
    save: thisprops.save,
    loading: state.admin.loading,
    office: denormalize(
      state.admin.resources.office.data,
      state.admin.resources.office.list.ids
    ),
    errors: { ...syncErrors, ...asyncErrors },
    submitFailed,
    initialValues: thisprops.record,
    disabled: thisprops.disabled
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        change,
        submit,
        fetchOffices
      },
      dispatch
    )
  };
}

UserReduxForm = connect(mapStateToProps, mapDispatchToProps)(UserReduxForm);

const EnhancedContainer = props => (
  <div>
    <UserReduxForm
      isEditing={props.isEditing}
      save={props.save}
      actions={props.actions}
      record={props.record}
      disabled={props.disabled}
    />
  </div>
);

EnhancedContainer.defaultProps = {
  disabled: false
};
EnhancedContainer.propTypes = {
  disabled: PropTypes.bool
};

export default EnhancedContainer;
