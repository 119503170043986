import { UPDATE, GET_LIST, CRUD_GET_LIST } from 'admin-on-rest';

export const ENABLE_LABOR_RATE = 'ENABLE_LABOR_RATE';
export const enableLaborRate = (id, data, basePath) => ({
    type: ENABLE_LABOR_RATE,
    payload: { id, data: { ...data, enabled: !data.enabled } },
    meta: { resource: 'laborrate', fetch: UPDATE, cancelPrevious: false },
});

export const fetchLaborRate = data => ({
    type: CRUD_GET_LIST,
    payload: { ...data },
    meta: { resource: 'laborrate', fetch: GET_LIST, cancelPrevious: false },
});
