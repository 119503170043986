import React from 'react';
import ReactDOM from 'react-dom';
// import App from './App';
// import registerServiceWorker from './registerServiceWorker';
import './index.css';
import './styles/checkboxes.css';
import './styles/text.css';

import Admin from './admin';
// import newapp from './newapp';

ReactDOM.render(<Admin />, document.getElementById('root'));
// registerServiceWorker();
