export const SEARCH_INPUT_CHANGED = 'SEARCH_INPUT_CHANGED';
export const searchInputChanged = input => ({
    type: SEARCH_INPUT_CHANGED,
    payload: input,
});
export const SEARCH = 'SEARCH';
export const search = query => ({
    type: SEARCH,
    query,
});

export const SEARCH_CLEAR_RESULTS = 'SEARCH_CLEAR_RESULTS';
export const searchClearResults = () => ({
    type: SEARCH_CLEAR_RESULTS,
});

export const SEARCH_STARTED = 'SEARCH_STARTED';
export const searchStarted = () => ({
    type: SEARCH_STARTED,
});

export const SEARCH_FINISHED = 'SEARCH_FINISHED';
export const searchFinished = data => ({
    type: SEARCH_FINISHED,
    payload: data,
});
