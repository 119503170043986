export function deleteCookie(name, path, domain) {
    document.cookie = `${name}=${path ? `;path=${path}` : ''}${domain
        ? `;domain=${domain}`
        : ''};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
}

export function getCookieValue(cookieKey) {
    const filtered = document.cookie.split(';').filter((item) => {
        const keyVal = item.split('=');
        const key = keyVal[0].trim();
        return key == cookieKey;
    });
    if (filtered.length === 1) {
        return filtered[0].split('=')[1].trim();
    }
    return '';
}
