import React from 'react';
import Chip from 'material-ui/Chip';
import { red500, green500, yellow500, amber500 } from 'material-ui/styles/colors';

export default function GetAcceptedRejected(acceptRejected, emailSentCount) {
    if (acceptRejected === null || acceptRejected === undefined) {
        if (emailSentCount > 0) {
            return (
              <Chip backgroundColor={yellow500} labelColor="black">
                    Awaiting approval
                </Chip>
            );
        }
        return (
          <Chip backgroundColor={amber500} labelColor="black">
                In progress
            </Chip>
        );
    }
    if (acceptRejected) {
        return (
          <Chip backgroundColor={green500} labelColor="white">
                Accepted
            </Chip>
        );
    }
    return (
      <Chip backgroundColor={red500} labelColor="white">
            Rejected
        </Chip>
    );
}
