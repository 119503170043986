import React from 'react';
import {
    List,
    Filter,
    ShowButton,
    EditButton,
    TextField,
    Datagrid,
    ChipField,
    TextInput,
    ReferenceInput,
    SelectInput,
    SimpleList,
    Responsive,
} from 'admin-on-rest';
import checkAccess from '../util/acl';

const PostFilter = props => (
  <Filter {...props}>
    <TextInput label="Name" source="name" />
    <TextInput label="Email" source="email" />
    <ReferenceInput label="Management" source="management_company_id" reference="management">
      <SelectInput optionText="company_name" optionValue="id" />
    </ReferenceInput>
  </Filter>
);

const ListComponent = props => (
  <div className="list-wrapper">
    <List title="Managers" {...props} perPage={10} filters={<PostFilter />}>
      {permissions => (
        <Responsive
          small={
            <SimpleList
              primaryText={record => record.name}
              secondaryText={record => record.management.company_name}
              // tertiaryText={record => record.email}
            />
          }
          medium={
            <Datagrid>
              <TextField source="id" />
              <TextField source="name" />
              <TextField source="email" />
              <TextField source="phone" />
              <ChipField
                source="management.company_name"
                label="Management Company"
                sortable={false}
              />
              <ShowButton />
              {checkAccess('manager_write', permissions) && <EditButton />}
            </Datagrid>
          }
        />        
      )}
    </List>
  </div>
);
export default ListComponent;
