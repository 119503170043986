import _ from 'lodash';

const offices = [
    { id: 'alisoviejo', name: 'Aliso Viejo' },
    { id: 'anaheim', name: 'Anaheim' },
    { id: 'applevalley', name: 'Apple Valley' },
    { id: 'beaumont', name: 'Beaumont' },
    { id: 'chino', name: 'Chino' },
    { id: 'chulavista', name: 'Chula Vista' },
    { id: 'corona', name: 'Corona' },
    { id: 'indio', name: 'Indio' },
    { id: 'laderaranch', name: 'Ladera Ranch' },
    { id: 'murrieta', name: 'Murrieta' },
    { id: 'newportbeach', name: 'Newport Beach' },
    { id: 'oceanhills', name: 'Ocean Hills' },
    { id: 'ranchosantamargarita', name: 'Rancho Santa Margarita' },
    { id: 'sandiego', name: 'San Diego' },
    { id: 'sanjuan', name: 'San Juan' },
    { id: 'sanjuancapistrano', name: 'San Juan Capistrano' },
    { id: 'sanmarcos', name: 'San Marcos' },
    { id: 'santaana', name: 'Santa Ana' },
    { id: 'santaluz', name: 'Santaluz' },
    { id: 'watson', name: 'Watson' },
];

function getOfficeNameByOfficeId(id) {
    const office = _.find(offices, o => o.id === id);
    if (office) {
        return office.name;
    }
    return '';
}

export default offices;
export { getOfficeNameByOfficeId };
