import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'admin-on-rest';
import { AUTH_GET_PERMISSIONS } from 'aor-permissions';
import { AuthCheck, LogUserOut, LogUserIn } from './auth';
// import { decode } from 'jsonwebtoken';

import { loginAPIUrl } from './client';

export default (type, params) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    localStorage.setItem('username', username);

    console.log('loginAPIUrl', loginAPIUrl);
    const request = new Request(`/authenticate`, {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
      credentials: 'same-origin',
    });

    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          return response.text().then(data => Promise.reject(data));
        }
        return response.json();
      })
      .then(({ token, roles }) => {
        LogUserIn(token, roles);
        return Promise.resolve();
      });
  }
  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    LogUserOut();
    return Promise.resolve();
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 /* || status === 403 */) {
      LogUserOut();
      return Promise.reject();
    }
    return Promise.resolve();
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    return AuthCheck() ? Promise.resolve() : Promise.reject();
  }

  if (type === AUTH_GET_PERMISSIONS) {
    const roles = JSON.parse(localStorage.getItem('roles'));
    return Promise.resolve(roles);
  }
  return Promise.reject('Unknown method');
};
