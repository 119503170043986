export const PASSWORD_RESET = 'PASSWORD_RESET';
export const PASSWORD_FORGOT = 'PASSWORD_FORGOT';

export const PASSWORD_FORGOT_STARTED = 'PASSWORD_FORGOT_STARTED';
export const PASSWORD_FORGOT_FINISHED = 'PASSWORD_FORGOT_FINISHED';

export const PASSWORD_RESET_STARTED = 'PASSWORD_RESET_STARTED';
export const PASSWORD_RESET_FINISHED = 'PASSWORD_RESET_FINISHED';

export const passwordForgotStarted = () => ({
    type: PASSWORD_FORGOT_STARTED,
});

export const passwordForgotFinished = () => ({
    type: PASSWORD_FORGOT_FINISHED,
});

export const passwordResetStarted = () => ({
    type: PASSWORD_RESET_STARTED,
});

export const passwordResetFinished = () => ({
    type: PASSWORD_RESET_FINISHED,
});

export const passwordReset = (password, logoutAfterReset) => ({
    type: PASSWORD_RESET,
    password,
    logoutAfterReset,
});

export const passwordForgot = email => ({
    type: PASSWORD_FORGOT,
    email,
});
