import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ViewTitle } from 'admin-on-rest';
import { Card } from 'material-ui/Card';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logOutManually } from '../actions';

class Activate extends Component {
    componentWillMount() {
        this.props.actions.logOutManually();
    }
    render() {
        return (
          <Card>
            <ViewTitle title="Logging out" />
          </Card>
        );
    }
}

const ActivateWithRouter = withRouter(Activate);

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators({ logOutManually }, dispatch) };
}
function mapStateToProps() {
    return {};
}

const ConnnectedComponent = connect(mapStateToProps, mapDispatchToProps)(ActivateWithRouter);
export default ConnnectedComponent;
