import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Edit } from 'admin-on-rest';
import { connect } from 'react-redux';
import SaveButton from '../components/savebutton';
import { reduxForm, Field } from 'redux-form';
import TokensList from './tokens_supported';
import { Toolbar, ToolbarGroup } from 'material-ui/Toolbar';
import RenderField, { RenderEditor, RenderLongTextInput } from '../shared/render_field';
import { required } from '../util/validate';
import '../styles/form.css';

class ContrateTemplateForm extends Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this);
    }
    onSave(formData) {
        const { name, content, footer } = formData;
        this.props.save({
            name,
            content,
            footer,
        });
    }
    render() {
        const { loading, save, handleSubmit, submitting } = this.props;
        return (
          <form className="simple-form" onSubmit={handleSubmit(this.onSave)}>
            <div className="field">
              <Field
                name="name"
                type="text"
                component={RenderField}
                validate={[required]}
                floatingLabelText="Name"
              />

              <h4>Tokens Supported:</h4>
              <div>
                <TokensList />
              </div>

              <div>
                <Field name="content" component={RenderEditor} />
              </div>
              <Field
                name="footer"
                component={RenderLongTextInput}
                validate={[required]}
                label="Footer (use HTML)"
              />
            </div>
            <Toolbar>
              <ToolbarGroup lastChild>
                <SaveButton label="Save" loading={loading} />
              </ToolbarGroup>
            </Toolbar>
          </form>
        );
    }
}
const formName = 'record-form';

function mapStateToProps(state, thisprops) {
    const { name, content, footer } = thisprops.record;
    return {
        loading: state.admin.loading,
        save: thisprops.save,
        initialValues: {
            name,
            content,
            footer,
        },
    };
}

ContrateTemplateForm = reduxForm({
    form: formName, // <------ same form name
    destroyOnUnmount: true, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(ContrateTemplateForm);

ContrateTemplateForm = connect(mapStateToProps)(ContrateTemplateForm);

class EnhancedContainer extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { record, save } = this.props;
        return (
          <div>
            <ContrateTemplateForm record={record} save={save} />
          </div>
        );
    }
}

class EditForm extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
          <Edit title="Edit contract template" {...this.props}>
            <EnhancedContainer />
          </Edit>
        );
    }
}
export default EditForm;
