import React from 'react';
import { Show } from 'admin-on-rest';

import UserContainer from './shared/form';

const UserShow = props => (
  <Show {...props}>
    <UserContainer disabled />
  </Show>
);

export { UserShow };
