import React, { Component } from 'react';
import SharedFields from './shared';
import { Create } from 'admin-on-rest';

import '../styles/form.css';

class Frm extends Component {
    render() {
        return (
          <div>
            <SharedFields {...this.props} />
          </div>
        );
    }
}

class EnhancedContainer extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { save } = this.props;
        return (
          <div>
            <SharedFields save={save} />
          </div>
        );
    }
}

class CreateForm extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
          <Create title="Create raise request" {...this.props}>
            <EnhancedContainer />
          </Create>
        );
    }
}
export default CreateForm;
