import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Create } from 'admin-on-rest';
import { connect } from 'react-redux';
import SaveButton from '../components/savebutton';
import { reduxForm, Field } from 'redux-form';
import TokensList from './tokens_supported';
import { Toolbar, ToolbarGroup } from 'material-ui/Toolbar';
import RenderField, { RenderEditor, RenderLongTextInput } from '../shared/render_field';
import { required } from '../util/validate';
import '../styles/form.css';

class ContrateTemplateForm extends Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this);
    }
    onSave(formData) {
        const { name, content, footer } = formData;
        this.props.save(
            {
                name,
                content,
                footer,
            },
            'list',
        );
    }
    render() {
        const { loading, save, handleSubmit, submitting } = this.props;
        return (
          <form className="simple-form" onSubmit={handleSubmit(this.onSave)}>
            <div className="field">
              <Field
                name="name"
                type="text"
                className="field"
                component={RenderField}
                validate={[required]}
                floatingLabelText="Name"
              />
              <div className="field">
                <h4>Tokens Supported:</h4>
                <div>
                  <TokensList />
                </div>
              </div>
              <div>
                <Field name="content" component={RenderEditor} />
              </div>
              <Field
                name="footer"
                component={RenderLongTextInput}
                label="Footer (use HTML)"
              />
            </div>
            <Toolbar>
              <ToolbarGroup lastChild>
                <SaveButton label="Save" loading={loading} />
              </ToolbarGroup>
            </Toolbar>
          </form>
        );
    }
}
const formName = 'record-form';

function mapStateToProps(state, thisprops) {
    return {
        loading: state.admin.loading,
        save: thisprops.save,
    };
}

ContrateTemplateForm = reduxForm({
    form: formName, // <------ same form name
    destroyOnUnmount: true, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(ContrateTemplateForm);

ContrateTemplateForm = connect(mapStateToProps)(ContrateTemplateForm);

class EnhancedContainer extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { save } = this.props;
        return (
          <div>
            <ContrateTemplateForm save={save} />
          </div>
        );
    }
}

class CreateForm extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
          <Create title="Create contract template" {...this.props}>
            <EnhancedContainer />
          </Create>
        );
    }
}
export default CreateForm;
