import { GET_LIST, UPDATE, CRUD_GET_LIST } from 'admin-on-rest';

export const ENABLE_MANAGEMENT_INFO = 'ENABLE_MANAGEMENT_INFO';
export const enableManagementInfo = (id, data, basePath) => ({
    type: ENABLE_MANAGEMENT_INFO,
    payload: { id, data: { ...data, enabled: !data.enabled } },
    meta: { resource: 'management', fetch: UPDATE, cancelPrevious: false },
});

export const fetchManagement = data => ({
    type: CRUD_GET_LIST,
    payload: { ...data },
    meta: { resource: 'management', fetch: GET_LIST, cancelPrevious: false },
});
