import React from 'react';
import PropTypes from 'prop-types';
import { Field, change } from 'redux-form';
import TextField from 'material-ui/TextField';
import RenderField, { RenderSelect, RenderCurrency } from '../../shared/render_field';
import { GetListForSelect } from '../../util/parse';
import { required } from '../../util/validate';
import numeral from 'numeral';

const styles = {
    row: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
    },
    rowItem: {
        marginRight: '2em',
    },
};

const ContractSummaryFormFields = {
    total_price: 'total_price',
    original_price: 'original_price',
    price_note: 'price_note',
    deduction_1: 'deduction_1',
    deduction_2: 'deduction_2',
    deduction_3: 'deduction_3',
    deduction_4: 'deduction_4',
    deduction_5: 'deduction_5',
};
const formName = 'record-form';

function getNullVal(val) {
    if (val === undefined || val === null) {
        return 0;
    }
    return val;
}
const ContractSummaryForm = (props) => {
    const {
        disabled,
        deductionTypes,
        original_price,
        deduction_1,
        deduction_2,
        deduction_3,
        deduction_4,
        deduction_5,
    } = props;
    const totalPrice =
        getNullVal(original_price) -
        getNullVal(deduction_1) -
        getNullVal(deduction_2) -
        getNullVal(deduction_3) -
        getNullVal(deduction_4) -
        getNullVal(deduction_5);
    const propsForDeductionTypeSelect = GetListForSelect(
        deductionTypes,
        'Deduction Type',
        'id',
        'name',
        true,
    );
    const deductions = [];
    for (let i = 1; i <= 5; i++) {
        deductions.push(
          <div style={{ ...styles.row }} key={i} className="text-row">
            <Field
              name={`deduction_${i}_type_id`}
              component={RenderSelect}
              style={{ ...styles.rowItem }}
              disabled={disabled}
              props={propsForDeductionTypeSelect}
            />
            <Field
              name={`deduction_${i}`}
              type="text"
              component={RenderCurrency}
              style={{ ...styles.rowItem }}
              disabled={disabled}
              onChange={val => change(`deduction_${i}`, formName, val)}
              floatingLabelText="Deduction Price"
            />
          </div>,
        );
    }
    return (
      <div>
        <div style={{ ...styles.row }} className="text-row">
          <Field
            name={ContractSummaryFormFields.original_price}
            type="text"
            component={RenderCurrency}
            onChange={val =>
                        change(ContractSummaryFormFields.original_price, formName, val)}
            style={{ ...styles.rowItem }}
            disabled={disabled}
            validate={[required]}
            floatingLabelText="Original Price"
          />
          <Field
            name={ContractSummaryFormFields.price_note}
            type="text"
            component={RenderField}
            style={{ ...styles.rowItem }}
            disabled={disabled}
            floatingLabelText="Price Note"
          />
        </div>
        {deductions}
        <div style={{ ...styles.row }} className="text-row">
          <div>
            <TextField
              value={numeral(totalPrice).format('$0,0.00')}
              id={ContractSummaryFormFields.total_price}
              floatingLabelText="Total Price"
            />
          </div>
        </div>
      </div>
    );
};

ContractSummaryForm.defaultProps = {
    disabled: false,
};

ContractSummaryForm.propTypes = {
    disabled: PropTypes.bool,
    deductionTypes: PropTypes.array.isRequired,
};

export default ContractSummaryForm;
export { ContractSummaryFormFields };
