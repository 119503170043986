import React, { Component } from 'react';
import { Show, ListButton } from 'admin-on-rest';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import FlatButton from 'material-ui/FlatButton';
import SharedFields from './shared';
import { raiseRequestDownload, raiseRequestEmail } from '../actions';
import Button from '../components/button';
import { fetchEmailTransaction } from '../actions';

import { CardActions } from 'material-ui/Card';
import { Link } from 'react-router-dom';
import EmailIcon from 'material-ui/svg-icons/communication/email';
import DownloadIcon from 'material-ui/svg-icons/file/file-download';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import moment from 'moment';
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn,
} from 'material-ui/Table';

import styles from '../styles';

const formName = 'record-form';
const RenderDownloadButton = connect(null, { onClick: raiseRequestDownload })(Button);
const RenderSendEmailButton = connect(
    (state, ownProps) => ({ ...ownProps, loading: state.contract.sendingEmail }),
    {
        onClick: props => raiseRequestEmail({ id: props.id }),
    },
)(Button);
const PostShowActions = ({ basePath, data, refresh }) => (
  <CardActions style={styles.cardAction}>
    <div>
      {data && (
        <RenderDownloadButton
          icon={<DownloadIcon />}
          record={{ id: data.id }}
          label="Download"
        />
            )}
      {data &&
                data.manager &&
                data.manager.email && (
                <RenderSendEmailButton
                  icon={<EmailIcon />}
                  record={{ id: data.id }}
                  label={`Send to ${data.manager.email}`}
                />
                )}
      <ListButton basePath={basePath} style={styles.cardActionItem} />
      {data && (
        <FlatButton
          label="Delete"
          icon={<DeleteIcon />}
          labelPosition="after"
          secondary
          style={{ overflow: 'inherit' }}
          containerElement={<Link to={{ pathname: `/raiserequest/${data.id}/delete` }} />}
        />
            )}
    </div>
  </CardActions>
);

function GenerateRow(item) {
    const { id, email, created_at } = item;
    return (
      <TableRow key={id}>
        <TableRowColumn>{id}</TableRowColumn>
        <TableRowColumn>{email}</TableRowColumn>
        <TableRowColumn>{moment(created_at).format('M/D/YYYY')}</TableRowColumn>
      </TableRow>
    );
}
function EmailHistory(props) {
    const items = [];
    if (props && props.data) {
        const { id, data } = props;
        const keys = Object.keys(data);
        keys.map((key) => {
            const value = data[key];
            if (value.raise_request_id == id) {
                items.push(value);
            }
        });
    }

    return (
      <Table>
        <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
          <TableRow>
            <TableHeaderColumn>ID</TableHeaderColumn>
            <TableHeaderColumn>TO</TableHeaderColumn>
            <TableHeaderColumn>TIME SENT</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false}>{items.map(item => GenerateRow(item))}</TableBody>
      </Table>
    );
}
class Contact extends Component {
    componentDidMount() {
        const { id } = this.props.initialValues;
        const { fetchEmailTransaction } = this.props.actions;
        fetchEmailTransaction({
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'created_at', order: 'ASC' },
            filter: { raise_request_id: id },
        });
    }
    render() {
        const { emailtransaction } = this.props;
        const { id } = this.props.initialValues;
        return (
          <div className="field">
            <div>
              <SharedFields {...this.props} disabled />
              <div style={{ ...styles.row }} />
              <div style={{ ...styles.row }} />
              <div>
                <div style={{ ...styles.rowContainer }}>
                  <label>Emails sent</label>
                </div>
                <EmailHistory data={emailtransaction} id={id} />
              </div>
            </div>
          </div>
        );
    }
}

let CreateReduxForm = reduxForm({
    form: formName, // <------ same form name
    destroyOnUnmount: true, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(Contact);

function mapStateToProps(state, thisprops) {
    return {
        emailtransaction: state.admin.resources.emailtransaction.data,
        initialValues: thisprops.record,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                fetchEmailTransaction,
            },
            dispatch,
        ),
    };
}

CreateReduxForm = connect(mapStateToProps, mapDispatchToProps)(CreateReduxForm);

function EnhancedContainer(props) {
    const { save, record } = props;
    return (
      <div>
        <CreateReduxForm save={save} record={record} />
      </div>
    );
}

function RaiseRequestShow(props) {
    return (
      <Show title="Raise request" {...props} actions={<PostShowActions />}>
        <EnhancedContainer />
      </Show>
    );
}

export default RaiseRequestShow;
