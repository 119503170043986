import React from 'react';
import {
    BooleanField,
    List,
    ShowButton,
    EditButton,
    Datagrid,
    TextField,
    NullableBooleanInput,
    Filter,
    SimpleList,
    Responsive,
} from 'admin-on-rest';
import checkAccess from '../util/acl';

const PostFilter = props => (
  <Filter {...props}>
    <NullableBooleanInput
      source="enabled"
      choices={[{ id: 'true', name: 'Yes' }, { id: 'false', name: 'No' }]}
    />
  </Filter>
);
const ListComponent = props => (
  <div className="list-wrapper">
    <List title="Jobs" {...props} perPage={10} filters={<PostFilter />}>
      {permissions => (
        <Responsive
          small={
            <SimpleList
              primaryText={record => record.project_name}
              secondaryText={record => record.enabled ? 'Enabled' : 'Disabled'}
              // tertiaryText={record => record.phone}
            />  
          }
          medium={
            <Datagrid>
              <TextField source="id" />
              <TextField source="project_name" />
              <BooleanField label="Enabled" source="enabled" />
              <ShowButton />
              {checkAccess('job_write', permissions) && <EditButton />}
            </Datagrid>
          }
        />
        
      )}
    </List>
  </div>
);
export default ListComponent;
