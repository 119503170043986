import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'material-ui/Checkbox';

class EnableCheckbox extends Component {
    constructor(props) {
        super(props);
        this.toggleEnable = this.toggleEnable.bind(this);
    }
    toggleEnable() {
        const { record } = this.props;
        this.props.enableAction(record.id, record);
    }
    render() {
        return <Checkbox onCheck={this.toggleEnable} checked={this.props.record.enabled} />;
    }
}

EnableCheckbox.propTypes = {
    record: PropTypes.object.isRequired,
    enableAction: PropTypes.func.isRequired,
};

export default EnableCheckbox;
