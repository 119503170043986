import { call, put, takeEvery, all } from 'redux-saga/effects';
import { showNotification } from 'admin-on-rest';
import {
    RAISE_REQUEST_EMAIL,
    raiseRequestEmailStarted,
    raiseRequestEmailFinished,
    fetchEmailTransaction,
} from '../actions';
import client, { baseUrl } from '../client';

const raiseRequestEmailAPI = function (id) {
    const token = localStorage.getItem('token');
    return fetch(`${baseUrl}/raiserequestemail`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/json',
        },
        body: JSON.stringify({ id }),
    }).then((response) => {
        if (response.status < 200 || response.status >= 300) {
            return response.json().then((data) => {
                throw new Error(data.message);
            });
        }
        return response.json().then(data => data.message);
    });
};

function* raiseRequestEmail({ id }) {
    try {
        yield put(raiseRequestEmailStarted());
        const message = yield call(raiseRequestEmailAPI, id);
        yield put(showNotification(message));
    } catch (err) {
        yield put(showNotification(err.message));
    }
    yield put(raiseRequestEmailFinished());
    yield put(
        fetchEmailTransaction({
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'created_at', order: 'ASC' },
            filter: { raise_request_id: id },
        }),
    );
}

export function* raiseRequestEmailSaga() {
    yield all([takeEvery(RAISE_REQUEST_EMAIL, raiseRequestEmail)]);
}
