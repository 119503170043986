import React, { Component } from 'react';
import { Show } from 'admin-on-rest';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import TokensList from './tokens_supported';
import RenderField, { RenderEditor, RenderLongTextInput } from '../shared/render_field';
import { required } from '../util/validate';

class ContrateTemplateForm extends Component {
    render() {
        return (
          <form className="simple-form">
            <div className="field">
              <Field
                name="name"
                type="text"
                className="field"
                component={RenderField}
                readOnly
                validate={[required]}
                floatingLabelText="Name"
              />
              <h4>Tokens Supported:</h4>
              <div>
                <TokensList />
              </div>
              <div>
                <Field name="content" disabled component={RenderEditor} />
              </div>
              <Field
                name="footer"
                component={RenderLongTextInput}
                validate={[required]}
                label="Footer (use HTML)"
              />
            </div>
          </form>
        );
    }
}
const formName = 'record-form';

function mapStateToProps(state, thisprops) {
    const { name, content, footer } = thisprops.record;
    return {
        save: thisprops.save,
        initialValues: {
            name,
            content,
            footer,
        },
    };
}

ContrateTemplateForm = reduxForm({
    form: formName, // <------ same form name
    destroyOnUnmount: true, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(ContrateTemplateForm);

ContrateTemplateForm = connect(mapStateToProps)(ContrateTemplateForm);

class EnhancedContainer extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { record } = this.props;
        return (
          <div>
            <ContrateTemplateForm record={record} />
          </div>
        );
    }
}

class ShowForm extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
          <Show title="Contract template" {...this.props}>
            <EnhancedContainer />
          </Show>
        );
    }
}
export default ShowForm;
