import { call, put, takeEvery, all } from 'redux-saga/effects';
import { CHECK_ACTIVATE_TOKEN, logOutManually, loginManually } from '../actions/';
import { push } from 'react-router-redux';
import { showNotification, userLogout } from 'admin-on-rest';
import { fetchToken } from '../api';
import { sleep } from '../util/sleep';
import { LogUserOut, LogUserIn } from '../auth';

function* checkActivateToken({ token: activationToken }) {
    try {
        yield put(logOutManually());
        const tokenResponse = yield call(fetchToken, activationToken);
        const { token, roles, email } = tokenResponse.data;
        yield put(loginManually(token, roles));
        localStorage.setItem('username', email);
        yield put(showNotification('Account activated'));
        yield sleep(2000);
        yield put(push('/passwordreset?logoff=true'));
    } catch (err) {
        yield put(showNotification(err.message));
    }
}

export function* activateSaga() {
    yield all([takeEvery(CHECK_ACTIVATE_TOKEN, checkActivateToken)]);
}
