import React from 'react';
import { Route } from 'react-router-dom';
import Activate from './account/activate';
import PasswordReset from './account/password_reset';
import PasswordForgot from './account/password_forgot';
import Logout from './account/logout';

export default [
    <Route exact path="/activate" component={Activate} />,
    <Route exact path="/passwordreset" component={PasswordReset} />,
    <Route exact path="/passwordforgot" component={PasswordForgot} />,
    <Route exact path="/logout" component={Logout} />,
];
