import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { getOneJobSetup } from '../../actions';
import client, { baseUrl } from '../../client';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import SearchUsers from './searchUsers';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function JobUsers({ data, dispatch }) {
  const classes = useStyles();

  const users = data.users || [];
  const [isSending, setIsSending] = useState(false);

  const onManagerSearchChange = async (e, oclmuser) => {
    console.log('oclmuser', oclmuser);
    if (!oclmuser) {
      return;
    }
    const token = localStorage.getItem('token');
    const url = `${baseUrl}/jobsetup/${data.id}/user/${oclmuser.id}`;
    await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }).then(response => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      dispatch(
        getOneJobSetup({
          id: data.id,
        })
      );

      setIsSending(false);
      return response.json();
    });
  };

  const onRemove = async (e, userId) => {
    e.preventDefault();

    if (isSending) return;

    setIsSending(true);

    const token = localStorage.getItem('token');
    const url = `${baseUrl}/jobsetup/${data.id}/user/${userId}`;
    await fetch(url, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }).then(response => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      dispatch(
        getOneJobSetup({
          id: data.id,
        })
      );

      setIsSending(false);
      return response.json();
    });
  };
  return (
    <div>
      <SearchUsers onChange={onManagerSearchChange} />
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Full Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(row => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.fullName}
                </TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={e => onRemove(e, row.id)}
                    disabled={isSending}
                  >
                    Remove
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default connect()(JobUsers);
