import React from 'react';
import {
  List,
  BooleanField,
  ShowButton,
  Filter,
  TextInput,
  EditButton,
  NullableBooleanInput,
  Datagrid,
  EmailField,
  TextField,
  CreateButton,
  SelectInput,
  SimpleList,
  Responsive,
  FunctionField,
} from 'admin-on-rest';
import { CardActions } from 'material-ui/Card';
import { getOfficeNameByOfficeId } from './shared/offices';
import EnableUserCheckbox from './enable_user';
import checkAccess from '../util/acl';
import offices from './shared/offices';

import * as display from './display/displayFunctions';

const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};

const PostFilter = props => (
  <Filter {...props}>
    <TextInput label="First name" source="firstName" />
    <TextInput label="Last name" source="lastName" />
    <TextInput label="Email" source="email" />
    <NullableBooleanInput
      source="enabled"
      choices={[
        { id: 'true', name: 'Yes' },
        { id: 'false', name: 'No' },
      ]}
    />
    <SelectInput source="office" choices={offices} />
    <NullableBooleanInput
      source="is_supervisor"
      choices={[
        { id: 'true', name: 'Yes' },
        { id: 'false', name: 'No' },
      ]}
    />
  </Filter>
);

const ListActions = props => {
  const { basePath, data, refresh } = props;
  return (
    <CardActions style={cardActionStyle}>
      <CreateButton basePath={basePath} record={data} />
    </CardActions>
  );
};

const OfficeField = ({ source, record = {} }) => (
  <span>{getOfficeNameByOfficeId(record[source])}</span>
);

const dataGridStyles = {
  table: {},
  tbody: {},
  tr: {},
  header: {
    th: {},
    'th:first-child': {
      width: '30px',
    },
  },
  cell: {
    td: {
      margin: '0.24rem 0',
    },
    'td:first-child': {
      width: '30px',
    },
  },
};

export const UserList = props => (
  <div className="list-wrapper">
    <List {...props} title="Users" perPage={10} filters={<PostFilter />}>
      {permissions => (
        <Responsive
          small={
            <SimpleList
              primaryText={record => record.firstName + record.lastName}
              secondaryText={record => record.email}
            />
          }
          medium={
            <Datagrid styles={dataGridStyles}>
              <TextField source="id" />
              <FunctionField label="Name" render={display.renderUser} />
              {/* <OfficeField label="office" source="office" /> */}
              {/* <BooleanField label="Supervisor" source="is_supervisor" /> */}
              {/* <BooleanField label="Administrator" source="roleAdministrator" /> */}
              <BooleanField label="Activated" source="activated" />
              {checkAccess('user_write', permissions) && (
                <EnableUserCheckbox source="enabled" sortable={false} />
              )}
              <ShowButton />
              {checkAccess('user_write', permissions) && <EditButton />}
            </Datagrid>
          }
        />
      )}
    </List>
  </div>
);
