import { call, put, takeEvery, all } from 'redux-saga/effects';
import { RAISE_REQUEST_DOWNLOAD } from '../actions';
import { showNotification } from 'admin-on-rest';
import { baseUrl } from '../client';

const raiseRequestDownloadAPI = function (id) {
    const token = localStorage.getItem('token');
    return fetch(`${baseUrl}/raiserequestdownload/${id}`, {
        method: 'get',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return response.blob();
        })
        .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'raiserequest.pdf';
            a.click();
        });
};

function* raiseRequestDownload({ id }) {
    try {
        yield call(raiseRequestDownloadAPI, id);
    } catch (err) {
        yield put(showNotification(err.message));
    }
}

export function* raiseRequestDownloadSaga() {
    yield all([takeEvery(RAISE_REQUEST_DOWNLOAD, raiseRequestDownload)]);
}
