import { getCookieValue, deleteCookie } from './util/cookie';

const getQueryString = function (field, url) {
    const href = url || window.location.href;
    const reg = new RegExp(`[?&]${field}=([^&#]*)`, 'i');
    const string = reg.exec(href);
    return string ? string[1] : null;
};

export const AuthCheck = () => {
    try {
        const user = getCookieValue('user');
        if (user) {
            const userParsed = JSON.parse(user);
            const { token, roles } = userParsed;
            // Log in user if they didn't login from this app
            // This will pull the token and roles from a cookie
            // set by another app on a subdomain
            if (!localStorage.getItem('token')) {
                localStorage.setItem('token', token);
                localStorage.setItem('roles', JSON.stringify(roles));
            }
            return true;
        }
        return false;
    } catch (err) {
        console.error('error parsing cookie');
        return false;
    }
};

export const LogUserOut = () => {
    localStorage.clear();
    deleteCookie('user', '/', window.COOKIE_DOMAIN);
    deleteCookie('token', '/', window.COOKIE_DOMAIN);
};

export const LogUserIn = (token, roles) => {
    localStorage.setItem('token', token);
    localStorage.setItem('roles', JSON.stringify(roles));

    const redirect_to = getQueryString('redirect_to');
    if (redirect_to) {
        window.location.href = redirect_to;
    }
};
