import { CREATE, GET_ONE, GET_LIST } from 'admin-on-rest';

export const CREATE_CONTRACT = 'CREATE_CONTRACT';
export const createContract = data => ({
    type: CREATE_CONTRACT,
    payload: { data: { ...data } },
    meta: { resource: 'contract', fetch: CREATE, cancelPrevious: false },
});

export const CONTRACT_DOWNLOAD = 'CONTRACT_DOWNLOAD';
export const contractDownload = ({ id }) => ({
    type: CONTRACT_DOWNLOAD,
    id,
});
export const CONTRACT_DOWNLOAD_STARTED = 'CONTRACT_DOWNLOAD_STARTED';
export const contractDownloadStarted = () => ({
    type: CONTRACT_DOWNLOAD_STARTED,
});

export const CONTRACT_DOWNLOAD_FINISHED = 'CONTRACT_DOWNLOAD_FINISHED';
export const contractDownloadFinished = () => ({
    type: CONTRACT_DOWNLOAD_FINISHED,
});

export const CONTRACT_EMAIL = 'CONTRACT_EMAIL';
export const contractEmail = ({ id }) => ({
    type: CONTRACT_EMAIL,
    id,
});

export const CONTRACT_EMAIL_STARTED = 'CONTRACT_EMAIL_STARTED';
export const contractEmailStarted = () => ({
    type: CONTRACT_EMAIL_STARTED,
});

export const CONTRACT_EMAIL_FINISHED = 'CONTRACT_EMAIL_FINISHED';
export const contractEmailFinished = () => ({
    type: CONTRACT_EMAIL_FINISHED,
});
