import React from 'react';

import './SearchBar.css';

export default class SearchBar extends React.Component {
  state = {
    text: '',
  };

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleClick = e => {
    if (this.state.text === '') {
      alert('You need to enter some text');
      return;
    }

    e.preventDefault();

    window.location.href = `${
      process.env.REACT_APP_QUOTES_APP
    }/dashboard/search?query=${encodeURIComponent(this.state.text)}`;
    return;
  };

  render() {
    const { text } = this.state;
    return (
      <form className="SearchBar--form" onSubmit={this.handleClick}>
        <div className="SearchBar--text">
          <div className="semantic--ui-fluid-input">
            <input
              onChange={this.handleChange}
              type="text"
              name="text"
              value={text}
              placeholder="Enter a Quote, Job Name, Description, etc...."
            />
          </div>
        </div>
        <div className="SearchBar--button">
          <button
            type="submit"
            className="semantic--ui-primary-button"
            role="button"
          >
            Search
          </button>
        </div>
      </form>
    );
  }
}
