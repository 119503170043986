import { UPDATE, GET_LIST, CRUD_GET_LIST } from 'admin-on-rest';

export const ENABLE_PRICING_GROUP = 'ENABLE_PRICING_GROUP';
export const enablePricingGroup = (id, data, basePath) => ({
    type: ENABLE_PRICING_GROUP,
    payload: { id, data: { ...data, enabled: !data.enabled } },
    meta: { resource: 'pricinggroup', fetch: UPDATE, cancelPrevious: false },
});

export const fetchPricingGroups = data => ({
    type: CRUD_GET_LIST,
    payload: { ...data },
    meta: { resource: 'pricinggroup', fetch: GET_LIST, cancelPrevious: false },
});
