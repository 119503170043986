import React from 'react';
import { Edit } from 'admin-on-rest';

import UserContainer from './shared/form';

const UserEdit = props => (
  <Edit {...props}>
    <UserContainer isEditing />
  </Edit>
);

export { UserEdit };
