import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { RenderSelect } from '../../shared/render_field';
import { GetListForSelect } from '../../util/parse';

const ManagementInfoFormFields = {
    management_id: 'management_id',
    manager_id: 'manager_id',
    preferred_method_of_contact_id: 'preferred_method_of_contact_id',
};

const ManagementInfoForm = (props) => {
    const { disabled, management, managers, onChangeManagement } = props;
    const propsForPreferredContactMethodSelect = {
        floatingLabelText: 'Preferred Method of Contact',
        items: [
            {
                id: 2,
                text: 'Email',
            },
            {
                id: 1,
                text: 'Phone',
            },
            {
                id: 3,
                text: 'SMS',
            },
        ],
    };
    const propsForMangementSelect = GetListForSelect(
        management,
        'Management',
        'id',
        'company_name',
        true,
    );
    const propsForManagersSelect = GetListForSelect(managers, 'Manager', 'id', 'name', true);
    return (
      <div>
        <Field
          name={ManagementInfoFormFields.management_id}
          props={propsForMangementSelect}
          disabled={disabled}
          component={RenderSelect}
          onChange={onChangeManagement}
          allowEmpty
        />
        <Field
          name={ManagementInfoFormFields.manager_id}
          props={propsForManagersSelect}
          disabled={disabled}
          component={RenderSelect}
          allowEmpty
        />
        <Field
          name={ManagementInfoFormFields.preferred_method_of_contact_id}
          props={propsForPreferredContactMethodSelect}
          disabled={disabled}
          component={RenderSelect}
        />
      </div>
    );
};

ManagementInfoForm.defaultProps = {
    disabled: false,
};
ManagementInfoForm.propTypes = {
    disabled: PropTypes.bool,
    management: PropTypes.array,
    managers: PropTypes.array.isRequired,
    onChangeManagement: PropTypes.func.isRequired,
};
export default ManagementInfoForm;
export { ManagementInfoFormFields };
