import querystring from "querystring";
import { GET_ONE } from 'admin-on-rest';
import restClient, { baseUrl } from '../client';

export const required = (value) => {
    if (value === undefined || value === null) {
        return 'Required';
    }
    return undefined;
};

export const positiveNumber = (value) => {
    if (value === null || value === undefined) {
        return undefined;
    }
    if (parseInt(value) > 0) {
        return undefined;
    }
    return 'Number must be greater than 0';
};

export const number = value => (value && isNaN(Number(value)) ? 'Must be a number' : undefined);

export const checkIfManagerEmailExists = (values, dispatch, props) => {
    const token = localStorage.getItem('token');
    const qb = {
        filter: JSON.stringify({
            email: values.email
        })
      };

    const query = querystring.stringify(qb);
    const url = `${baseUrl}/manager?${query}`;

    return fetch(url, {
        method: 'get',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return response.json();
        })
        .then((data) => {
            if (data.length > 0) {
                return { email: 'That email is taken' };
            }
        })
        .catch((err) => {});
};


export const checkIfEmailExists = (values, dispatch, props) => {
    const { isEditing } = props;
    const token = localStorage.getItem('token');
    const url = values.id
        ? `${baseUrl}/email/${values.email}?id=${values.id}`
        : `${baseUrl}/email/${values.email}`;

    if (isEditing) {
        return Promise.resolve(true);
    }

    return fetch(url, {
        method: 'get',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return response.json();
        })
        .then((data) => {
            if (data.exists) {
                return { email: 'That email is taken' };
            }
        })
        .catch((err) => {});
};
