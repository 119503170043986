import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { RenderLongTextInput } from '../../shared/render_field';

const AdditionalNotesFormFields = {
    additional_notes: 'additional_notes',
};

const AdditionalNotesForm = (props) => {
    const { disabled } = props;
    return (
      <Field
        name={AdditionalNotesFormFields.additional_notes}
        type="text"
        component={RenderLongTextInput}
        disabled={disabled}
        label="Additional Notes"
      />
    );
};
AdditionalNotesForm.defaultProps = {
    disabled: false,
};
AdditionalNotesForm.propTypes = {
    disabled: PropTypes.bool,
};
export default AdditionalNotesForm;
export { AdditionalNotesFormFields };
