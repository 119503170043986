import { call, put, takeLatest, all } from 'redux-saga/effects';
import { showNotification } from 'admin-on-rest';
import { SEARCH, searchStarted, searchFinished } from '../actions';
import { baseUrl } from '../client';

const searchAPI = function (query) {
    const token = localStorage.getItem('token');
    return fetch(`${baseUrl}/search?query=${query}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/json',
        },
    }).then((response) => {
        if (response.status < 200 || response.status >= 300) {
            return response.json().then((data) => {
                throw new Error(data.message);
            });
        }
        return response.json();
    });
};

function* search({ query }) {
    try {
        yield put(searchStarted());
        const data = yield call(searchAPI, query);
        yield put(searchFinished(data));
    } catch (err) {
        yield put(showNotification(err.message));
    }
}

export function* searchSaga() {
    yield all([takeLatest(SEARCH, search)]);
}
