export default function getAcceptRejectText(acceptRejected, emailSentCount) {
    if (acceptRejected === null || acceptRejected === undefined) {
        if (emailSentCount > 0) {
            return 'Awaiting approval';
        }
        return 'In progress';
    }
    if (acceptRejected) {
        return 'Accepted';
    }
    return 'Rejected';
}
