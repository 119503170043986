import React from 'react';
import {
    BooleanField,
    List,
    ShowButton,
    EditButton,
    Datagrid,
    TextField,
    TextInput,
    NullableBooleanInput,
    Filter,
    FunctionField,
    SimpleList,
    Responsive,
} from 'admin-on-rest';
import LinkToRelatedManagers from './linkToRelatedManagers';
import EnableManagementInfoCheckbox from './enable_management_info';
import checkAccess from '../util/acl';

const PostFilter = props => (
  <Filter {...props}>
    <TextInput label="Company Name" source="company_name" />
    <TextInput label="City" source="city" />
    <TextInput label="Zip" source="zip" />
    <NullableBooleanInput
      source="is_third_party"
      choices={[{ id: 'true', name: 'Yes' }, { id: 'false', name: 'No' }]}
    />
  </Filter>
);

const ListComponent = props => (
  <div className="list-wrapper">
    <List title="Management Companies" {...props} perPage={30} filters={<PostFilter />}>
      {permissions => (
        <Responsive
          small={
            <SimpleList
              primaryText={record => record.company_name}
              secondaryText={record => `${record.street_address} ${record.city}, ${record.zip} `}
              // tertiaryText={record => record.phone}
            />
          }
          medium={
            <Datagrid>
              <TextField source="id" />
              <TextField source="company_name" />
              <TextField source="street_address" />
              <TextField source="city" />
              <TextField source="zip" />
              <BooleanField source="is_third_party" />
              <LinkToRelatedManagers />
              {checkAccess('management_write', permissions) &&
                            false && <EnableManagementInfoCheckbox />}

              <ShowButton />
              {checkAccess('management_write', permissions) && <EditButton />}
            </Datagrid>
          }
        />
          
        )}
    </List>
  </div>
);
export default ListComponent;
