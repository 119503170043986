import React from 'react';

const TokensList = () => (
  <ul>
    <li>{'{{page_break}} - Puts a page break in pdf'}</li>
    <li>
      {'{{effective_date}}'} - Effective Date of contract (earlier of contract date generated
            or job start date)
        </li>
    <li>{'{{job_name}}'} - Name of project, comes from job</li>
    <li>{'{{legal_name}}'} - Legal name, comes from job</li>
    <li>{'{{legal_name_capitalized}}'} - Legal name (capitalized), comes from job</li>
    <li>{'{{monthly_price}}'} - Monthly price, comes from contract</li>
    <li>{'{{term_start_date}}'} - Job start date, comes from job</li>
    <li>{'{{term_end_date}}'} - Term end date, comes from contract</li>
    <li>
      {'{{notice_to_address}}'} - Notice to association (C/O Management or Attn Board of
            directors)
        </li>
    <li>{'{{exhibit_a}}'} - Property map, uploaded on contract</li>
    <li>{'{{exhibit_b}}'} - Scope of work, WYSWYG on contract</li>
    <li>{'{{exhibit_c}}'} - Image uploaded on contract</li>
    <li>{'{{pricing_group}}'} - Pricing group from Job</li>
    <li>{'{{labor_rate}}'} - Labor rate from Job</li>
  </ul>
);

export default TokensList;
