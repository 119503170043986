import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RaisedButton from 'material-ui/RaisedButton';

class Button extends Component {
    constructor(props) {
        super(props);
        this.onClick = this.onClick.bind(this);
    }
    onClick(record) {
        this.props.onClick(record);
    }
    render() {
        const { record, loading } = this.props;

        return (
          <RaisedButton
            icon={this.props.icon}
            label={this.props.label}
            disabled={loading}
            disableTouchRipple
            disableFocusRipple
            onClick={() => this.onClick(record)}
            primary
            style={{ marginRight: 12 }}
          />
        );
    }
}

Button.defaultProps = {
    loading: false,
};
Button.propTypes = {
    record: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    loading: PropTypes.bool,
};

export default Button;
