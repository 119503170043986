import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Boxed from '../shared/collapse_box';

const RenderCard = (props) => {
    const { title, fields, errors, submitFailed, children, initiallyExpanded } = props;
    const titleToDisplay = title;
    let titleStyle = {};
    if (errors && submitFailed) {
        const errorKeys = Object.keys(errors);
        const fieldKeys = Object.keys(fields);
        const errorsOccured = _.intersection(errorKeys, fieldKeys);
        if (errorsOccured && errorsOccured.length > 0) {
            titleStyle = { backgroundColor: '#f8d7da' };
        }
    }
    return (
      <Boxed
        title={titleToDisplay}
        titleStyle={titleStyle}
        expandable
        initiallyExpanded={initiallyExpanded}
      >
        {children}
      </Boxed>
    );
};
RenderCard.defaultProps = {
    initiallyExpanded: false,
};
RenderCard.propTypes = {
    initiallyExpanded: PropTypes.bool,
    title: PropTypes.string.isRequired,
    fields: PropTypes.object.isRequired,
    errors: PropTypes.object,
    submitFailed: PropTypes.bool,
    children: PropTypes.node.isRequired,
};
export default RenderCard;
