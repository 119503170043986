import React, { Component } from 'react';
import { ViewTitle } from 'admin-on-rest';
import { Card } from 'material-ui/Card';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { bindActionCreators } from 'redux';
import { passwordForgot } from '../actions';
import { RaisedButton, CircularProgress, CardActions, TextField } from 'material-ui';
import compose from 'recompose/compose';

const renderInput = ({ meta: { touched, error } = {}, input: { ...inputProps }, ...props }) =>
    <TextField
        errorText={touched && error}
        {...inputProps}
        {...props}
    />;

var translate = function (key) {
    return key;
}

const formStyle = { padding: '0 1em 1em 1em' };
class Activate extends Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this);
    }
    onSave(val) {
        if (val.email) {
            this.props.actions.passwordForgot(val.email);
        }
        return;
    }
    render() {
        const { handleSubmit, loading } = this.props;
        return (
            <Card>
                <ViewTitle title="Forgot your password" />
                <form onSubmit={handleSubmit(this.onSave)}>
                    <div style={formStyle}>
                        <div>
                            <Field
                                name="email"
                                component={renderInput}
                                floatingLabelText={"Email"}
                                disabled={this.props.loading}
                            />
                        </div>
                    </div>
                    <CardActions>
                        <RaisedButton
                            type="submit"
                            primary
                            disabled={loading}
                            icon={loading && <CircularProgress size={25} thickness={2} />}
                            label={"Send"}
                        />
                    </CardActions>
                </form>
            </Card>
        );
    }
}
function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators({ passwordForgot }, dispatch) };
}
function mapStateToProps(state) {
    return {
        loading: state.login.passwordForgotLoading,
    };
}


const enhance = compose(
    translate,
    reduxForm({
        form: 'passwordReset',
        validate: (values) => {
            const errors = {};
            if (!values.email) errors.email = 'Email Required';
            return errors;
        },
    }),
    connect(mapStateToProps, mapDispatchToProps),
);
export default enhance(Activate);

