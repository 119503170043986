export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';
export const LOGIN_STARTED = 'LOGIN_STARTED';
export const LOGIN_FINISHED = 'LOGIN_FINISHED';
export const LOGOUT_MANUALLY = 'LOGOUT_MANUALLY';
export const LOGIN_MANUALLY = 'LOGIN_MANUALLY';

export const loginStarted = () => ({
    type: LOGIN_STARTED,
});

export const loginFinished = () => ({
    type: LOGIN_FINISHED,
});

export const loggedIn = () => ({
    type: LOGGED_IN,
});

export const loggedOut = () => ({
    type: LOGGED_OUT,
});

export const logOutManually = () => ({
    type: LOGOUT_MANUALLY,
});

export const loginManually = (token, roles) => ({
    type: LOGIN_MANUALLY,
    token,
    roles,
});
