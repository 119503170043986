import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { RenderSelect } from '../../shared/render_field';
import moment from 'moment';

const PricingGroupFormFields = {
    pricing_group_id: 'pricing_group_id',
};
const PricingGroupForm = (props) => {
    const { disabled, pricingGroups } = props;
    const keys = Object.keys(pricingGroups);
    const pricingGroupItems = keys.map((key) => {
        const item = pricingGroups[key];
        const validStartDate = moment(item.valid_start_date).format('M/D/YYYY');
        return {
            id: item.id,
            text: `${item.name} (Valid as of ${validStartDate})`,
        };
    });
    const propsForPricingGroupSelect = {
        floatingLabelText: 'Pricing Group',
        items: pricingGroupItems,
    };
    return (
      <div>
        <Field
          name={PricingGroupFormFields.pricing_group_id}
          disabled={disabled}
          props={propsForPricingGroupSelect}
          component={RenderSelect}
        />
      </div>
    );
};
PricingGroupForm.defaultProps = {
    disabled: false,
};
PricingGroupForm.propTypes = {
    disabled: PropTypes.bool,
    pricingGroups: PropTypes.array,
};
export default PricingGroupForm;
export { PricingGroupFormFields };
