const acl = {
    user_read: ['roleAdministrator', 'user_read'],
    user_delete: ['roleAdministrator'],
    user_write: ['roleAdministrator', 'user_write'],
    pricing_group_read: ['roleAdministrator', 'pricing_group_read'],
    pricing_group_write: ['roleAdministrator', 'pricing_group_write'],
    labor_rate_read: ['roleAdministrator', 'labor_rate_read'],
    labor_rate_write: ['roleAdministrator', 'labor_rate_write'],
    management_read: ['roleAdministrator', 'management_read'],
    management_write: ['roleAdministrator', 'management_write'],
    manager_read: ['roleAdministrator', 'manager_read'],
    manager_write: ['roleAdministrator', 'manager_write'],
    job_read: ['roleAdministrator', 'job_read'],
    job_write: ['roleAdministrator', 'job_write'],
    contract_template_read: ['roleAdministrator', 'contract_template_read'],
    contract_template_write: ['roleAdministrator', 'contract_template_write'],
    contract_read: ['roleAdministrator', 'contract_read'],
    contract_write: ['roleAdministrator', 'contract_write'],
    raise_request_template_read: ['roleAdministrator', 'raise_request_template_read'],
    raise_request_template_write: ['roleAdministrator', 'raise_request_template_write'],
    raise_request_read: ['roleAdministrator', 'raise_request_read'],
    raise_request_write: ['roleAdministrator', 'raise_request_write'],
};
function checkAccess(resource, roles) {
    if (!roles) {
        return false;
    }
    if (acl[resource]) {
        const aclForResource = acl[resource];

        for (let i = 0; i < roles.length; i++) {
            const role = roles[i];
            if (aclForResource.indexOf(role) > -1) {
                return true;
            }
        }
        return false;
    }
    return false;
}

export default checkAccess;
