import React, { Component } from 'react';
import Divider from 'material-ui/Divider';
import Subheader from 'material-ui/Subheader';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import { Toolbar, ToolbarGroup } from 'material-ui/Toolbar';
import numeral from 'numeral';
import { connect } from 'react-redux';
import keyBy from 'lodash/keyBy';
import {
    Field,
    reduxForm,
    hasSubmitFailed,
    getFormSyncErrors,
    getFormValues,
    change,
} from 'redux-form';
import { bindActionCreators } from 'redux';
import { Decimal } from 'decimal.js';
import SaveButton from '../components/savebutton';
import {
    fetchRaiseRequestTemplates,
    fetchJobSetups,
    fetchManagement,
    fetchManager,
} from '../actions';
import { GetListForSelect } from '../util/parse';
import RenderField, {
    RenderSelect,
    RenderDatePicker,
    RenderCurrency,
    RenderPercentage,
} from '../shared/render_field';
import '../styles/form.css';
import styles from '../styles';
import { denormalize } from '../util/normalizr';

function getnewMonthlyContractAmount(currentMonthlyContractAmount, increase, type) {
    const decCurrentMonthlyContractAmount = new Decimal(currentMonthlyContractAmount);
    const decIncrease = new Decimal(increase);
    if (type === 'percent') {
        const newMonthlyAmount = decIncrease
            .dividedBy(new Decimal(100))
            .plus(new Decimal(1))
            .times(decCurrentMonthlyContractAmount);
        return newMonthlyAmount.toFixed(2);
    }
    return decCurrentMonthlyContractAmount.plus(decIncrease).toFixed(2);
}
const formName = 'record-form';
class SharedForm extends Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this);
        this.onJobChange = this.onJobChange.bind(this);
        this.handIncreasePercent = this.handIncreasePercent.bind(this);
        this.handleIncrease = this.handleIncrease.bind(this);
    }
    componentDidMount() {
        this.props.actions.fetchRaiseRequestTemplates({
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'name', order: 'ASC' },
            filter: {},
        });
        this.props.actions.fetchJobSetups({
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'project_name', order: 'ASC' },
            filter: {},
        });
        this.props.actions.fetchManagement({
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'company_name', order: 'ASC' },
            filter: {},
        });
        this.props.actions.fetchManager({
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'name', order: 'ASC' },
            filter: {},
        });
    }
    onSave(formData) {
        this.props.save(formData, 'show');
    }
    handIncreasePercent(event, newValue) {
        const { change } = this.props.actions;
        const { formValues } = this.props;

        if (newValue === '') {
            change(formName, 'increase', null);
            return;
        }
        change(formName, 'increase', null);
    }
    handleIncrease(event, newValue) {
        const { change } = this.props.actions;
        change(formName, 'increase_by_percent', null);
    }

    onJobChange(event, newValue) {
        const job_setup_id = newValue;
        const { change } = this.props.actions;
        const { jobSetups } = this.props;
        const jobSetupKeys = keyBy(jobSetups, 'id');
        // const jobSetupFiltered = jobSetupKeys.filter(key => key == job_setup_id);
        const jobSetup = jobSetupKeys[job_setup_id];
        
        if (jobSetup) {
            change(formName, 'current_monthly_contract_amount', jobSetup.total_price);
            // if (jobSetup.total_price !== null && jobSetup.total_price !== undefined) {
            //     change(formName, 'current_monthly_contract_amount', jobSetup.total_price);
            // }
            if (jobSetup.management_id) {
                change(formName, 'management_id', jobSetup.management_id);
            } else {
                change(formName, 'management_id', null);
            }
            if (jobSetup.manager_id) {
                change(formName, 'manager_id', jobSetup.manager_id);
            } else {
                change(formName, 'manager_id', null);
            }
        }
    }
    render() {
        const {
            save,
            handleSubmit,
            submitFailed,
            errors,
            submitting,
            jobSetups,
            management,
            manager,
            raiseRequestTemplates,
            formValues,
            loading,
            disabled = false,
        } = this.props;
        const propsForRaiseRequestTemplateSelect = GetListForSelect(
            raiseRequestTemplates,
            'Raise Request Template',
            'id',
            'name',
        );
        const propsForJobSetupSelect = GetListForSelect(jobSetups, 'Job', 'id', 'project_name');
        const propsForMangementSelect = GetListForSelect(
            management,
            'Management',
            'id',
            'company_name',
        );
        let managers = [];
        let managementSelectDisabled = false;
        let managerSelectDisabled = false;
        if (formValues && formValues.management_id) {
            managers = manager.filter(x => x.management_company_id == formValues.management_id);
        }
        const propsForManagersSelect = GetListForSelect(managers, 'Manager', 'id', 'name');

        const {
            increase = 0,
            current_monthly_contract_amount = 0,
            increase_by_percent = 0,
        } = formValues;

        let newMonthlyContractAmount = Number.parseFloat(current_monthly_contract_amount);
        if (increase !== null) {
            newMonthlyContractAmount = getnewMonthlyContractAmount(
                current_monthly_contract_amount,
                increase,
                'fixed',
            );
        }
        if (increase_by_percent !== null && increase_by_percent !== 0) {
            newMonthlyContractAmount = getnewMonthlyContractAmount(
                current_monthly_contract_amount,
                increase_by_percent,
                'percent',
            );
        }
        if (formValues && formValues.job_setup_id) {
            const { job_setup_id } = formValues;
            const jobSetupKeys = Object.keys(jobSetups);
            const jobSetupFiltered = jobSetupKeys.filter(key => key == job_setup_id);

            if (jobSetupFiltered && jobSetupFiltered.length > 0) {
                const jobSetupKey = jobSetupFiltered[0];
                const jobSetup = jobSetups[jobSetupKey];
                if (jobSetup.management_id) {
                    managementSelectDisabled = true;
                }

                if (jobSetup.manager_id) {
                    managerSelectDisabled = true;
                }
            }
        }
        return (
          <form className="simple-form" onSubmit={handleSubmit(this.onSave)}>
            <div className="field">
              <div>
                <Subheader style={{ ...styles.subheader }}>Basic Info</Subheader>
                <Divider />
                <div style={{ ...styles.row }}>
                  <Field
                    name="name"
                    type="text"
                    component={RenderField}
                    floatingLabelText="Name"
                    style={{ ...styles.row.field }}
                    disabled={disabled}
                  />

                  <Field
                    name="date"
                    component={RenderDatePicker}
                    container="inline"
                    floatingLabelText="Date"
                    style={{ ...styles.row.field }}
                    disabled={disabled}
                  />

                  <Field
                    name="raise_request_template_id"
                    props={propsForRaiseRequestTemplateSelect}
                    component={RenderSelect}
                    style={{ ...styles.row.field }}
                    disabled={disabled}
                  />
                </div>

                <Subheader style={{ ...styles.subheader }}>Rate Change</Subheader>
                <Divider />
                <div style={{ ...styles.row }}>
                  <Field
                    name="job_setup_id"
                    props={propsForJobSetupSelect}
                    onChange={this.onJobChange}
                    component={RenderSelect}
                    style={{ ...styles.row.field }}
                    disabled={disabled}
                  />
                  <Field
                    name="current_monthly_contract_amount"
                    type="text"
                    component={RenderCurrency}
                    floatingLabelText="Current monthly contract amount"
                    style={{ ...styles.row.field }}
                    disabled={disabled}
                  />
                </div>
                <div style={{ ...styles.row }}>
                  <div style={{ ...styles.row, ...styles.columnDivide }}>
                    <Field
                      name="increase"
                      type="text"
                      component={RenderCurrency}
                      floatingLabelText="Fixed Increase"
                      onChange={this.handleIncrease}
                      style={{ ...styles.row.field }}
                      disabled={disabled}
                    />
                  </div>
                  <div style={{ ...styles.row }}>
                    <Field
                      name="increase_by_percent"
                      type="text"
                      component={RenderPercentage}
                      onChange={this.handIncreasePercent}
                      floatingLabelText="Percentage Increase"
                      style={{ ...styles.row.field }}
                      disabled={disabled}
                    />
                  </div>
                </div>
                <div style={{ ...styles.row }}>
                  <TextField
                    value={numeral(newMonthlyContractAmount).format('$0,0.00')}
                    id="newMonthlyContractAmount"
                    floatingLabelText="New monthly contract amount"
                    disabled={disabled}
                  />
                </div>
                <Subheader style={{ ...styles.subheader }}>Management Details</Subheader>
                <Divider />
                <div style={{ ...styles.row }}>
                  <Field
                    name="signature"
                    type="text"
                    component={RenderField}
                    floatingLabelText="Signature"
                    style={{ ...styles.row.field }}
                    disabled={disabled}
                  />
                  <Field
                    name="project_manager_name"
                    type="text"
                    component={RenderField}
                    floatingLabelText="Project manager name"
                    style={{ ...styles.row.field }}
                    disabled={disabled}
                  />
                </div>
                <div style={{ ...styles.row }}>
                  <Field
                    name="title"
                    type="text"
                    component={RenderField}
                    floatingLabelText="Title"
                    style={{ ...styles.row.field }}
                    disabled={disabled}
                  />
                  {!disabled &&
                                propsForMangementSelect.items.length > 0 && (
                                <Field
                                  name="management_id"
                                  props={propsForMangementSelect}
                                  component={RenderSelect}
                                  disabled={managementSelectDisabled}
                                  style={{ ...styles.row.field }}
                                />
                                )}
                  {!disabled &&
                                propsForMangementSelect.items.length === 0 && (
                                <div>
                                  <RaisedButton
                                    label="Create Management company"
                                    disableTouchRipple
                                    disableFocusRipple
                                    primary
                                    target="_blank"
                                    href="#/management/create"
                                    style={{ ...styles.row.field }}
                                  />
                                </div>
                                )}
                  {!disabled &&
                                propsForManagersSelect.items.length > 0 && (
                                <Field
                                  name="manager_id"
                                  props={propsForManagersSelect}
                                  component={RenderSelect}
                                  disabled={managerSelectDisabled}
                                  style={{ ...styles.row.field }}
                                />
                                )}
                  {!disabled &&
                                manager.length === 0 &&
                                propsForManagersSelect.items.length === 0 && (
                                <div>
                                  <RaisedButton
                                    label="Create Manager"
                                    disableTouchRipple
                                    disableFocusRipple
                                    primary
                                    target="_blank"
                                    href="#/manager/create"
                                  />
                                </div>
                                )}
                </div>
                {!disabled && (
                <Toolbar>
                  <ToolbarGroup lastChild>
                    <SaveButton label="Save" loading={loading} />
                  </ToolbarGroup>
                </Toolbar>
                        )}
              </div>
            </div>
          </form>
        );
    }
}

const validate = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = 'Required';
    }
    if (!values.current_monthly_contract_amount) {
        errors.current_monthly_contract_amount = 'Required';
    }
    if (!values.increase_by_percent && !values.increase) {
        errors.increase = 'Required';
    }
    if (!values.signature) {
        errors.signature = 'Required';
    }
    if (!values.raise_request_template_id) {
        errors.raise_request_template_id = 'Required';
    }
    if (!values.job_setup_id) {
        errors.job_setup_id = 'Required';
    }
    if (!values.management_id) {
        errors.management_id = 'Required';
    }
    if (!values.manager_id) {
        errors.manager_id = 'Required';
    }
    return errors;
};
let ContactReduxForm = reduxForm({
    form: formName, // <------ same form name
    destroyOnUnmount: true, // <------ preserve form data
    forceUnregisterOnUnmount: false, // <------ unregister fields on unmount
    validate,
})(SharedForm);

function mapStateToProps(state, thisprops) {
    const syncErrors = getFormSyncErrors(formName)(state);
    const submitFailed = hasSubmitFailed(formName)(state);
    const formValues = getFormValues(formName)(state);

    return {
        errors: syncErrors,
        submitFailed,
        formValues: formValues || { increase: 0, increase_by_percent: 0 },
        save: thisprops.save,
        jobSetups: denormalize(
            state.admin.resources.jobsetup.data,
            state.admin.resources.jobsetup.list.ids,
        ),
        raiseRequestTemplates: denormalize(
            state.admin.resources.raiserequesttemplate.data,
            state.admin.resources.raiserequesttemplate.list.ids,
        ),
        management: denormalize(
            state.admin.resources.management.data,
            state.admin.resources.management.list.ids,
        ),
        manager: denormalize(
            state.admin.resources.manager.data,
            state.admin.resources.manager.list.ids,
        ),
        loading: state.admin.loading,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                fetchRaiseRequestTemplates,
                fetchJobSetups,
                change,
                fetchManagement,
                fetchManager,
            },
            dispatch,
        ),
    };
}

ContactReduxForm = connect(mapStateToProps, mapDispatchToProps)(ContactReduxForm);

export default ContactReduxForm;
