import React, { Component } from 'react';
import { Show } from 'admin-on-rest';
import { Tabs, Tab } from 'material-ui/Tabs';
import { connect } from 'react-redux';
import JobUsers from '../../components/jobUsers';

import {
  reduxForm,
  hasSubmitFailed,
  getFormSyncErrors,
  getFormValues,
} from 'redux-form';
import { bindActionCreators } from 'redux';
import RenderCard from '../shared/Card';
import { denormalize } from '../../util/normalizr';

import {
  createJobSetup,
  fetchProjectTypes,
  fetchYards,
  fetchPricingGroups,
  fetchLaborRate,
  fetchUsers,
  fetchDeductionType,
  fetchSpecificationType,
  fetchManagement,
  fetchManager,
  fetchJobSetupFeed,
} from '../../actions';

import ProjectInfoForm, { ProjectInfoFormFields } from '../shared/project_info';
import ContractSummary, {
  ContractSummaryFormFields,
} from '../shared/contract_summary';
import AdditionalNotesForm, {
  AdditionalNotesFormFields,
} from '../shared/additional_notes';
import ContractDetailsForm, {
  ContractDetailsFormFields,
} from '../shared/contract_details';
import IrrigationForm, { IrrigationFormFields } from '../shared/irrigation';
import LaborRateForm, { LaborRateFormFields } from '../shared/labor_rate';
import ManagementInfoForm, {
  ManagementInfoFormFields,
} from '../shared/management_info';

import ItemsToBeCompletedForm, {
  ItemsToBeCompletedFormFields,
} from '../shared/items_to_be_completed';

import PricingGroupForm, {
  PricingGroupFormFields,
} from '../shared/pricing_group';
import SpecificationDetailsForm, {
  SpecificationDetailsFormFields,
} from '../shared/specification_details';
import JobFeed from '../../job_feed';
import styles from '../../styles';

class JobSetupCreateForm extends Component {
  constructor(props) {
    super(props);
    this.onSave = this.onSave.bind(this);
    this.state = { selectedTab: 0 };
  }
  componentDidMount() {
    const {
      fetchProjectTypes,
      fetchYards,
      fetchPricingGroups,
      fetchLaborRate,
      fetchUsers,
      fetchDeductionType,
      fetchSpecificationType,
      fetchManagement,
      fetchManager,
      fetchJobSetupFeed,
    } = this.props.actions;
    const { management_company_id } = this.props;
    const { id } = this.props.initialValues;

    fetchProjectTypes({
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'project_type', order: 'ASC' },
      filter: {},
    });
    fetchYards({
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'name', order: 'ASC' },
      filter: {},
    });
    fetchPricingGroups({
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'name', order: 'ASC' },
      filter: {},
    });
    fetchLaborRate({
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'name', order: 'ASC' },
      filter: {},
    });
    fetchUsers({
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'firstName', order: 'ASC' },
      filter: { is_supervisor: true },
    });
    fetchDeductionType({
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'name', order: 'ASC' },
      filter: {},
    });
    fetchSpecificationType({
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'name', order: 'ASC' },
      filter: {},
    });
    fetchManagement({
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'company_name', order: 'ASC' },
      filter: {},
    });
    fetchManager({
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'name', order: 'ASC' },
      filter: { management_company_id },
    });
    fetchJobSetupFeed({
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'created_at', order: 'ASC' },
      filter: { job_setup_id: id },
    });
  }
  onSave(formData) {
    this.props.save(formData);
  }
  getManagers(event, newValue, oldValue) {
    this.props.actions.fetchManager({
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'name', order: 'ASC' },
      filter: { management_company_id: newValue },
    });
  }

  render() {
    const {
      save,
      handleSubmit,
      submitFailed,
      errors,
      submitting,
      laborRates,
      projectTypes,
      pricingGroups,
      users,
      specificationTypes,
      yard,
      deductionTypes,
      management,
      manager,
      formValues = {},
      jobsetupfeed,
      record,
    } = this.props;

    const {
      id = 0,
      total_price = 0,
      original_price = 0,
      deduction_1 = 0,
      deduction_2 = 0,
      deduction_3 = 0,
      deduction_4 = 0,
      deduction_5 = 0,
      detail_hours = 0,
      mowing_hours = 0,
      irrigation_hours = 0,
    } = formValues;
    const { selectedTab } = this.state;
    return (
      <form className="simple-form" onSubmit={handleSubmit(this.onSave)}>
        <Tabs
          inkBarStyle={{ ...styles.tab.inkBarStyle }}
          value={this.state.selectedTab}
          onChange={value => this.setState({ selectedTab: value })}
        >
          <Tab
            label="Job"
            value={0}
            buttonStyle={{ ...styles.tab.selectedButton }}
          >
            <RenderCard
              title="Project Info"
              submitFailed={submitFailed}
              fields={ProjectInfoFormFields}
              initiallyExpanded
              errors={errors}
            >
              <ProjectInfoForm
                disabled
                projectTypes={projectTypes}
                users={users}
                yard={yard}
              />
            </RenderCard>
            <RenderCard
              title="Contract Summary"
              submitFailed={submitFailed}
              fields={ContractSummaryFormFields}
              errors={errors}
            >
              <ContractSummary
                disabled
                deductionTypes={deductionTypes}
                original_price={original_price}
                deduction_1={deduction_1}
                deduction_2={deduction_2}
                deduction_3={deduction_3}
                deduction_4={deduction_4}
                deduction_5={deduction_5}
              />
            </RenderCard>
            <RenderCard
              title="Additional Notes"
              submitFailed={submitFailed}
              fields={AdditionalNotesFormFields}
              errors={errors}
            >
              <AdditionalNotesForm disabled />
            </RenderCard>
            <RenderCard
              title="Management"
              submitFailed={submitFailed}
              fields={ManagementInfoFormFields}
              errors={errors}
            >
              <ManagementInfoForm
                management={management}
                managers={manager}
                disabled
                onChangeManagement={this.getManagers}
              />
            </RenderCard>
            <RenderCard
              title="Irrigation"
              submitFailed={submitFailed}
              fields={IrrigationFormFields}
              errors={errors}
            >
              <IrrigationForm disabled />
            </RenderCard>
            <RenderCard
              title="Items to be completed"
              submitFailed={submitFailed}
              fields={ItemsToBeCompletedFormFields}
              errors={errors}
            >
              <ItemsToBeCompletedForm users={users} disabled />
            </RenderCard>
            <RenderCard
              title="Contract Details"
              submitFailed={submitFailed}
              fields={ContractDetailsFormFields}
              errors={errors}
            >
              <ContractDetailsForm
                detail_hours={detail_hours}
                mowing_hours={mowing_hours}
                irrigation_hours={irrigation_hours}
                disabled
              />
            </RenderCard>
            <RenderCard
              title="Specification Details"
              submitFailed={submitFailed}
              fields={SpecificationDetailsFormFields}
              errors={errors}
            >
              <SpecificationDetailsForm
                disabled
                specificationTypes={specificationTypes}
              />
            </RenderCard>
            <RenderCard
              title="Pricing Group"
              submitFailed={submitFailed}
              fields={PricingGroupFormFields}
              errors={errors}
            >
              <PricingGroupForm disabled pricingGroups={pricingGroups} />
            </RenderCard>
            <RenderCard
              title="Labor Rate"
              submitFailed={submitFailed}
              fields={LaborRateFormFields}
              errors={errors}
            >
              <LaborRateForm disabled laborRates={laborRates} />
            </RenderCard>
          </Tab>
          <Tab
            label="Activity"
            value={1}
            buttonStyle={{ ...styles.tab.selectedButton }}
          >
            <JobFeed data={jobsetupfeed} />
          </Tab>

          <Tab
            label="Users"
            value={3}
            buttonStyle={{ ...styles.tab.selectedButton }}
          >
            <JobUsers data={record} />
          </Tab>
        </Tabs>
      </form>
    );
  }
}
const formName = 'record-form';

function mapStateToProps(state, thisprops) {
  const syncErrors = getFormSyncErrors(formName)(state);
  const submitFailed = hasSubmitFailed(formName)(state);
  const formValues = getFormValues(formName)(state);
  return {
    errors: syncErrors,
    submitFailed,
    formValues,
    save: thisprops.save,
    projectTypes: denormalize(
      state.admin.resources.projecttype.data,
      state.admin.resources.projecttype.list.ids
    ),
    users: denormalize(
      state.admin.resources.user.data,
      state.admin.resources.user.list.ids
    ),
    yard: denormalize(
      state.admin.resources.yard.data,
      state.admin.resources.yard.list.ids
    ),
    laborRates: denormalize(
      state.admin.resources.laborrate.data,
      state.admin.resources.laborrate.list.ids
    ),
    pricingGroups: denormalize(
      state.admin.resources.pricinggroup.data,
      state.admin.resources.pricinggroup.list.ids
    ),
    deductionTypes: denormalize(
      state.admin.resources.deductiontype.data,
      state.admin.resources.deductiontype.list.ids
    ),
    specificationTypes: denormalize(
      state.admin.resources.specificationtype.data,
      state.admin.resources.specificationtype.list.ids
    ),
    management: denormalize(
      state.admin.resources.management.data,
      state.admin.resources.management.list.ids
    ),
    manager: denormalize(
      state.admin.resources.manager.data,
      state.admin.resources.manager.list.ids
    ),
    jobsetupfeed: denormalize(
      state.admin.resources.jobsetupfeed.data,
      state.admin.resources.jobsetupfeed.list.ids
    ),
    initialValues: thisprops.record,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        createJobSetup,
        fetchProjectTypes,
        fetchYards,
        fetchPricingGroups,
        fetchLaborRate,
        fetchUsers,
        fetchDeductionType,
        fetchSpecificationType,
        fetchManagement,
        fetchManager,
        fetchJobSetupFeed,
      },
      dispatch
    ),
  };
}

let JobReduxForm = reduxForm({
  form: formName, // <------ same form name
  destroyOnUnmount: true, // <------ preserve form data
  forceUnregisterOnUnmount: false, // <------ unregister fields on unmount
})(JobSetupCreateForm);

JobReduxForm = connect(mapStateToProps, mapDispatchToProps)(JobReduxForm);

class EnhancedContainer extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { record, save } = this.props;
    return (
      <div>
        <JobReduxForm record={record} save={save} />
      </div>
    );
  }
}

class ShowForm extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Show title="Job" {...this.props}>
        <EnhancedContainer />
      </Show>
    );
  }
}
export default ShowForm;
