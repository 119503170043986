import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MuiAppBar from 'material-ui/AppBar';
import muiThemeable from 'material-ui/styles/muiThemeable';
import compose from 'recompose/compose';
import { toggleSidebar as toggleSidebarAction } from 'admin-on-rest';
// import SearchBar from './search_bar';

import SearchBar from '../Search/SearchBar';

const AppBar = ({ title, toggleSidebar }) => (
  <MuiAppBar
    titleStyle={{ overflow: 'overflow' }}
    title={title}
    iconElementRight={<SearchBar />}
    onLeftIconButtonTouchTap={toggleSidebar}
  />
);

AppBar.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    toggleSidebar: PropTypes.func.isRequired,
};

const enhance = compose(
    muiThemeable(), // force redraw on theme change
    connect(null, {
        toggleSidebar: toggleSidebarAction,
    }),
);

export default enhance(AppBar);
