import { CREATE, GET_ONE, GET_LIST, CRUD_GET_LIST } from 'admin-on-rest';

export const CREATE_JOB_SETUP = 'CREATE_JOB_SETUP';
export const createJobSetup = data => ({
  type: CREATE_JOB_SETUP,
  payload: { data: { ...data } },
  meta: { resource: 'jobsetup', fetch: CREATE, cancelPrevious: false },
});

export const GET_ONE_JOB_SETUP = 'GET_ONE_JOB_SETUP';
export const getOneJobSetup = data => ({
  type: GET_ONE_JOB_SETUP,
  payload: data,
  meta: { resource: 'jobsetup', fetch: GET_ONE, cancelPrevious: false },
});
export const fetchProjectTypes = data => ({
  type: CRUD_GET_LIST,
  payload: { ...data },
  meta: { resource: 'projecttype', fetch: GET_LIST, cancelPrevious: false },
});

export const fetchYards = data => ({
  type: CRUD_GET_LIST,
  payload: { ...data },
  meta: { resource: 'yard', fetch: GET_LIST, cancelPrevious: false },
});

export const fetchDeductionType = data => ({
  type: CRUD_GET_LIST,
  payload: { ...data },
  meta: { resource: 'deductiontype', fetch: GET_LIST, cancelPrevious: false },
});

export const fetchSpecificationType = data => ({
  type: CRUD_GET_LIST,
  payload: { ...data },
  meta: {
    resource: 'specificationtype',
    fetch: GET_LIST,
    cancelPrevious: false,
  },
});
