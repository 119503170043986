// in src/Menu.js
import React from 'react';
import { WithPermission, MenuItemLink, hideFilter } from 'admin-on-rest';
import { withRouter } from 'react-router-dom';
import { stringify } from 'query-string';
import UserIcon from 'material-ui/svg-icons/social/group';
import PersonIcon from 'material-ui/svg-icons/social/person';
import MoneyIcon from 'material-ui/svg-icons/editor/attach-money';
import BuildIcon from 'material-ui/svg-icons/action/build';
import AccountIcon from 'material-ui/svg-icons/action/account-circle';
import DescriptionIcon from 'material-ui/svg-icons/action/description';
import ContentCopyIcon from 'material-ui/svg-icons/content/content-copy';
import DirectionsRunIcon from 'material-ui/svg-icons/maps/directions-run';
import WorkIcon from 'material-ui/svg-icons/action/work';
import PoolIcon from 'material-ui/svg-icons/places/hot-tub';

import AccountBoxIcon from 'material-ui/svg-icons/action/account-box';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Avatar from 'material-ui/Avatar';
import List from 'material-ui/List/List';
import ListItem from 'material-ui/List/ListItem';
import style from './index.css';

const Menu = (props) => {
    const { resources, onMenuTap, logout, loggedIn, actions, history } = props;
    const { hideFilter } = actions;
    return (
      <div className="menu">
        <List>
          <ListItem disabled leftAvatar={<Avatar icon={<AccountBoxIcon />} />}>
            {localStorage.getItem('username')}
          </ListItem>
        </List>
        {loggedIn && (
        <div>
          <WithPermission value={['roleAdministrator', 'user_read', 'user_write']}>
            <MenuItemLink
              to="/user"
              primaryText="Users"
              leftIcon={<DirectionsRunIcon />}
              onClick={onMenuTap}
            />
          </WithPermission>
          <WithPermission
            value={['roleAdministrator', 'pricing_group_read', 'pricing_group_write']}
          >
            <MenuItemLink
              to="/pricinggroup"
              primaryText="Pricing Groups"
              leftIcon={<MoneyIcon />}
              onClick={onMenuTap}
            />
          </WithPermission>
          <WithPermission
            value={['roleAdministrator', 'labor_rate_read', 'labor_rate_write']}
          >
            <MenuItemLink
              to="/laborrate"
              primaryText="Labor Rates"
              leftIcon={<BuildIcon />}
              onClick={onMenuTap}
            />
          </WithPermission>

          <WithPermission
            value={['roleAdministrator', 'management_read', 'management_write']}
          >
            <MenuItemLink
              to="/management"
              primaryText="Management Info"
              leftIcon={<UserIcon />}
              onClick={onMenuTap}
            />
          </WithPermission>
          <WithPermission value={['roleAdministrator', 'manager_read', 'manager_write']}>
            <MenuItemLink
              to="/manager"
              primaryText="Manager"
              leftIcon={<PersonIcon />}
              onClick={() => {
                  history.replace({
                      pathname: '/manager',
                      search: stringify({
                          filter: JSON.stringify({}),
                      }),
                  });
                  onMenuTap();
              }}
            />
          </WithPermission>
          <WithPermission value={['roleAdministrator', 'quote_read', 'quote_write']}>
            <MenuItemLink
              to="#"
              primaryText="Quotes"
              leftIcon={<MoneyIcon />}
              onClick={() =>
                                (window.location.href = process.env.REACT_APP_QUOTES_APP)}
            />
          </WithPermission>
          <WithPermission value={['roleAdministrator', 'job_read', 'job_write']}>
            <MenuItemLink
              to="/jobsetup"
              primaryText="Jobs"
              leftIcon={<WorkIcon />}
              onClick={onMenuTap}
            />
          </WithPermission>
          <WithPermission
            value={[
                'roleAdministrator',
                'contract_template_read',
                'contract_template_write',
            ]}
          >
            <MenuItemLink
              to="/contracttemplate"
              primaryText="Contract Templates"
              leftIcon={<ContentCopyIcon />}
              onClick={onMenuTap}
            />
          </WithPermission>
          <WithPermission
            value={['roleAdministrator', 'contract_read', 'contract_write']}
          >
            <MenuItemLink
              to="/contract"
              primaryText="Contracts"
              leftIcon={<DescriptionIcon />}
              onClick={onMenuTap}
            />
          </WithPermission>
          <WithPermission
            value={[
                'roleAdministrator',
                'raise_request_template_read',
                'raise_request_template_write',
            ]}
          >
            <MenuItemLink
              to="/raiserequesttemplate"
              primaryText="Raise request template"
              leftIcon={<DescriptionIcon />}
              onClick={onMenuTap}
            />
          </WithPermission>
          <WithPermission
            value={['roleAdministrator', 'raise_request_read', 'raise_request_write']}
          >
            <MenuItemLink
              to="/raiserequest"
              primaryText="Raise request"
              leftIcon={<PoolIcon />}
              onClick={onMenuTap}
            />
          </WithPermission>
          <MenuItemLink
            to="#"
            primaryText="Report a Bug"
            leftIcon={<BuildIcon />}
            onClick={() =>
                            (window.location.href = 'https://goo.gl/forms/38MAyH1AITTVLLNs1')}
          />

          <WithPermission value="user">
            <MenuItemLink
              to="/passwordreset"
              primaryText="Account"
              leftIcon={<AccountIcon />}
              onClick={onMenuTap}
            />
          </WithPermission>
        </div>
            )}
        {!loggedIn && (
        <MenuItemLink
          to="/login"
          primaryText="Login"
          leftIcon={<UserIcon />}
          onClick={onMenuTap}
        />
            )}
        {loggedIn && logout}
      </div>
    );
};

const MenuWithRouter = withRouter(Menu);

function mapDispatchToProps(dispatch) {
    return { actions: bindActionCreators({ hideFilter }, dispatch) };
}
function mapStateToProps(state) {
    return {
        loggedIn: state.login.loggedIn,
    };
}
const ConnnectedMenu = connect(mapStateToProps, mapDispatchToProps)(MenuWithRouter);
export default ConnnectedMenu;
