import { GET_LIST, CRUD_GET_LIST } from 'admin-on-rest';

export const fetchContractTemplates = data => ({
    type: CRUD_GET_LIST,
    payload: { ...data },
    meta: { resource: 'contracttemplate', fetch: GET_LIST, cancelPrevious: false },
});

export const fetchJobSetups = data => ({
    type: CRUD_GET_LIST,
    payload: { ...data },
    meta: { resource: 'jobsetup', fetch: GET_LIST, cancelPrevious: false },
});
