import React from 'react';
import { List, ShowButton, EditButton, Datagrid, TextField, SimpleList, Responsive } from 'admin-on-rest';
import checkAccess from '../util/acl';

const ListComponent = props => (
  <List title="Raise request templates" {...props} perPage={10}>
    {permissions => (
      <Responsive
        small={
          <SimpleList
            primaryText={record => record.name}
            // secondaryText={record => record.email}
            // tertiaryText={record => record.phone}
          />
        }
        medium={
          <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <ShowButton />
            {checkAccess('raise_request_template_write', permissions) && <EditButton />}
          </Datagrid>
        }
      />
          
    )}
  </List>
);
export default ListComponent;
