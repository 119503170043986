import React from 'react';
import FlatButton from 'material-ui/FlatButton';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import PersonIcon from 'material-ui/svg-icons/social/person';

const LinkToRelatedManagers = (props) => {
    const { record, text } = props;
    const { id } = record;
    return (
      <FlatButton
        primary
        label={text}
        icon={<PersonIcon />}
        containerElement={
          <Link
            to={{
                pathname: '/manager',
                search: stringify({
                    page: 1,
                    perPage: 25,
                    filter: JSON.stringify({ management_company_id: id }),
                }),
            }}
          />
            }
      />
    );
};
export default LinkToRelatedManagers;
