import { put, takeEvery, all } from 'redux-saga/effects';
import {
  loggedIn,
  loggedOut,
  LOGOUT_MANUALLY,
  LOGIN_MANUALLY,
} from '../actions/';
import { push } from 'react-router-redux';
import { LogUserOut, LogUserIn } from '../auth';

function* logout() {
  LogUserOut();
  yield put(loggedOut());
  yield put(push('/login'));
}

function* login() {
  yield put(loggedIn());
}

function loginManually({ token, roles }) {
  LogUserIn(token, roles);
}

function* loginLoading() {}
export function* loginSaga() {
  yield all([
    takeEvery('AOR/USER_LOGIN_LOADING', loginLoading),
    takeEvery('AOR/USER_LOGOUT', logout),
    takeEvery('AOR/USER_LOGIN_SUCCESS', login),
    takeEvery('AOR/USER_LOGOUT', logout),
    takeEvery(LOGOUT_MANUALLY, logout),
    takeEvery(LOGIN_MANUALLY, loginManually),
  ]);
}
