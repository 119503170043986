import _ from 'lodash';

const roleAccess = {
    user: {
        read: ['user_write', 'roleAdministrator'],
        write: ['roleAdministrator'],
    },
    pricing_group: {
        read: [
            'job_read',
            'contract_read',
            'raise_request_read',
            'pricing_group_write',
            'job_write',
            'contract_write',
            'raise_request_write',
            'roleAdministrator',
        ],
        write: ['job_write', 'contract_write', 'raise_request_write', 'roleAdministrator'],
    },
    labor_rate: {
        read: [
            'job_read',
            'contract_read',
            'raise_request_read',
            'labor_rate_write',
            'job_write',
            'contract_write',
            'raise_request_write',
            'roleAdministrator',
        ],
        write: ['job_write', 'contract_write', 'raise_request_write', 'roleAdministrator'],
    },
    management: {
        read: [
            'job_read',
            'contract_read',
            'raise_request_read',
            'management_write',
            'job_write',
            'contract_write',
            'raise_request_write',
            'roleAdministrator',
        ],
        write: ['job_write', 'contract_write', 'raise_request_write', 'roleAdministrator'],
    },
    manager: {
        read: [
            'job_read',
            'contract_read',
            'raise_request_read',
            'manager_write',
            'job_write',
            'contract_write',
            'raise_request_write',
            'roleAdministrator',
        ],
        write: ['job_write', 'contract_write', 'raise_request_write', 'roleAdministrator'],
    },
    job: {
        read: [
            'contract_read',
            'raise_request_read',
            'job_write',
            'contract_write',
            'raise_request_write',
            'roleAdministrator',
        ],
        write: ['contract_write', 'raise_request_write', 'roleAdministrator'],
    },
    quote: {
        read: ['quote_write', 'roleAdministrator'],
        write: ['roleAdministrator'],
    },
    contract_template: {
        read: ['contract_read', 'contract_template_write', 'contract_write', 'roleAdministrator'],
        write: ['contract_write', 'roleAdministrator'],
    },
    raise_request_template: {
        read: [
            'raise_request_read',
            'raise_request_template_write',
            'raise_request_read',
            'raise_request_write',
            'roleAdministrator',
        ],
        write: ['raise_request_write', 'roleAdministrator'],
    },
    contract: {
        read: ['contract_write', 'roleAdministrator'],
        write: ['roleAdministrator'],
    },
    raise_request: {
        read: ['raise_request_write', 'roleAdministrator'],
        write: ['roleAdministrator'],
    },
};
function checkAccess(formValues, roleAccessRules, field) {
    if (!formValues) {
        return true;
    }
    const access = _.get(roleAccessRules, field);

    for (let i = 0; i < access.length; i++) {
        const accessField = access[i];
        if (formValues[accessField]) {
            return false;
        }
    }
    return true;
}

export default roleAccess;
export { checkAccess };
