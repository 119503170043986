import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import RenderField, { RenderSelect } from '../../shared/render_field';
import { GetListForSelect } from '../../util/parse';
import styles from '../../styles';

const SpecificationDetailsFormFields = {
    aeration_specification_id: 'aeration_specification_id',
    aeration_specification_notes: 'aeration_specification_notes',
    broad_leaf_specification_id: 'broad_leaf_specification_id',
    broad_leaf_specification_notes: 'broad_leaf_specification_notes',
    spray_specification_id: 'spray_specification_id',
    spray_specification_notes: 'spray_specification_notes',
    tree_trimming_specification_id: 'tree_trimming_specification_id',
    tree_trimming_specification_notes: 'tree_trimming_specification_notes',
    fertilizer_specification_id: 'fertilizer_specification_id',
    fertilizer_specification_notes: 'fertilizer_specification_notes',
    rodent_control_specification_id: 'rodent_control_specification_id',
    rodent_control_specification_notes: 'rodent_control_specification_notes',
    main_valve_labor_specification_id: 'main_valve_labor_specification_id',
    main_valve_labor_specification_notes: 'main_valve_labor_specification_notes',
    color_specification_id: 'color_specification_id',
    color_specification_notes: 'color_specification_notes',
    dump_fees_specification_id: 'dump_fees_specification_id',
    dump_fees_specification_notes: 'dump_fees_specification_notes',
    chemicals_specification_id: 'chemicals_specification_id',
    chemicals_specification_notes: 'chemicals_specification_notes',
    fuel_mod_specification_id: 'fuel_mod_specification_id',
    fuel_mod_specification_notes: 'fuel_mod_specification_notes',
    mulch_specification_id: 'mulch_specification_id',
    mulch_specification_notes: 'mulch_specification_notes',
    irrigation_parts_specification_id: 'irrigation_parts_specification_id',
    irrigation_parts_specification_notes: 'irrigation_parts_specification_notes',
};

const SpecificationDetailsForm = (props) => {
    const { disabled, specificationTypes } = props;
    const propsSpecificationSelect = GetListForSelect(
        specificationTypes,
        'Specification',
        'id',
        'name',
    );
    const propsAerationSpecsSelect = {
        ...propsSpecificationSelect,
        items: propsSpecificationSelect.items.filter(
            x => x.text === 'Included, 1x Per Year' || x.text === 'Included, 2x Per Year',
        ),
    };
    const propsBroadLeafSpecsSelect = {
        ...propsSpecificationSelect,
        items: propsSpecificationSelect.items.filter(
            x => x.text === 'Included' || x.text === 'Extra',
        ),
    };
    const propsTreeTrimmingSpecsSelect = {
        ...propsSpecificationSelect,
        items: propsSpecificationSelect.items.filter(
            x =>
                x.text === 'Included, Up to 15 ft' ||
                x.text === 'Included, up to 12 ft' ||
                x.text === 'Included, up to 20 ft' ||
                x.text === 'All trees included' ||
                x.text === 'All Extra',
        ),
    };

    return (
      <div>
        <div style={{ ...styles.row }} className="text-row long-row">
          <div style={{ ...styles.row, ...styles.columnDivide }} className="text-row">
            <Field
              name={SpecificationDetailsFormFields.aeration_specification_id}
              props={{ ...propsAerationSpecsSelect, floatingLabelText: 'Aeration' }}
              disabled={disabled}
              component={RenderSelect}
              style={{ ...styles.row.field }}
            />
            <Field
              name={SpecificationDetailsFormFields.aeration_specification_notes}
              type="text"
              component={RenderField}
              floatingLabelText="Note"
              disabled={disabled}
              style={{ ...styles.row.field }}
            />
          </div>
          <div style={{ ...styles.row }} className="text-row">
            <Field
              name={SpecificationDetailsFormFields.fuel_mod_specification_id}
              props={{ ...propsBroadLeafSpecsSelect, floatingLabelText: 'Fuel Mod' }}
              component={RenderSelect}
              disabled={disabled}
              style={{ ...styles.row.field }}
            />
            <Field
              name={SpecificationDetailsFormFields.fuel_mod_specification_notes}
              type="text"
              component={RenderField}
              style={{ ...styles.row.field }}
              floatingLabelText="Note"
              disabled={disabled}
            />
          </div>
        </div>
        <div style={{ ...styles.row }} className="text-row long-row">
          <div style={{ ...styles.row, ...styles.columnDivide }} className="text-row">
            <Field
              name={SpecificationDetailsFormFields.broad_leaf_specification_id}
              props={{ ...propsBroadLeafSpecsSelect, floatingLabelText: 'Broadleaf' }}
              component={RenderSelect}
              disabled={disabled}
              style={{ ...styles.row.field }}
            />
            <Field
              name={SpecificationDetailsFormFields.broad_leaf_specification_notes}
              type="text"
              component={RenderField}
              floatingLabelText="Note"
              disabled={disabled}
              style={{ ...styles.row.field }}
            />
          </div>
          <div style={{ ...styles.row }} className="text-row">
            <Field
              name={SpecificationDetailsFormFields.main_valve_labor_specification_id}
              props={{
                  ...propsBroadLeafSpecsSelect,
                  floatingLabelText: 'Main/Valve Labor',
              }}
              component={RenderSelect}
              disabled={disabled}
              style={{ ...styles.row.field }}
            />
            <Field
              name={SpecificationDetailsFormFields.main_valve_labor_specification_notes}
              type="text"
              component={RenderField}
              style={{ ...styles.row.field }}
              floatingLabelText="Note"
              disabled={disabled}
            />
          </div>
        </div>
        <div style={{ ...styles.row }} className="text-row long-row">
          <div style={{ ...styles.row, ...styles.columnDivide }} className="text-row">
            <Field
              name={SpecificationDetailsFormFields.chemicals_specification_id}
              props={{ ...propsBroadLeafSpecsSelect, floatingLabelText: 'Chemicals' }}
              component={RenderSelect}
              disabled={disabled}
              style={{ ...styles.row.field }}
            />
            <Field
              name={SpecificationDetailsFormFields.chemicals_specification_notes}
              type="text"
              component={RenderField}
              style={{ ...styles.row.field }}
              floatingLabelText="Note"
              disabled={disabled}
            />
          </div>
          <div style={{ ...styles.row }} className="text-row">
            <Field
              name={SpecificationDetailsFormFields.mulch_specification_id}
              props={{ ...propsBroadLeafSpecsSelect, floatingLabelText: 'Mulch' }}
              component={RenderSelect}
              disabled={disabled}
              style={{ ...styles.row.field }}
            />
            <Field
              name={SpecificationDetailsFormFields.mulch_specification_notes}
              type="text"
              component={RenderField}
              style={{ ...styles.row.field }}
              floatingLabelText=" "
              floatingLabelFixed
              hintText="If included, How many cubic yards?"
              disabled={disabled}
            />
          </div>
        </div>
        <div style={{ ...styles.row }} className="text-row long-row">
          <div style={{ ...styles.row, ...styles.columnDivide }} className="text-row">
            <Field
              name={SpecificationDetailsFormFields.color_specification_id}
              props={{
                  ...propsBroadLeafSpecsSelect,
                  floatingLabelText: 'Color',
              }}
              component={RenderSelect}
              disabled={disabled}
              style={{ ...styles.row.field }}
            />
            <Field
              name={SpecificationDetailsFormFields.color_specification_notes}
              type="text"
              component={RenderField}
              style={{ ...styles.row.field }}
              floatingLabelText=" "
              floatingLabelFixed
              hintText="If included, Please write how many flats & changes per year here"
              disabled={disabled}
            />
          </div>
          <div style={{ ...styles.row }} className="text-row">
            <Field
              name={SpecificationDetailsFormFields.rodent_control_specification_id}
              props={{
                  ...propsBroadLeafSpecsSelect,
                  floatingLabelText: 'Rodent Control',
              }}
              component={RenderSelect}
              disabled={disabled}
              style={{ ...styles.row.field }}
            />
            <Field
              name={SpecificationDetailsFormFields.rodent_control_specification_notes}
              type="text"
              component={RenderField}
              style={{ ...styles.row.field }}
              floatingLabelText="Note"
              disabled={disabled}
            />
          </div>
        </div>
        <div style={{ ...styles.row }} className="text-row long-row">
          <div style={{ ...styles.row, ...styles.columnDivide }} className="text-row">
            <Field
              name={SpecificationDetailsFormFields.dump_fees_specification_id}
              props={{ ...propsBroadLeafSpecsSelect, floatingLabelText: 'Dump Fees' }}
              component={RenderSelect}
              disabled={disabled}
              style={{ ...styles.row.field }}
            />
            <Field
              name={SpecificationDetailsFormFields.dump_fees_specification_notes}
              type="text"
              component={RenderField}
              style={{ ...styles.row.field }}
              floatingLabelText="Note"
              disabled={disabled}
            />
          </div>
          <div style={{ ...styles.row }} className="text-row">
            <Field
              name={SpecificationDetailsFormFields.spray_specification_id}
              props={{
                  ...propsBroadLeafSpecsSelect,
                  floatingLabelText: 'Minor irrigation labor',
              }}
              component={RenderSelect}
              disabled={disabled}
              style={{ ...styles.row.field }}
            />
            <Field
              name={SpecificationDetailsFormFields.spray_specification_notes}
              type="text"
              component={RenderField}
              style={{ ...styles.row.field }}
              floatingLabelText="Note"
              disabled={disabled}
            />
          </div>
        </div>
        <div style={{ ...styles.row }} className="text-row long-row">
          <div style={{ ...styles.row, ...styles.columnDivide }} className="text-row">
            <Field
              name={SpecificationDetailsFormFields.fertilizer_specification_id}
              props={{ ...propsBroadLeafSpecsSelect, floatingLabelText: 'Fertilizer' }}
              component={RenderSelect}
              disabled={disabled}
              style={{ ...styles.row.field }}
            />
            <Field
              name={SpecificationDetailsFormFields.fertilizer_specification_notes}
              type="text"
              component={RenderField}
              style={{ ...styles.row.field }}
              floatingLabelText=" "
              floatingLabelFixed
              hintText="State application frequency here"
              disabled={disabled}
            />
          </div>
          <div style={{ ...styles.row }} className="text-row">
            <Field
              name={SpecificationDetailsFormFields.tree_trimming_specification_id}
              props={{
                  ...propsTreeTrimmingSpecsSelect,
                  floatingLabelText: 'Tree Trimming',
              }}
              component={RenderSelect}
              disabled={disabled}
              style={{ ...styles.row.field }}
            />
            <Field
              name={SpecificationDetailsFormFields.tree_trimming_specification_notes}
              type="text"
              component={RenderField}
              style={{ ...styles.row.field }}
              floatingLabelText="Note"
              disabled={disabled}
            />
          </div>
        </div>
        <div style={{ ...styles.row }} className="text-row long-row">
          <div style={{ ...styles.row, ...styles.columnDivide }} className="text-row">
            <Field
              name={SpecificationDetailsFormFields.irrigation_parts_specification_id}
              props={{
                  ...propsBroadLeafSpecsSelect,
                  floatingLabelText: 'Irrigation parts',
              }}
              component={RenderSelect}
              disabled={disabled}
              style={{ ...styles.row.field }}
            />
            <Field
              name={SpecificationDetailsFormFields.irrigation_parts_specification_notes}
              type="text"
              component={RenderField}
              style={{ ...styles.row.field }}
              floatingLabelText="Note"
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    );
};
SpecificationDetailsForm.defaultProps = {
    disabled: false,
};
SpecificationDetailsForm.propTypes = {
    disabled: PropTypes.bool,
    specificationTypes: PropTypes.array,
};
export default SpecificationDetailsForm;
export { SpecificationDetailsFormFields };
