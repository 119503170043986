import React from 'react';
import PropTypes from 'prop-types';
import { RaisedButton, CircularProgress } from 'material-ui';

const SavedButton = ({ label, loading }) => (
  <RaisedButton
    label={label}
    type="Submit"
    primary
    icon={loading !== 0 && <CircularProgress size={25} thickness={2} />}
    disabled={loading !== 0}
  />
);

SavedButton.defaultProps = {
    loading: 0,
};
SavedButton.propTypes = {
    label: PropTypes.string.isRequired,
    loading: PropTypes.number,
};

export default SavedButton;
