import React, { Component } from 'react';
import { Show, ListButton } from 'admin-on-rest';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import draftToHtml from 'draftjs-to-html';
import { contractDownload, contractEmail } from '../actions';
import Button from '../components/button';
import FlatButton from 'material-ui/FlatButton';
import { Link } from 'react-router-dom';
import { fetchEmailTransaction } from '../actions';
import RenderField, { RenderDatePicker, RenderCurrency } from '../shared/render_field';
import { required, number } from '../util/validate';
import { CardActions } from 'material-ui/Card';
import EmailIcon from 'material-ui/svg-icons/communication/email';
import DownloadIcon from 'material-ui/svg-icons/file/file-download';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import moment from 'moment';
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn,
} from 'material-ui/Table';

import styles from '../styles';

const formName = 'record-form';
const RenderContractButton = connect(
    (state, ownProps) => ({ ...ownProps, loading: state.contract.downloading }),
    {
        onClick: props => contractDownload({ id: props.id }),
    },
)(Button);
const RenderSendEmailButton = connect(
    (state, ownProps) => ({ ...ownProps, loading: state.contract.sendingEmail }),
    {
        onClick: props => contractEmail({ id: props.id }),
    },
)(Button);
const PostShowActions = ({ basePath, data, refresh }) => (
  <CardActions style={styles.cardAction}>
    <div>
      {data && (
        <RenderContractButton
          icon={<DownloadIcon />}
          record={{ id: data.id }}
          label="Preview"
        />
            )}
      {data &&
                data.manager &&
                data.manager.email && (
                <RenderSendEmailButton
                  icon={<EmailIcon />}
                  record={{ id: data.id }}
                  label={`Send to ${data.manager.email}`}
                />
                )}
      <ListButton basePath={basePath} style={styles.cardActionItem} />
      {data && (
        <FlatButton
          label="Delete"
          icon={<DeleteIcon />}
          labelPosition="after"
          secondary
          style={{ overflow: 'inherit' }}
          containerElement={<Link to={{ pathname: `/contract/${data.id}/delete` }} />}
        />
            )}
    </div>
  </CardActions>
);

function openFile(e, imageSrc) {
    e.preventDefault();
    const image = new Image();
    image.src = imageSrc;
    const w = window.open('');
    w.document.write(image.outerHTML);
}
function RenderExhibitImage(props) {
    const { image_type, pic } = props.record;
    if (!image_type) {
        return (<div style={{ ...styles.rowContainer, ...styles.row.field  }}>
          <label>{props.label}</label></div>)
    }
    const imageSrc = `data:image/${image_type};base64,${pic}`;
    return (
      <div style={{ ...styles.rowContainer, ...styles.row.field  }}>
        <label>{props.label}</label>
        <div style={{ ...styles.exhibitImageContainer }}>
          <a href="#" onClick={e => openFile(e, imageSrc)} target="_blank">
            <img
              src={imageSrc}
              style={{ ...styles.exhibitImage }}
              className="exhibitImage"
              alt="exhibit"
            />
          </a>
        </div>
      </div>
    );
}
function RenderContent(props) {
    const { content } = props.record;
    const html = draftToHtml(content);
    const markup = { __html: html };
    return (
      <div style={{ ...styles.rowContainer }}>
        <label>{props.label}</label>
        <div dangerouslySetInnerHTML={markup} />
      </div>
    );
}

function GenerateRow(item) {
    const { id, email, created_at } = item;
    return (
      <TableRow key={id}>
        <TableRowColumn>{id}</TableRowColumn>
        <TableRowColumn>{email}</TableRowColumn>
        <TableRowColumn>{moment(created_at).format('M/D/YYYY')}</TableRowColumn>
      </TableRow>
    );
}
function EmailHistory(props) {
    const items = [];
    if (props && props.data) {
        const { id, data } = props;
        const keys = Object.keys(data);
        keys.map(key => {
          const value = data[key];
          if (value.contract_id == id){
            items.push(value);
          }
        });
    }

    return (
      <Table>
        <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
          <TableRow>
            <TableHeaderColumn>ID</TableHeaderColumn>
            <TableHeaderColumn>TO</TableHeaderColumn>
            <TableHeaderColumn>TIME SENT</TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody displayRowCheckbox={false}>{items.map(item => GenerateRow(item))}</TableBody>
      </Table>
    );
}
class Contact extends Component {
    componentDidMount() {
        const { id } = this.props.initialValues;
        const { fetchEmailTransaction } = this.props.actions;
        fetchEmailTransaction({
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'created_at', order: 'ASC' },
            filter: { contract_id: id },
        });
    }
    render() {
        const {
          id,
            exhibit_a_image_type,
            exhibit_a_pic,
            exhibit_b,
            exhibit_c_image_type,
            exhibit_c_pic,
        } = this.props.initialValues;
        const { emailtransaction } = this.props;
        return (
          <form className="simple-form">
            <div className="field">
              <div>
                <div style={{ ...styles.row }}>
                  <Field
                    name="name"
                    type="text"
                    component={RenderField}
                    disabled
                    floatingLabelText="Name"
                    style={{ ...styles.row.field }}
                  />
                </div>
                <div style={{ ...styles.row }}>
                  <Field
                    name="contract_template.name"
                    type="text"
                    component={RenderField}
                    disabled
                    floatingLabelText="Contract Template"
                    style={{ ...styles.row.field }}
                  />
                </div>
                <div style={{ ...styles.row }}>
                  <Field
                    name="job.project_name"
                    type="text"
                    component={RenderField}
                    disabled
                    floatingLabelText="Job"
                    style={{ ...styles.row.field }}
                  />
                </div>
                <div style={{ ...styles.row }}>
                  <Field
                    name="management.company_name"
                    type="text"
                    component={RenderField}
                    disabled
                    floatingLabelText="Management Company"
                    style={{ ...styles.row.field }}
                  />

                  <Field
                    name="manager.name"
                    type="text"
                    component={RenderField}
                    disabled
                    floatingLabelText="Manager"
                    style={{ ...styles.row.field }}
                  />
                </div>
                <div style={{ ...styles.row }}>
                  <Field
                    name="effective_date"
                    disabled
                    component={RenderDatePicker}
                    props={{ container: 'inline' }}
                    validate={[required]}
                    locale="en-US"
                    floatingLabelText="Effective date"
                    style={{ ...styles.row.field }}
                  />

                  <Field
                    name="term_end_date"
                    disabled
                    component={RenderDatePicker}
                    props={{ container: 'inline' }}
                    validate={[required]}
                    locale="en-US"
                    floatingLabelText="Term end date"
                    style={{ ...styles.row.field }}
                  />
                </div>
                <div style={{ ...styles.row }}>
                  <Field
                    name="monthly_price"
                    disabled
                    component={RenderCurrency}
                    validate={[required, number]}
                    floatingLabelText="Monthly price"
                    style={{ ...styles.row.field }}
                  />
                </div>
                <div style={{ ...styles.row }}>
                  <RenderExhibitImage
                    record={{
                        image_type: exhibit_a_image_type,
                        pic: exhibit_a_pic,
                    }}
                    label="Exhibit A"
                  />
                  <RenderExhibitImage
                    record={{
                        image_type: exhibit_c_image_type,
                        pic: exhibit_c_pic,
                    }}
                    label="Exhibit C"
                  />
                </div>
                <div style={{ ...styles.row }}>
                  <RenderContent label="Exhibit B" record={{ content: exhibit_b }} />
                </div>
                <div>
                  <div style={{ ...styles.rowContainer }}>
                    <label>Emails sent</label>
                  </div>
                  <EmailHistory data={emailtransaction} id={id} />
                </div>
              </div>
            </div>
          </form>
        );
    }
}

let CreateReduxForm = reduxForm({
    form: formName, // <------ same form name
    destroyOnUnmount: true, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(Contact);

function mapStateToProps(state, thisprops) {
  return {
        emailtransaction: state.admin.resources.emailtransaction.data,
        initialValues: thisprops.record,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                fetchEmailTransaction,
            },
            dispatch,
        ),
    };
}

CreateReduxForm = connect(mapStateToProps, mapDispatchToProps)(CreateReduxForm);

class EnhancedContainer extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { save, record } = this.props;
        return (
          <div>
            <CreateReduxForm save={save} record={record} />
          </div>
        );
    }
}
class EnhancedContainer1 extends Component {
    render() {
        const { record } = this.props;
        return (
          <div>
            <CreateReduxForm record={record} />
          </div>
        );
    }
}

class ContractShow extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
          <Show title="Contract" {...this.props} actions={<PostShowActions />}>
            <EnhancedContainer />
          </Show>
        );
    }
}
export default ContractShow;
