import React from 'react';

const userStyle = {
    wrapper: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    name: {
        fontWeight: 'bold',
    },
    title: {
        fontStyle: 'italic',
        color: 'gray',
    },
};

export const renderUser = record => (
  <div style={userStyle.wrapper}>
    <div style={userStyle.name}>
      {record.firstName} {record.lastName}
    </div>
    <span style={userStyle.title}>
      {Boolean(record.title) && record.title}
      {Boolean(record.title) && <br />}
    </span>
    <span style={userStyle.phone}>
      {Boolean(record.phone) && record.phone}
      {Boolean(record.phone) && <br />}
    </span>
    <span style={userStyle.cell}>
      {Boolean(record.cell) && record.cell}
      {Boolean(record.cell) && <br />}
    </span>
    <a href={record.email}>{record.email}</a>
  </div>
);

export default {};
